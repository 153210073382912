<script lang="ts" setup>
const { t } = useI18n()
const router = useRouter()
const listPage = router.currentRoute.value.name === 'index'
const bankStatementPage = router.currentRoute.value.name === 'bank-statement'
const impersonateStore = useImpersonateStore()
const impersonate = useImpersonate()
const ALL_PROJECTS = 1
const PROJECT = 0

const title = computed(() => {
  const fantasyName = impersonateStore.fantasyName
  const showTitleToListPage = listPage ? ALL_PROJECTS : PROJECT
  return bankStatementPage
    ? `${t('app.alert.partner.statement_title')} ${fantasyName}`
    : `${t('app.alert.partner.title', { count: showTitleToListPage })} ${fantasyName}`
})

const description = computed(() => {
  const showTitleToListPage = listPage ? ALL_PROJECTS : PROJECT
  return bankStatementPage
    ? t('app.alert.partner.statement_description')
    : t('app.alert.partner.description', { count: showTitleToListPage })
})
function viewAdmin() {
  impersonate.removeImpersonate()
}
</script>

<template>
  <div v-if="impersonateStore.showAlertImpersonateActive" class="w-full mb-6">
    <SolAlert
      id="view-partner"
      class="view-partner-alert"
      :title="title"
      feedback="informative"
      :action="t('app.alert.partner.textButton')"
      @click="viewAdmin"
    >
      {{ description }}
    </SolAlert>
  </div>
</template>

<style lang="scss" scoped>
.view-partner-alert {
  :deep(.sol-button-core.-secondary) {
    @apply w-[200px] lg:system:w-[170px]
  }
}
</style>
