import { defineStore } from 'pinia'
import storage from '~/foundation/storage/localstorage'
import { router } from '~/router'

export interface ImpersonateStore {
  partnerId: number
  fantasyName: string
  impersonated: boolean
  openImpersonateModal: boolean
  showAlertImpersonateActive: boolean
}

export const useImpersonateStore = defineStore('impersonate-store', {
  state: (): ImpersonateStore => ({
    partnerId: 0,
    fantasyName: '',
    impersonated: false,
    openImpersonateModal: false,
    showAlertImpersonateActive: false,
  }),

  actions: {
    verifyIsImpersonated() {
      return this.impersonated
    },

    createImpersonate(value: { partnerId: number, fantasyName: string }) {
      this.partnerId = value.partnerId
      this.fantasyName = value.fantasyName
      this.impersonated = true
      this.showAlertImpersonateActive = true
      storage.set('impersonate', value)
    },

    deleteImpersonate() {
      this.partnerId = 0
      this.fantasyName = ''
      this.impersonated = false
      this.showAlertImpersonateActive = false
      storage.remove('impersonate')
      router.push('/')
    },

    toggleImpersonateModal() {
      this.openImpersonateModal = !this.openImpersonateModal
    },

    alertUserImpersonate() {
      this.showAlertImpersonateActive = !this.showAlertImpersonateActive
    },

    isImpersonated() {
      return storage.get('impersonate')
    },
  },
})
