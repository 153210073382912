<script setup lang="ts">
import type { BankStatementPayment, BankStatementResponse } from '~/services-v2/payment-bff/payment-bff.types'

const props = defineProps<{
  bankStatement: BankStatementResponse
}>()

const { formatMoney } = useMoney()
const isLargeScreen = useMediaQuery('(min-width: 1024px)')

const paymentTypeMap = {
  1: 'Entrada: projeto',
  2: 'Entrada: comissão do projeto',
  3: 'Entrada: comissão de seguro',
  4: 'Saída: 1ª parcela',
  5: 'Saída: 2ª parcela',
  6: 'Saída: fornecedor',
  7: 'Saída: comissão do projeto',
  8: 'Saída: comissão de seguro',
  10: 'Entrada: desembolso de recurso ajuste',
  11: 'Saída: estorno de recurso',
  12: 'Saída: estorno de recurso remanescente',
  13: 'Saída: estorno de recurso ajuste',
  14: 'Entrada: devolução pagamento parceiro 1ª parcela',
  15: 'Entrada: devolução pagamento parceiro 2ª parcela',
  16: 'Entrada: devolução pagamento fornecedor',
  17: 'Entrada: devolução pagamento parceiro comissão projeto',
  18: 'Entrada: devolução pagamento parceiro comissão seguro',
}

function calculateTotal(payments: BankStatementPayment[], for_ids: number[]) {
  const calculatedTotal = payments.reduce((acc, payment) => {
    if (for_ids.includes(payment.payment_type_id))
      return acc + payment.paid_amount

    return acc
  }, 0)

  return formatMoney(calculatedTotal / 100)
}

function formatDate(date: string) {
  const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
}
</script>

<template>
  <BankStatementAccordionListItem
    v-for="result in props.bankStatement.results" :key="result.legacy_id" class="mb-2"
    :document-number="result.customer_document" :solfacil-id="result.legacy_id"
    :formalized-at="result.first_disbursement_date" :customer-name="result.customer_name"
  >
    <div class="pt-4xs">
      <h4 class="fonts-heading-h4 mb-4xs">
        Entradas e saídas do projeto
      </h4>

      <div v-for="payment in result.payments" :key="payment.transaction_id" class="payments">
        <div class="heading-wrapper">
          <p class="mb-1 text-neutral-low-dark">
            {{ paymentTypeMap[payment.payment_type_id] }}
          </p>
          <p class="fonts-body-small-regular">
            Pago: {{ formatDate(payment.paid_at) }}
          </p>
        </div>
        <div class="left-payment-info">
          <span
            class="fonts-body-medium-bold" :class="{
              'text-brand-secondary-dark': [4, 5, 7, 8].includes(
                payment.payment_type_id,
              ),
              'text-neutral-low-light': payment.payment_type_id === 6,
            }"
          >
            {{ formatMoney(payment.paid_amount / 100) }}
          </span>
          <BankStatementDownloadButton
            v-if="![14, 15, 16, 17, 18].includes(payment.payment_type_id)"
            :transaction-id="payment.transaction_id"
          />
          <div v-else :class="{ 'w-[162px]': isLargeScreen, 'w-[34px]': !isLargeScreen }" />
        </div>
      </div>
    </div>
    <div class="text-right text-neutral-low-dark fonts-body-medium-regular mt-4xs">
      <p class="mb-2">
        Total depositado na conta vinculada:
        <span class="fonts-body-medium-bold">
          {{ calculateTotal(result.payments, [1, 2, 3]) }}
        </span>
      </p>
      <p class="mb-2">
        Transferido ao fornecedor:
        <span class="fonts-body-medium-bold text-neutral-low-light">
          {{ calculateTotal(result.payments, [4, 5, 7, 8]) }}
        </span>
      </p>
      <p>
        Total transferido à sua conta:
        <span class="fonts-body-medium-bold text-brand-secondary-dark">
          {{ calculateTotal(result.payments, [6]) }}
        </span>
      </p>
    </div>
  </BankStatementAccordionListItem>
</template>

<style lang="scss" scoped>
.payments {
  @apply flex gap-2 justify-between items-center fonts-body-medium-regular;

  &:not(:last-of-type) {
    @apply pb-2 mb-2;
    @apply border-b-neutral-high-medium border-b-xs;
  }

  .heading-wrapper {
    @apply max-w-40;

    @screen md:system {
      @apply max-w-none;
    }
  }
}

.left-payment-info {
  @apply flex gap-2 items-center text-neutral-low-dark;

  @screen md:system {
    @apply gap-6;
  }
}
</style>
