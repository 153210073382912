const { track } = useMixpanel()
const impersonate = useImpersonate()
const impersonateStore = useImpersonateStore()

export function menuHeader() {
  const goSolfacilPlus = () => {
    track('usermenu_solfacilmais', { trigger: 'Clique em ver mais detalhes de SF+ dentro do menu do usuário' })
  }

  const goMenuUser = (menuUserClicked: string) => {
    const MENU_USER_TYPE: any = {
      gcCall: {
        event: 'usermenu_gc',
        propertie: 'Clique em Falar com GC dentro do menu do usuário',
      },
      helpCenter: {
        event: 'usermenu_faq',
        propertie: 'Clique em Central de Ajuda dentro do menu do usuário',
      },
      myCallings: {
        event: 'usermenu_support',
        propertie: 'Clique em Meus chamados dentro do menu do usuário',
      },
      inPersonification: {
        event: 'usermenu_impersonate',
        propertie: 'Clique em Encerrar personificação dentro do menu do usuário',
      },
      umPersonification: {
        event: 'usermenu_umpersonate',
        propertie: 'Clique em Personificar dentro do menu do usuário',
      },
    }
    track(`${MENU_USER_TYPE[menuUserClicked].event}`, { trigger: `${MENU_USER_TYPE[menuUserClicked].propertie}` })
    if (MENU_USER_TYPE[menuUserClicked].event === 'usermenu_impersonate') {
      impersonateStore.toggleImpersonateModal()
    }
    if (MENU_USER_TYPE[menuUserClicked].event === 'usermenu_umpersonate') {
      impersonate.removeImpersonate()
    }
  }

  const openProduct = (productClicked: string) => {
    const TRACKING_TYPE: any = {
      portal: {
        event: 'product_portal',
        propertie: 'Clique em Portal do Integrador dentro do menu de produtos',
      },
      shop: {
        event: 'product_store',
        propertie: 'Clique em Loja dentro do menu de produtos',
      },
      ampera: {
        event: 'product_ampera',
        propertie: 'Clique em Ampera dentro do menu de produtos',
      },
    }
    track(`${TRACKING_TYPE[productClicked].event}`, { trigger: `${TRACKING_TYPE[productClicked].propertie}` })
  }

  return {
    goSolfacilPlus,
    goMenuUser,
    openProduct,
  }
}
