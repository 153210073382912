import type { User } from '~/utils/auth/User'
import { identifyUser, trackEvent } from '~/modules/mixpanel'

export function useMixpanel() {
  const user: User = useAppStorage().get('user')
  const partner: number | undefined = user.parceiro?.id
  const email: string = user.email
  const name: string = user.nomeCompleto

  identifyUser(String(user.id)!)

  const track = (event: string, properties?: any) => {
    trackEvent(event, {
      ...properties,
      user,
      $partner_id: partner,
      email,
      name,
    })
  }

  return { track }
}
