<script setup lang="ts">
import type { AddonOpportunity, Addons, ParsedInstallment, SimulationOpportunities } from '~/utils/simulation/Simulation'
import IMaterialSymbolsWorkspacePremiumOutline from '~icons/material-symbols/workspace-premium-outline'
import useAuth from '~/store-v2/auth/useAuth'

import { useAddonStore } from '~/store/addons'

defineProps<{
  projectIsCompleted?: boolean
  installment?: ParsedInstallment | null
}>()

onBeforeMount(async () => {
  await simulationOpportunities()
})

const responseSimulationOpportunities = ref<SimulationOpportunities>()
const pointsInsuranceEquipment = ref<AddonOpportunity[]>()
const pointsInsuranceFinancialProtection = ref<AddonOpportunity[]>()
const { getSimulationOpportunities } = useResumeStore()
const { toggleInsurance, addons_list } = useAddonStore()
const { data: auth } = useAuth()

const userBlack = auth.value?.person.partner?.sfplus?.is_level_black

const { formatMoney } = useMoney()

function toggleAddonAdapter(value: any) {
  return toggleInsurance(value)
}

function getLabel(addonName: string) {
  const addon = addons_list.find(value => value.product_slug === addonName)
  if (addon?.applied)
    return 'Adicionado'

  return 'Adicionar'
}

function getIsApplied(addonName: string) {
  const addon = addons_list.find(value => value.product_slug === addonName)
  return addon?.applied
}

function verifyInsuranceIsAvailable() {
  return !!addons_list.find(e => e.product_slug === 'Prestamista')
}

function getFieldValueOfAddon(addon: string, field: keyof Addons) {
  const data = addons_list.find(value => value.product_slug === addon)

  if (data)
    return Math.abs(Number(data[field]))

  return null
}

async function simulationOpportunities() {
  responseSimulationOpportunities.value = getSimulationOpportunities()
  pointsInsuranceFinancialProtection.value = responseSimulationOpportunities?.value?.addons_opportunities.filter(opportunity => opportunity.product_slug === 'Riscos Diversos')
  pointsInsuranceEquipment.value = responseSimulationOpportunities?.value?.addons_opportunities.filter(opportunity => opportunity.product_slug === 'Prestamista')
}
</script>

<template>
  <div class="col-span-5" :class="projectIsCompleted ? 'cursor-not-allowed' : ''">
    <div class="details-container">
      <h2 class="text-2xs font-bold lg:site:mb-8">
        Seguros Solfácil
      </h2>
      <SolAlert v-if="!verifyInsuranceIsAvailable() && !projectIsCompleted" id="informative_insurance" feedback="informative" title="Seguro contra perda de renda" class="lg:site:mb-8">
        Devido às nossas políticas, este cliente infelizmente não é elegível para este seguro.
      </SolAlert>

      <!-- Seguro contra imprevistos no equipamento -->
      <div v-if="addons_list.find(item => item.product_slug === 'Riscos Diversos') && (!userBlack || !installment?.combo)" class="container-benefits-details">
        <div class="benefits-details-title">
          <img class="benefits-tag" src="/icons/insurance.svg">
          <div class="mb:site:w-[240px]">
            <h3 class="pb-2 flex flex-col lg:site:gap-1">
              <span class="fonts-body-large-bold benefits-title !text-3xs !md:site:mb-0">Seguro do equipamento solar</span>
              <span v-if="responseSimulationOpportunities && pointsInsuranceFinancialProtection" class="text-brand-secondary-dark flex items-center text-micro">
                <IMaterialSymbolsWorkspacePremiumOutline class="w-[16px] h-[16px] inline mr-1.5" />
                <span><strong>+{{ pointsInsuranceFinancialProtection[0].gained_points.toLocaleString('pt-BR') }} pontos </strong> no Solfácil Mais</span>
              </span>
            </h3>
            <p class="fonts-body-medium-regular text-brand-primary-medium">
              Proteja seu cliente contra imprevistos em equipamentos já instalados, como incêndios, danos elétricos, furtos e roubos.
              <a
                :href="projectIsCompleted ? '#' : 'https://seguros.solfacil.com.br/?_gl=1*am5k6q*_ga*ODYzMTgzMjkyLjE2ODAwOTkxMTQ.*_ga_NQJ9ZNTL71*MTY4NDkzNjA2Mi40Ny4xLjE2ODQ5MzYwNjkuMC4wLjA'"
                class="underline fonts-body-medium-regular"
                :class="projectIsCompleted ? 'text-brand-primary-medium opacity-75 cursor-not-allowed' : 'text-brand-secondary-pure'"
                target="_blank"
              >
                Mais detalhes
              </a>
            </p>
          </div>
        </div>
        <div class="benefits-details-elements">
          <div class="min-w-[142px] flex flex-col gap-1">
            <h3 class="text-[16px] benefits-title text-neutral-low-medium">
              <strong class="!text-[16px] fonts-body-medium-bold">{{ `+ ${formatMoney(getFieldValueOfAddon('Riscos Diversos', 'installment_price'))}` }}</strong><span class="text-[14px]">/mês</span>
            </h3>
            <p class="!text-[12px] text-neutral-low-light">
              <span class="!text-[12px] fonts-body-medium-bold">Sua comissão: </span><span>{{ formatMoney(getFieldValueOfAddon('Riscos Diversos', 'partner_commission')) }}</span>
            </p>
          </div>

          <SolSwitch
            id="Riscos Diversos"
            :label="getLabel('Riscos Diversos')"
            text-direction="right"
            :disabled="projectIsCompleted"
            :checked="getIsApplied('Riscos Diversos')"
            name="Riscos Diversos"
            value="Riscos Diversos"
            @change="toggleAddonAdapter"
          />
        </div>
      </div>

      <!-- Solfacil mais black -->
      <div v-if="userBlack && installment?.combo" class="container-benefits-details">
        <div class="benefits-details-title ">
          <img class="benefits-tag" src="/icons/insurance.svg">
          <div class="mb:site:w-[240px]">
            <h3 class="fonts-body-large-bold benefits-title !text-3xs">
              Seguro do equipamento solar
            </h3>
            <p class="fonts-body-medium-regular text-brand-primary-medium">
              Proteja seu cliente contra imprevistos em equipamentos já instalados, como incêndios, danos elétricos, furtos e roubos.
              <a
                :href="projectIsCompleted ? '#' : 'https://seguros.solfacil.com.br/?_gl=1*am5k6q*_ga*ODYzMTgzMjkyLjE2ODAwOTkxMTQ.*_ga_NQJ9ZNTL71*MTY4NDkzNjA2Mi40Ny4xLjE2ODQ5MzYwNjkuMC4wLjA'"
                class="underline fonts-body-medium-regular"
                :class="projectIsCompleted ? 'text-brand-primary-medium opacity-75 cursor-not-allowed' : 'text-brand-secondary-pure'"
                target="_blank"
              >
                Mais detalhes
              </a>
            </p>
          </div>
        </div>
        <div class="benefits-details-elements">
          <div class="min-w-[142px] flex flex-col gap-1">
            <h3 class="text-[16px] benefits-title text-neutral-low-medium">
              <strong class="!text-[16px] fonts-body-medium-bold">Grátis</strong>
            </h3>
            <p class="!text-[12px] text-neutral-low-light">
              <span>No Combo Fácil</span>
            </p>
          </div>
          <SolSwitch
            id="SolfacilMaisBlack"
            label="Adicionado"
            text-direction="right"
            :disabled="true"
            :checked="true"
            name="SolfacilMaisBlack"
            value="SolfacilMaisBlack"
          />
        </div>
      </div>
      <!-- Close Seguro contra imprevistos no equipamento -->

      <!-- Seguro contra perda de renda -->
      <div v-if="verifyInsuranceIsAvailable()" class="container-benefits-details">
        <div class="benefits-details-title ">
          <img class="benefits-tag" src="/icons/security-user.svg">
          <div class="mb:site:w-[240px]">
            <h3 class="pb-2 flex flex-col lg:site:gap-1">
              <span class="fonts-body-large-bold benefits-title !text-3xs !md:site:mb-0">Seguro proteção financeira</span>
              <span v-if="responseSimulationOpportunities && pointsInsuranceEquipment" class="text-brand-secondary-dark flex items-center text-micro">
                <IMaterialSymbolsWorkspacePremiumOutline class="w-[16px] h-[16px] inline mr-1.5" />
                <span><strong>+{{ pointsInsuranceEquipment[0].gained_points.toLocaleString('pt-BR') }} pontos </strong> no Solfácil Mais</span>
              </span>
            </h3>
            <p class="fonts-body-medium-regular text-brand-primary-medium">
              Protege seu cliente contra imprevistos financeiros como desemprego, perda de renda ou falecimento.
              <a
                :href="projectIsCompleted ? '#' : 'https://seguros.solfacil.com.br/?_gl=1*am5k6q*_ga*ODYzMTgzMjkyLjE2ODAwOTkxMTQ.*_ga_NQJ9ZNTL71*MTY4NDkzNjA2Mi40Ny4xLjE2ODQ5MzYwNjkuMC4wLjA'"
                class="underline fonts-body-medium-regular"
                :class="projectIsCompleted ? 'text-brand-primary-medium opacity-75 cursor-not-allowed' : 'text-brand-secondary-pure'"
                target="_blank"
              >
                Mais detalhes
              </a>
            </p>
          </div>
        </div>
        <div class="benefits-details-elements">
          <div class="min-w-[142px] flex flex-col gap-1">
            <h3 class="text-[16px] benefits-title text-neutral-low-medium">
              <strong class="!text-[16px] fonts-body-medium-bold">{{ `+ ${formatMoney(getFieldValueOfAddon('Prestamista', 'installment_price'))}` }}</strong><span class="text-[14px]">/mês</span>
            </h3>
            <p class="!text-[12px] text-neutral-low-light">
              <span class="!text-[12px] fonts-body-medium-bold">Sua comissão: </span><span>{{ formatMoney(getFieldValueOfAddon('Prestamista', 'partner_commission')) }}</span>
            </p>
          </div>
          <SolSwitch
            id="Prestamista"
            :label="getLabel('Prestamista')"
            text-direction="right"
            :disabled="projectIsCompleted"
            :checked="getIsApplied('Prestamista')"
            name="Prestamista"
            value="Prestamista"
            @change="toggleAddonAdapter"
          />
        </div>
      </div>
      <!-- Close Seguro contra perda de renda -->

      <!-- Banner Solfácil Mais Black -->
      <div
        v-if="userBlack && installment?.combo"
        class="bg-brand-primary-pure mt-5 rounded-md flex items-center justify-center flex-col h-[80px] overflow-hidden w-full p-4 lg:site:px-10"
      >
        <div class="flex justify-between items-center w-full gap-2">
          <div class="rounded-md bg-[#222] flex flex-col gap-1 text-neutral-high-light p-2">
            <h2 class="text-[16px] md:site:text-[20px] text-neutral-high-light font-bold">
              Seguro <span class="text-brand-secondary-medium font-normal">grátis</span>
            </h2>
            <p class="text-[12px]">
              Isso é benefício exclusivo
            </p>
          </div>

          <div>
            <!-- Mobile -->
            <img
              class="md:site:hidden"
              src="/images/logo-solfacil-mais-black-mobile.svg"
              alt="Logo Solfácil mais black"
              title="Solfácil mais black"
            >
            <!-- Desktop -->
            <img
              class="hidden md:site:block"
              src="/images/logo-solfacil-mais-black.svg"
              alt="Logo Solfácil mais black"
              title="Solfácil mais black"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.details-container {
  @apply flex justify-between w-full flex-col gap-6 lg:site:gap-0;
  @apply p-4xs my-6 bg-neutral-high-pure rounded-lg mb-giga;
  @screen lg:site {
    @apply p-xs;
  }

  > .container-benefits-details {
    @apply w-full my-0 flex flex-col;
    @screen lg:site {
      @apply w-full my-0 flex flex-row gap-6;
    }
    .benefits-details-title {
      @apply flex w-full mb-2xs;
      @screen lg:site {
        @apply flex w-full;
      }

      .benefits-title {
        @apply mb-nano text-stroke-brand-primary-dark;
      }

      > .benefits-tag {
        @apply text-md mr-nano mb-nano self-start max-w-[24px];
        @screen lg:site {
          @apply mx-nano;
        }
      }
    }
    .benefits-details-elements {
      @apply flex w-full self-center flex-col mt-0 gap-1 flex-row pl-8;
      @screen lg:site {
        @apply flex ml-nano text-right w-auto px-0 gap-6 lg:site:min-w-[292px];
      }
      .benefits-title {
        h3 {
          @apply ml-xs;
          @screen lg:site {
            @apply ml-0;
          }
        }
      }
      >p {
        @apply ml-xs;
        @screen lg:site {
          @apply ml-0;
        }
      }
      .sol-switch-core {
        @apply justify-end;
        @screen lg:site {
          @apply ml-0;
        }
        .label {
          @apply min-w-[70px] text-left;
        }
      }

    }
  }
}
</style>
