<script setup lang="ts">
import type { DocumentModel, DocumentStatusEnum, TypesErrorsCompanyDocuments } from '~/utils/company-register/CompanyRegister'
import type { StageFormalization } from '~/utils/customer-register/CustomerRegister'
import type { DocumentData, ExistingDocumentData } from '~/utils/document/DocumentData'
import type { FormalizationStatus } from '~/utils/financing-flow/Financing'
import { useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import { useField, useForm } from 'vee-validate'
import { nextTick } from 'vue'
import * as zod from 'zod'
import IconLock from '~icons/material-symbols/lock'
import CompanyRegisterService from '~/services/company-register/CompanyRegister'
import { useFinancing } from '~/store/useFinancing'
import { Formalization } from '~/types/enum'
import download from '~/utils/download'

const props = defineProps<{
  position: number
  statusCompanyform: string
  stage: StageFormalization
  stageReproved: string
  statusProject: FormalizationStatus
}>()
const emit
  = defineEmits<{
    (e: 'errorsCompanyDocuments', v: TypesErrorsCompanyDocuments): void
    (e: 'clientStatusDocumentStep', v: keyof DocumentStatusEnum): void
  }>()

const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'application/pdf']
const { createErrorToast, createSuccessToast } = useToast()
const { track } = useMixpanel()

const companyRegisterService = new CompanyRegisterService(useApi('customerRegister'))
const router = useRouter()
const id = router.currentRoute.value.params.id as string
const blocked = ref(true)
const { financing } = useFinancing(id)
const disableStepsAccordingStatus = ['canceled', 'expired'].includes(financing?.status ?? '')
const EnumTags: DocumentStatusEnum = {
  pending: {
    id: 'clientDataPending',
    text: 'Em andamento',
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  pendency: {
    id: 'clientDataPendency',
    text: 'Pendenciado',
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  under_analysis: {
    id: 'clientDataUnderAnalysis',
    text: 'Em análise',
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
  reproved: {
    id: 'clientDataReproved',
    text: 'Reprovado',
    variant: 'fill',
    size: 'medium',
    type: 'negative',
  },
  approved: {
    id: 'clientDataApproved',
    text: 'Aprovado',
    variant: 'fill',
    size: 'medium',
    type: 'positive',
  },
}

onMounted(async () => {
  checkStageReproved()
  await getDocumentData()
})

const loadingDocument = ref(false)
const status = ref('' as FormalizationStatus['documentation'])
const responseDocument = ref({} as DocumentModel)
const fieldIsDisabledBasedStatus = ref(false)
const currentDocuments = ref({
  identity: ref<ExistingDocumentData[]>([]),
  energy_bill: ref<ExistingDocumentData[]>([]),
  incorporation_document: ref<ExistingDocumentData[]>([]),
  additional_document: ref<ExistingDocumentData[]>([]),
})
const errorDocuments = ref({
  identity: false,
  energy_bill: false,
  incorporation_document: false,
  additional_document: false,
})

const tag = computed(() => EnumTags[props.statusProject.documentation])

const { t } = useI18n()
const statusIsPending = computed(() => status.value === 'pending')
const statusIsPendency = computed(() => status.value === 'pendency')
const statusIsUnderAnalysis = computed(() => status.value === 'under_analysis')
const statusIsApproved = computed(() => status.value === 'approved')
const initialValues = {
  uploadCompanyMajority: null,
  uploadCompanyLegalRepresentants: null,
  uploadCompanyDocument: null,
  uploadAdditionalDocument: null,
  additionalComments: '',
}

const validationSchema = computed(() => toTypedSchema(
  zod.object({
    uploadCompanyMajority: !hasFileOfType('identity') && !statusIsPending.value
      ? zod.any().optional()
      : zod.any().refine(uploadIdentifier => uploadIdentifier?.length, t('form.file_required')),
    uploadCompanyLegalRepresentants: !hasFileOfType('energy_bill') && !statusIsPending.value
      ? zod.any().optional()
      : zod.any().refine(uploadElectricityBill => uploadElectricityBill?.length, t('form.file_required')),
    uploadCompanyDocument: !hasFileOfType('incorporation_document') && !statusIsPending.value
      ? zod.any().optional()
      : zod.any().refine(uploadCompanyOfficializationDocuments => uploadCompanyOfficializationDocuments?.length, t('form.file_required')),
    uploadAdditionalDocument: zod.any().optional(),
    additionalComments: zod.any().optional(),
  }),
))

const { handleSubmit, setValues, validate, setFieldError } = useForm({
  validationSchema,
  initialValues,
})

const errorsValidation = ref(false)

onUpdated(async () => {
  updateStatusWithFlow()
})

function updateStatusWithFlow() {
  status.value = props.statusProject.documentation
}

async function getDocumentData() {
  loadingDocument.value = true

  try {
    const response = await companyRegisterService.get_documents_company(id)
    if (response) {
      responseDocument.value = response
      const { documents } = response
      setValues({
        additionalComments: response.comments,
      }, false)
      status.value = props.statusProject.documentation

      documents.forEach((document: DocumentData) => {
        const isIdentityDocument = document.type === 'identity'
        const isEnergyBillDocument = document.type === 'energy_bill'
        const isIncorporationDocument = document.type === 'incorporation_document'
        const isAdditionalDocument = document.type === 'additional_document'
        const applicationPdf = 'application/pdf'

        if (isIdentityDocument) {
          const existingDocument = currentDocuments.value.identity.some(item => item.id === document.id)

          if (!existingDocument) {
            const { id = '', filename = '', mimetype = '', url = '', url_inline = '' } = document

            currentDocuments.value.identity.push({
              id,
              fileName: filename,
              urlPreview: mimetype === applicationPdf ? url_inline : url,
              urlDownload: url,
              mimetype,
            })
          }
        }
        else if (isEnergyBillDocument) {
          const existingDocument = currentDocuments.value.energy_bill.some(item => item.id === document.id)

          if (!existingDocument) {
            const { id = '', filename = '', mimetype = '', url = '', url_inline = '' } = document

            currentDocuments.value.energy_bill.push({
              id,
              fileName: filename,
              urlPreview: mimetype === applicationPdf ? url_inline : url,
              urlDownload: url,
              mimetype,
            })
          }
        }
        else if (isIncorporationDocument) {
          const existingDocument = currentDocuments.value.incorporation_document.some(item => item.id === document.id)

          if (!existingDocument) {
            const { id = '', filename = '', mimetype = '', url = '', url_inline = '' } = document

            currentDocuments.value.incorporation_document.push({
              id,
              fileName: filename,
              urlPreview: mimetype === applicationPdf ? url_inline : url,
              urlDownload: url,
              mimetype,
            })
          }
        }
        else if (isAdditionalDocument) {
          const existingDocument = currentDocuments.value.additional_document.some(item => item.id === document.id)

          if (!existingDocument) {
            const { id = '', filename = '', mimetype = '', url = '', url_inline = '' } = document

            currentDocuments.value.additional_document.push({
              id,
              fileName: filename,
              urlPreview: mimetype === applicationPdf ? url_inline : url,
              urlDownload: url,
              mimetype,
            })
          }
        }
      })
    }

    emit('clientStatusDocumentStep', status.value)
    fieldIsDisabledBasedStatus.value = ['approved', 'reproved', 'under_analysis'].includes(status.value)

    if (status.value === 'pendency') {
      errorDocuments.value.additional_document = true

      if (!response.identity_matches_document) {
        nextTick(() => {
          setFieldError('uploadCompanyMajority', t('electricityBill.errorMessageInstallationDocumentMatchesBill'))
          errorsValidation.value = true
          errorDocuments.value.identity = true
        })
      }
      else {
        errorDocuments.value.identity = false
      }

      if (!response.installation_address_matches_bill) {
        nextTick(() => {
          setFieldError('uploadCompanyLegalRepresentants', t('formDocument.errorMessageInstallationAddressMatchesBill'))
          errorsValidation.value = true
          errorDocuments.value.energy_bill = true
        })
      }
      else {
        errorDocuments.value.energy_bill = false
      }

      if (!response.incorporation_document_matches_document) {
        nextTick(() => {
          setFieldError('uploadCompanyDocument', t('electricityBill.errorMessageCompany'))
          errorsValidation.value = true
          errorDocuments.value.incorporation_document = true
        })
      }
      else {
        errorDocuments.value.incorporation_document = false
      }

      emit('errorsCompanyDocuments', {
        identity_matches_document: response.identity_matches_document,
        incorporation_document_matches_document: response.incorporation_document_matches_document,
        installation_address_matches_bill: response.installation_address_matches_bill,
        installation_document_matches_bill: response.installation_document_matches_bill,
        legal_representant_missing: response.legal_representant_missing,
      } as TypesErrorsCompanyDocuments)

      errorsValidation.value = !response.identity_matches_document
      || !response.installation_address_matches_bill
      || !response.incorporation_document_matches_document
      || !response.installation_document_matches_bill
      || response.legal_representant_missing

      return
    }
    else {
      const documentKeys = [
        'identity',
        'energy_bill',
        'incorporation_document',
        'additional_document',
      ]

      documentKeys.forEach(key => errorDocuments.value[key] = false)
    }

    errorsValidation.value = false
  }
  catch {
    loadingDocument.value = false
  }
  finally {
    loadingDocument.value = false
  }
}

const loadingUpload = ref(false)

async function uploadFile(docUpload): Promise<void> {
  const formData = new FormData()
  formData.append('project_id', id)
  formData.append('additional_comments', docUpload.additionalComments || '')

  await docUpload.uploadCompanyMajority?.forEach(file => formData.append('identity', file, file.name))
  await docUpload.uploadCompanyLegalRepresentants?.forEach(file => formData.append('energy_bill', file, file.name))
  await docUpload.uploadIncorporationDocument?.forEach(file => formData.append('incorporation_document', file, file.name))
  await docUpload.uploadAdditionalDocument?.forEach(file => formData.append('additional_document', file, file.name))

  await companyRegisterService.upload_documents(id, formData)
}

const saveDocument = handleSubmit(async (event: any) => {
  loadingUpload.value = true
  const { valid } = await validate()

  if (valid) {
    try {
      const docUpload = {
        uploadCompanyMajority: event.uploadCompanyMajority,
        uploadCompanyLegalRepresentants: event.uploadCompanyLegalRepresentants,
        uploadIncorporationDocument: event.uploadCompanyDocument,
        uploadAdditionalDocument: event.uploadAdditionalDocument,
        additionalComments: event.additionalComments,
      }

      await uploadFile(docUpload)
      await getDocumentData()
      await companyRegisterService.confirm_documents(id)

      track('formalizing_documentation_button_next', { trigger: 'Clique no botão enviar em Documentação PJ' })
      fieldIsDisabledBasedStatus.value = true
    }
    catch {
      loadingUpload.value = false
      createErrorToast(t('formDocument.responseError'))
    }
    finally {
      loadingUpload.value = false
    }
  }
})

function hasFileOfType(type: string) {
  return !responseDocument.value?.documents?.some((document: any) => document.type === type)
}

const disabledFieldsAndButton = computed(() => disableStepsAccordingStatus || props.statusProject.registration === 'pending')
const showAlertMessageAwaitingCustomerData = computed(() => props.statusProject.registration === 'pending')
const disabledFieldsDeleteDocument = ref(false)
const blockedInput = computed(() => disabledFieldsAndButton.value || disabledFieldsDeleteDocument.value)
const disabledInput = computed(() => loadingUpload.value || blockedInput.value)
const showAlertReason = computed(() => {
  return statusIsPendency.value
    && !['', null].includes(responseDocument.value.reason)
    && props.statusProject.registration !== 'pending'
})

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.DOCUMENTATION
}

const disabledSaveButton = computed(() => disableStepsAccordingStatus || ((fieldIsDisabledBasedStatus.value || showAlertMessageAwaitingCustomerData.value) && !errorsValidation.value))

function clearDocumentUrls() {
  currentDocuments.value.identity = []
  currentDocuments.value.energy_bill = []
  currentDocuments.value.incorporation_document = []
  currentDocuments.value.additional_document = []
}

async function updateAfterAnalysisCanceled() {
  clearDocumentUrls()
  fieldIsDisabledBasedStatus.value = false
  status.value = 'pending'
}

const showModalToPreviewFile = ref(false)
const documentUrl = ref('')
const mimeTypeFile = ref('')
const fileName = ref('')

function previewFile(document: ExistingDocumentData) {
  documentUrl.value = document.urlPreview
  mimeTypeFile.value = document.mimetype
  fileName.value = document.fileName
  showModalToPreviewFile.value = true
}

async function deleteFile(name: string, document: ExistingDocumentData) {
  try {
    disabledFieldsDeleteDocument.value = true
    await companyRegisterService.delete_document(document.id)
    const index = currentDocuments.value[name].findIndex(item => item.id === document.id)
    currentDocuments.value[name].splice(index, 1)
    disabledFieldsDeleteDocument.value = false
    createSuccessToast(t('formDocument.responseSuccessDelete'))
  }
  catch {
    disabledFieldsDeleteDocument.value = false
    createErrorToast(t('formDocument.responseErrorDelete'))
  }
}
</script>

<template>
  <CustomerElementAccordion
    :id="`accordion_${Formalization.DOCUMENTATION}`"
    :title="t('customer.document_data_title')"
    :position="String(position)"
    :status="props.statusProject.documentation"
    :tag="blocked ? undefined : tag"
    :blocked="blocked"
    :open-collapse="!blocked && stage === Formalization.DOCUMENTATION"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>

    <template v-if="!loadingDocument">
      <div class="mt-2">
        <p>{{ t('customer.document_data_subtitle') }}</p>
      </div>
      <div class="form-client-data">
        <SolAlert
          v-if="showAlertMessageAwaitingCustomerData"
          id="informative-feedback-receipt-model"
          class="mb-6"
          :title="t('formDocument.messageTitleAwaitingCustomerData')"
          feedback="warning"
        >
          {{ t('formDocument.messageTextAwaitingCustomerData') }}
        </SolAlert>

        <SolAlert
          v-if="showAlertReason && responseDocument.documents?.length > 0"
          id="informative-feedback-receipt-model"
          class="my-3"
          :title="t('formDocument.alertError')"
          feedback="error"
        >
          {{ responseDocument.reason }}
        </SolAlert>

        <form>
          <div class="mt-4">
            <div class="mb-quark mb-0" :class="{ 'pointer-events-none opacity-60': disabledInput }">
              <strong class="label text-brand-primary-pure text-[14px]">
                {{ t('company_form_document.label_document_majority_and_legal_representants') }}
              </strong>
              <div>
                <small>{{ t('company_form_document.label_subtitle_document_majority_and_legal_representants') }}</small>
              </div>
            </div>
            <SolFileUpload
              v-if="!currentDocuments.identity.length"
              id="uploadCompanyMajority"
              name="uploadCompanyMajority"
              :class="{ 'pointer-events-none opacity-60': disabledInput }"
              :use-field="useField"
              :accept="ACCEPTED_FILE_TYPES.join(',')"
              :multiple="true"
              :placeholder="t('formDocument.placeholder')"
              label=""
              :disabled="blockedInput"
            />
            <SolFileUpload
              v-for="(identity, index) in currentDocuments.identity"
              id="uploadCompanyMajority"
              :key="index"
              name="uploadCompanyMajority"
              :class="{ 'pointer-events-none opacity-60': disabledInput, 'error-document': errorDocuments.identity }"
              :use-field="useField"
              label=""
              :disabled="blockedInput"
              :download-src="identity"
              :download-menu="errorDocuments.identity ? ['download', 'preview', 'delete'] : ['download', 'preview']"
              :file-name="identity.fileName"
              @menu:download="download.downloadFile(identity.urlDownload)"
              @menu:preview="previewFile(identity)"
              @menu:delete="deleteFile('identity', identity)"
            />
          </div>

          <div class="mt-4">
            <div class="mb-quark mb-0" :class="{ 'pointer-events-none opacity-60': disabledInput }">
              <strong class="label text-brand-primary-pure text-[14px]">
                {{ t('company_form_document.label_energy_bill') }}
              </strong>
              <div>
                <small>{{ t('company_form_document.label_subtitle_energy_bill') }}</small>
              </div>
            </div>
            <SolFileUpload
              v-if="!currentDocuments.energy_bill.length"
              id="uploadCompanyLegalRepresentants"
              name="uploadCompanyLegalRepresentants"
              :class="{ 'pointer-events-none opacity-60': disabledInput }"
              :use-field="useField"
              :accept="ACCEPTED_FILE_TYPES.join(',')"
              :multiple="true"
              :placeholder="t('formDocument.placeholder')"
              label=""
              :disabled="blockedInput"
            />
            <SolFileUpload
              v-for="(energy_bill, index) in currentDocuments.energy_bill"
              id="uploadCompanyLegalRepresentants"
              :key="index"
              name="uploadCompanyLegalRepresentants"
              :class="{ 'pointer-events-none opacity-60': disabledInput, 'error-document': errorDocuments.energy_bill }"
              :use-field="useField"
              label=""
              :disabled="blockedInput"
              :download-src="energy_bill"
              :download-menu="errorDocuments.energy_bill ? ['download', 'preview', 'delete'] : ['download', 'preview']"
              :file-name="energy_bill.fileName"
              @menu:download="download.downloadFile(energy_bill.urlDownload)"
              @menu:preview="previewFile(energy_bill)"
              @menu:delete="deleteFile('energy_bill', energy_bill)"
            />
          </div>

          <div class="mt-4">
            <div class="mb-quark mb-0" :class="{ 'pointer-events-none opacity-60': disabledInput }">
              <strong class="label text-brand-primary-pure text-[14px]">
                {{ t('company_form_document.label_document_company') }}
              </strong>
            </div>
            <div>
              <small>{{ t('company_form_document.label_subtitle_document_company') }}</small>
            </div>
            <SolFileUpload
              v-if="!currentDocuments.incorporation_document.length"
              id="uploadCompanyDocument"
              name="uploadCompanyDocument"
              :class="{ 'pointer-events-none opacity-60': disabledInput }"
              :use-field="useField"
              :accept="ACCEPTED_FILE_TYPES.join(',')"
              :multiple="true"
              :placeholder="t('formDocument.placeholder')"
              label=""
              :disabled="blockedInput"
            />
            <SolFileUpload
              v-for="(incorporation_document, index) in currentDocuments.incorporation_document"
              id="uploadCompanyDocument"
              :key="index"
              name="uploadCompanyDocument"
              :class="{ 'pointer-events-none opacity-60': disabledInput, 'error-document': errorDocuments.incorporation_document }"
              :use-field="useField"
              label=""
              :disabled="blockedInput"
              :download-src="incorporation_document"
              :file-name="incorporation_document.fileName"
              :download-menu="errorDocuments.incorporation_document ? ['download', 'preview', 'delete'] : ['download', 'preview']"
              @menu:download="download.downloadFile(incorporation_document.urlDownload)"
              @menu:preview="previewFile(incorporation_document)"
              @menu:delete="deleteFile('incorporation_document', incorporation_document)"
            />
          </div>

          <div class="mt-4">
            <div class="mb-quark mb-0" :class="{ 'pointer-events-none opacity-60': disabledInput }">
              <strong class="label text-brand-primary-pure text-[14px]">
                {{ t('formDocument.labelAdditionalDocument') }}
              </strong>
              <div>
                <small>{{ t('company_form_document.label_subtitle_additional_document') }}</small>
              </div>
            </div>
            <SolFileUpload
              v-if="!currentDocuments.additional_document.length"
              id="uploadAdditionalDocument"
              name="uploadAdditionalDocument"
              :class="{ 'pointer-events-none opacity-60': disabledInput || ((statusIsUnderAnalysis || statusIsApproved) && !currentDocuments.additional_document.length) }"
              :use-field="useField"
              :accept="ACCEPTED_FILE_TYPES.join(',')"
              :multiple="true"
              :placeholder="t('company_form_document.placeholder')"
              label=""
            />
            <SolFileUpload
              v-for="(additional_document, index) in currentDocuments.additional_document"
              id="uploadAdditionalDocument"
              :key="index"
              name="uploadAdditionalDocument"
              :class="{ 'pointer-events-none opacity-60': disabledInput || ((statusIsUnderAnalysis || statusIsApproved) && !currentDocuments.additional_document.length) }"
              :use-field="useField"
              label=""
              :download-src="additional_document"
              :download-menu="errorDocuments.additional_document ? ['download', 'preview', 'delete'] : ['download', 'preview']"
              :file-name="additional_document.fileName"
              @menu:download="download.downloadFile(additional_document.urlDownload)"
              @menu:preview="previewFile(additional_document)"
              @menu:delete="deleteFile('additional_document', additional_document)"
            />
          </div>

          <SolInputTextarea
            id="additionalComments"
            name="additionalComments"
            class="mt-4"
            :use-field="useField"
            :disabled="disabledInput || disabledSaveButton || statusIsUnderAnalysis || statusIsApproved"
            :label="t('company_form_document.additional_comments')"
            :placeholder="disabledInput || disabledSaveButton || statusIsUnderAnalysis || statusIsApproved ? '' : t('company_form_document.placeholder_additional_comments')"
          />

          <div class="flex justify-end border-t border-neutral-high-medium pt-6 mt-8 gap-4 flex-col md:system:flex-row -mb-12">
            <SharedCancelStep
              :status-step="statusProject.documentation"
              step-to-cancel="document_form"
              :disabled-button-by-status="disableStepsAccordingStatus"
              @step-canceled="updateAfterAnalysisCanceled()"
            />
            <SolButton
              id="document_confirm"
              class="w-full md:system:w-auto order-1 md:system:order-2"
              :loading="loadingUpload"
              size="large"
              :disabled="disabledSaveButton"
              @click="saveDocument"
            >
              {{ t('app.send') }}
            </SolButton>
          </div>
        </form>
      </div>
    </template>
    <template v-else>
      <div class="container">
        <div class="my-4 card-container flex flex-col justify-between w-full">
          <span class="loader-project" />
        </div>
      </div>
    </template>
  </CustomerElementAccordion>

  <SolModal
    id="modal-preview-file"
    :is-open="showModalToPreviewFile"
    title=""
    :size="{ desktop: 'extra-large', mobile: 'full' }"
    class="overflow-auto"
    @close="showModalToPreviewFile = false"
  >
    <div v-if="mimeTypeFile === 'application/pdf'">
      <iframe
        :src="documentUrl"
        width="100%"
        height="500px"
        :type="mimeTypeFile"
        frameborder="0"
      />
    </div>
    <div
      v-else
      class="flex justify-center w-full"
    >
      <img :src="documentUrl" :alt="fileName">
    </div>
  </SolModal>
</template>

<style lang="scss" scoped>
.error-message {
  @apply text-feedback-negative-pure py-nano;
  @apply fonts-subtitle-small;
}

.form-client-data {
  @apply mt-6 mb-0;
}

.error-document {
  :deep(.sol-download) {
    @apply border-feedback-negative-pure;
  }
}
</style>
