<script setup lang="ts">
import type { AddressFormModel, AddressFormRequest, ClientDataStatusEnum, PersonFormModel, PersonFormRequest, ResponseUpdatePerson, StageFormalization, TypesErrorBill, UpdateFormPartialResponse } from '~/utils/customer-register/CustomerRegister'
import type { FormalizationStatus } from '~/utils/financing-flow/Financing'
import { SolInputText, useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import dayjs from 'dayjs'
import { useField, useForm } from 'vee-validate'
import * as zod from 'zod'
import IconInfo from '~icons/material-symbols/info-outline'
import IconLock from '~icons/material-symbols/lock'
import CustomerRegisterService from '~/services/customer-register/CustomerRegister'
import { useFinancing } from '~/store/useFinancing'
import { Formalization } from '~/types/enum'
import { conventionalNameFormat } from '~/utils/formatter/name'

const props = defineProps<{
  personForm: PersonFormModel
  position: number
  plantAddress: AddressFormModel
  residentialAddress: AddressFormModel
  installationDocument: string
  errorDocumentBill: TypesErrorBill
  triggerRefetch: () => Promise<void>
  stage: StageFormalization
  stageReproved: string
  statusProject: FormalizationStatus
}>()

const customerRegisterService = new CustomerRegisterService(useApi('customerRegister'))
const router = useRouter()
const loadingCep = ref(false)
const stateOptions = ref()
const loadingLocal = ref(false)
const isCombo = ref(false)
const { track } = useMixpanel()
const blocked = ref(true)
const { financing } = useFinancing(String(router.currentRoute.value.params.id))
const disableStepsAccordingStatus = ['canceled', 'expired'].includes(financing?.status ?? '')
const createdPage = ref(false)

const { createSuccessToast, createErrorToast } = useToast()
const loadingInstallationCep = ref(false)

const isApprovedOrReprovedOrUnderAnalysis = ['approved', 'reproved', 'under_analysis'].includes(props.statusProject.registration)

const disabledStatus = isApprovedOrReprovedOrUnderAnalysis

const fieldIsDisabledBasedOnPersonIdOrStatus = computed(() => {
  if (disableStepsAccordingStatus)
    return true
  else
    return ((props.errorDocumentBill?.addressMatchesBill || props.errorDocumentBill?.documentMatchesBill) && props.statusProject.registration !== 'under_analysis') ? false : disabledStatus
})

const customerService = new CustomerRegisterService(useApi('customerRegister'))

onMounted(async () => {
  checkStageReproved()
  refreshValidationCep()
  await getIsCombo()
  createdPage.value = true
})

async function getIsCombo() {
  try {
    const { data } = await customerService.get_is_combo(String(router.currentRoute.value.params.id))
    if (data)
      isCombo.value = data
  }
  catch (error) {
    console.error('Error on get in combo is true or false', error)
  }
}

const { t } = useI18n()

function compareResidentialWithPlantAddress(plantAddress: AddressFormModel, resindentialAddress: AddressFormModel) {
  const plantInfo = {
    street: plantAddress.street,
    postal_code: plantAddress.postal_code,
    state: plantAddress.state?.name,
    number: plantAddress.street_number,
  }
  const residentialInfo = {
    street: resindentialAddress.street,
    postal_code: resindentialAddress.postal_code,
    state: resindentialAddress.state?.name,
    number: resindentialAddress.street_number,
  }
  return JSON.stringify(plantInfo) === JSON.stringify(residentialInfo)
}
const compared = compareResidentialWithPlantAddress(props.plantAddress, props.residentialAddress)
const localAddressRefine = ref(compared)
const electricityBillRefine = ref(compared)
const clientNameFormatted = conventionalNameFormat(props.personForm.full_name)
const initialValues = {
  // Client Data
  name: clientNameFormatted || '',
  document: props.personForm.cpf || '',
  phone: props.personForm.phone || '',
  email: props.personForm.email || '',
  birthdate: dayjs(props.personForm.birthdate).format('DD/MM/YYYY') || '',
  // Address Data - residential
  cep: props.residentialAddress.postal_code,
  street: props.residentialAddress.street,
  number: props.residentialAddress.street_number !== null ? String(props.residentialAddress.street_number) : '',
  complement: props.residentialAddress.additional_info,
  neighborhood: props.residentialAddress.neighborhood,
  state: {
    name: props.residentialAddress?.state?.name || '',
    value: Number(props.residentialAddress?.state?.id) || 0,
  },
  city: {
    name: props.residentialAddress?.city?.name || '',
    value: Number(props.residentialAddress?.city?.id) || 0,
  },
  // Address Data - installation
  localAddress: props.errorDocumentBill?.addressMatchesBill ? false : compareResidentialWithPlantAddress(props.plantAddress, props.residentialAddress),
  installationCountryside: false,
  installationCep: props.plantAddress.postal_code ? props.plantAddress.postal_code : props.residentialAddress.postal_code || '',
  installationStreet: props.plantAddress.street || '',
  installationNumber: props.plantAddress.street_number !== null ? String(props.plantAddress.street_number) : '',
  installationComplement: props.plantAddress.additional_info || '',
  installationNeighborhood: props.plantAddress.neighborhood || '',
  installationState: {
    name: props.plantAddress?.state?.name || props.residentialAddress?.state?.name,
    value: Number(props.plantAddress?.state?.id) || props.residentialAddress?.state?.id,
  },
  installationCity: {
    name: props.plantAddress?.city?.name || props.residentialAddress?.city?.name,
    value: Number(props.plantAddress?.city?.id) || props.residentialAddress?.city?.id,
  },
  // Electricity Bill
  electricityBill: props.errorDocumentBill?.documentMatchesBill ? false : !props.installationDocument,
  electricityBillDocument: props.installationDocument || '',
}

const validationSchema = computed(() => toTypedSchema(
  zod.object({
    // Client Data
    name: zod.string().min(1, { message: t('form.required') }),
    document: zod.string().min(14, t('form.invalid_document')).max(14, t('form.invalid_document')),
    phone: zod.string().min(16, t('form.invalid_phone')).max(16, t('form.invalid_phone')),
    email: zod.string().email({ message: t('form.invalid_email') }).min(1, t('form.required')),
    birthdate: zod.string().min(1, t('form.required')),
    // Address Data - residential
    cep: zod.string().min(1, { message: t('form.required') }),
    street: zod.string().min(1, { message: t('form.required') }),
    number: zod.string().min(1, { message: t('form.required') }),
    complement: zod.any(),
    neighborhood: zod.string().min(1, { message: t('form.required') }),
    state: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: initialValues.state.value ? zod.number().min(1, t('form.required')) : zod.any(),
    }),
    city: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: initialValues.city.value ? zod.number().min(1, t('form.required')) : zod.any(),
    }),
    localAddress: zod.boolean(),
    // Address Data - installation
    installationCountryside: zod.boolean(),
    installationCep: localAddressRefine.value ? zod.string().optional() : zod.string().min(1, t('form.required')),
    installationStreet: localAddressRefine.value ? zod.string().optional() : zod.string().min(1, { message: t('form.required') }),
    installationNumber: localAddressRefine.value ? zod.string().optional() : zod.string().min(1, { message: t('form.required') }),
    installationComplement: zod.any(),
    installationNeighborhood: localAddressRefine.value ? zod.string().optional() : zod.string().min(1, { message: t('form.required') }),
    installationState: localAddressRefine.value
      ? zod.any().optional()
      : zod.object({
        name: localAddressRefine.value ? zod.string().optional() : zod.string().min(1, { message: t('form.required') }),
        value: (() => {
          if (localAddressRefine.value)
            return zod.number().optional()
          else if (initialValues.installationState.value)
            return zod.number().min(1, { message: t('form.required') })
          else
            return zod.any()
        })(),
      }),
    installationCity: localAddressRefine.value
      ? zod.any().optional()
      : zod.object({
        name: localAddressRefine.value ? zod.string().optional() : zod.string().min(1, { message: t('form.required') }),
        value: (() => {
          if (localAddressRefine.value)
            return zod.number().optional()
          else if (initialValues.installationCity.value)
            return zod.number().min(1, { message: t('form.required') })
          else
            return zod.any()
        })(),
      }),
    // Electricity Bill
    electricityBill: zod.boolean(),
    electricityBillDocument: electricityBillRefine.value
      ? zod.string().optional()
      : zod.string().min(14, t('form.invalid_document')).max(14, t('form.invalid_document')),
  }),
))

const { handleSubmit, values, validate, setFieldError, errors, setValues } = useForm({
  validationSchema,
  initialValues,
})

watch(() => [
  values.localAddress,
  values.installationCountryside,
  values.electricityBill,
], () => {
  refreshValidationCep()
})

function refreshValidationCep() {
  if (!isApprovedOrReprovedOrUnderAnalysis) {
    fetchAddress()
    if (!values?.localAddress)
      fetchAddressInstallation()
  }
}

const createUser = handleSubmit(async () => {
  const { valid } = await validate()
  if (valid) {
    try {
      const parsedEventBody = createBodyData()

      if (parsedEventBody)
        await customerRegisterService.registration_confirm(parsedEventBody)

      await props.triggerRefetch()

      track('formalizing_button_finish', { trigger: 'Clique no botão avançar confirmando todo fluxo PF', ...parsedEventBody })

      return createSuccessToast('Cadastro realizado com sucesso!')
    }
    catch {
      return createErrorToast('Cadastro não realizado')
    }
  }
})

const debouncedUpdate = ref()
const loadingEmail = ref(false)

watch(values, async (newValues) => {
  localAddressRefine.value = newValues.localAddress || false
  electricityBillRefine.value = newValues.electricityBill || false

  const store = useStore()
  const sameInstallationLocation = compareResidentialWithPlantAddress(props.plantAddress, props.residentialAddress)
  if (sameInstallationLocation) {
    store.installationState = {
      name: String(newValues.state?.name),
      value: String(newValues.state?.value),
    }
  }
  else {
    store.installationState = {
      name: newValues.installationState?.name,
      value: newValues.installationState?.value,
    }
  }

  clearTimeout(debouncedUpdate.value)

  debouncedUpdate.value = setTimeout(async () => {
    const personForm = newValues
    const addressForm = newValues

    const residentialAddressForm: AddressFormRequest = {
      additional_info: addressForm.complement,
      neighborhood: addressForm.neighborhood,
      postal_code: addressForm.cep?.replace('-', ''),
      street: addressForm.street,
      street_number: Number(addressForm.number),
      city_id: addressForm?.city?.value || null,
      state_id: addressForm?.state?.value || null,
      rural_area: isCombo.value ? newValues.installationCountryside : false,
    }

    const plantAddressForm: AddressFormRequest = {
      additional_info: values.localAddress ? values.complement : values.installationComplement,
      neighborhood: values.localAddress ? values.neighborhood : values.installationNeighborhood,
      postal_code: values.localAddress ? values.cep : values.installationCep ? values.installationCep?.replace('-', '') : values.cep?.replace('-', ''),
      street: values.localAddress ? values.street : values.installationStreet,
      street_number: values.localAddress ? Number(values.number) : Number(values.installationNumber),
      city_id: values.localAddress ? values?.city?.value || null : Number(values?.installationCity?.value),
      state_id: values.localAddress ? values?.state?.value || null : Number(values?.installationState?.value),
      rural_area: isCombo.value ? values.installationCountryside : false,
    }
    const datePath = personForm?.birthdate?.split('/')

    if (!datePath)
      return

    const year = datePath[2]
    const month = datePath[1]
    const day = datePath[0]

    const dateParsed = new Date(Number.parseInt(year), Number.parseInt(month), Number.parseInt(day) + 1)

    const parsedPersonForm: PersonFormRequest = {
      email: personForm.email || '',
      full_name: personForm.name || '',
      birthdate: dateParsed.toISOString().split('T')[0],
      nationality_id: null,
      gender_id: null,
      occupation_id: null,
      mother_name: null,
      phone: personForm.phone || '',
    }

    const dataBody = {
      project_id: String(router.currentRoute.value.params.id),
      person_form: parsedPersonForm,
      residential_is_the_same_of_plant: addressForm.localAddress || false,
      residential_address_form: residentialAddressForm,
      plant_address_form: plantAddressForm,
      installation_document: newValues.electricityBillDocument || null,
    }

    if (!fieldIsDisabledBasedOnPersonIdOrStatus.value) {
      try {
        const response: ResponseUpdatePerson = await customerService.update_form_partial(dataBody)

        if ((values.email?.length ?? 0) > 0 && response?.data?.invalid_fields?.includes('email'))
          setFieldError('email', t('form.invalid_email'))
      }
      catch {
        loadingEmail.value = false
      }
      finally {
        loadingEmail.value = false
      }
    }
    else {
      loadingEmail.value = false
    }
  }, 1000)
})

async function fetchAddress() {
  try {
    loadingCep.value = true
    const { data } = await customerService.get_address(values.cep || '')
    setValues({
      street: values?.street || data?.street,
      neighborhood: values?.neighborhood || data?.neighborhood,
      state: {
        name: data?.state?.name,
        value: Number(data?.state.combo_id),
      },
      city: {
        name: data?.city?.name,
        value: Number(data?.city.combo_id),
      },
    }, false)
  }
  catch {
    if (values?.cep)
      setFieldError('cep', t('form.cepNotFound'))
  }
  finally {
    loadingCep.value = false
  }
}

async function fetchAddressInstallation() {
  try {
    loadingInstallationCep.value = true

    const { data } = await customerService.get_address(values.installationCep || '')
    setValues({
      installationStreet: values?.installationStreet || data?.street,
      installationNeighborhood: values?.installationNeighborhood || data?.neighborhood,
      installationState: {
        name: data?.state?.name,
        value: Number(data?.state.combo_id),
      },
      installationCity: {
        name: data?.city?.name,
        value: Number(data?.city.combo_id),
      },
    }, false)
  }
  catch {
    if (values?.installationCep)
      setFieldError('installationCep', t('form.cepNotFound'))
  }
  finally {
    loadingInstallationCep.value = false
  }
}

function createBodyData(): UpdateFormPartialResponse | undefined {
  const datePath = values.birthdate?.split('/')

  if (!datePath)
    return

  const year = datePath[2]
  const month = datePath[1]
  const day = datePath[0]

  const dateParsed = new Date(Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day) + 1)

  const parsedPersonForm: PersonFormRequest = {
    email: values.email || '',
    full_name: values.name || '',
    birthdate: dateParsed.toISOString().split('T')[0],
    nationality_id: null,
    gender_id: null,
    occupation_id: null,
    mother_name: null,
    phone: values.phone || '',
  }

  const residentialAddressForm: AddressFormRequest = {
    additional_info: values.complement,
    neighborhood: values.neighborhood,
    postal_code: values.cep?.replace('-', ''),
    street: values.street,
    street_number: Number(values.number),
    city_id: values?.city?.value || null,
    state_id: values?.state?.value || null,
    rural_area: isCombo.value ? values.installationCountryside : false,
  }

  const plantAddressForm: AddressFormRequest = {
    additional_info: values.localAddress ? values.complement : values.installationComplement,
    neighborhood: values.localAddress ? values.neighborhood : values.installationNeighborhood,
    postal_code: values.localAddress ? values.cep : values.installationCep?.replace('-', ''),
    street: values.localAddress ? values.street : values.installationStreet,
    street_number: values.localAddress ? Number(values.number) : Number(values.installationNumber),
    city_id: values.localAddress ? values?.city?.value || null : Number(values?.installationCity?.value),
    state_id: values.localAddress ? values?.state?.value || null : Number(values?.installationState?.value),
    rural_area: isCombo.value ? values.installationCountryside : false,
  }

  const dataBody = {
    project_id: String(router.currentRoute.value.params.id),
    person_form: parsedPersonForm,
    residential_is_the_same_of_plant: values.localAddress || false,
    residential_address_form: residentialAddressForm,
    plant_address_form: plantAddressForm,
    installation_document: values.electricityBillDocument || null,
  }

  return dataBody
}

const radiosAddress = [
  {
    name: 'localAddress',
    value: true,
    label: 'Sim',
  },
  {
    name: 'localAddress',
    value: false,
    label: 'Não',
  },
]

const radiosInstallationCountryside = [
  {
    name: 'installationCountryside',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },
  {
    name: 'installationCountryside',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
]

const radiosElectricityBill = [
  {
    name: 'electricityBill',
    value: true,
    label: `${t('electricityBill.yesMessage')}`,
  },
  {
    name: 'electricityBill',
    value: false,
    label: `${t('electricityBill.noMessage')}`,
  },
]

const status = props.statusProject.registration

const EnumTags: ClientDataStatusEnum = {
  under_analysis: {
    id: 'clientDataAnalysis',
    text: 'Em análise',
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
  approved: {
    id: 'clientDataApproved',
    text: 'Aprovado',
    variant: 'fill',
    size: 'medium',
    type: 'positive',
  },
  reproved: {
    id: 'clientDataReproved',
    text: 'Reprovado',
    variant: 'fill',
    size: 'medium',
    type: 'negative',
  },
  pending: {
    id: 'clientDataPending',
    text: 'Em andamento',
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  pendency: {
    id: 'clientDataPendency',
    text: 'Pendenciado',
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
}

watch(() => props.errorDocumentBill, (newValue) => {
  if (newValue.addressMatchesBill) {
    setValues({
      localAddress: false,
    }, false)
  }
  if (newValue.documentMatchesBill) {
    setValues({
      electricityBill: false,
    }, false)
    setTimeout(() => {
      setFieldError('electricityBillDocument', t('electricityBill.errorMessageInstallationDocumentMatchesBill'))
    })
  }
})

watch(() => values.email, (newValue) => {
  if (newValue !== '')
    loadingEmail.value = true
})

function fieldShouldBeValidated(field: number) {
  return field > 0
}

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.REGISTRATION
}

const checkStatusProject = computed(() => {
  return EnumTags[props.statusProject.registration]
})

watch(() => values.cep, (value) => {
  if (value)
    clearAddressFields('cep')
})

watch(() => values.installationCep, (value) => {
  if (value)
    clearAddressFields('installationCep')
})

watch(() => errors.value, (value) => {
  if (value.cep)
    clearAddressFields('cep')
  if (value.installationCep)
    clearAddressFields('installationCep')
})

function clearAddressFields(field: string) {
  if (!createdPage.value)
    return

  const emptyAddressFields = () => ({
    street: '',
    neighborhood: '',
    number: '',
    complement: '',
    state: {
      name: '',
      value: 0,
    },
    city: {
      name: '',
      value: 0,
    },
  })

  const emptyInstallationAddressFields = () => ({
    installationStreet: '',
    installationNeighborhood: '',
    installationNumber: '',
    installationComplement: '',
    installationState: {
      name: '',
      value: 0,
    },
    installationCity: {
      name: '',
      value: 0,
    },
  })

  if (field === 'cep')
    setValues(emptyAddressFields(), false)
  else if (field === 'installationCep')
    setValues(emptyInstallationAddressFields(), false)
}
</script>

<template>
  <CustomerElementAccordion
    v-if="!loadingLocal"
    :id="`accordion_${Formalization.REGISTRATION}`"
    :title="t('customer.client_data')"
    :position="String(position)"
    :tag="blocked ? undefined : checkStatusProject"
    :blocked="blocked"
    :open-collapse="!blocked && stage === Formalization.REGISTRATION"
    class="-mt-6"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>

    <div>
      <p class="mt-2">
        {{ t('customer.client_data_subtitle') }}
      </p>
    </div>
    <div class="form-client-data !mb-0">
      <form>
        <div class="grid grid-cols-1 md:system:grid-cols-12 gap-6 mt-6">
          <SolInputText
            id="name"
            class="md:system:col-span-8"
            name="name"
            :use-field="useField"
            :label="`${t('form.name')}*`"
            :disabled="fieldIsDisabledBasedOnPersonIdOrStatus"
            placeholder="Francisco Junior"
          />
          <SolInputText
            id="cpf"
            class="md:system:col-span-4"
            name="document"
            :use-field="useField"
            :label="`${t('form.cpf')}*`"
            :mask="{ preset: 'CPF' }"
            :disabled="true"
            placeholder="000.000.000-00"
          />
        </div>
        <div class="grid grid-cols-1 md:system:grid-cols-3 gap-6 mt-6">
          <SolInputText
            id="phone"
            name="phone"
            :mask="{ preset: 'MobilePhoneDDD' }"
            :label="`${t('form.phone')}*`"
            placeholder="(00) 0 0000 0000"
            :disabled=" disableStepsAccordingStatus || status !== 'pending'"
            :use-field="useField"
          >
            <template #icon:left>
              +55
            </template>
          </SolInputText>
          <SolInputText
            id="email-field"
            name="email"
            type="email"
            :disabled="fieldIsDisabledBasedOnPersonIdOrStatus"
            :label="`${t('form.email')}*`"
            :use-field="useField"
            placeholder="cliente@email.com"
            :is-loading="loadingEmail"
            :is-success="!!(!errors.email) && fieldShouldBeValidated(values.email?.length || 0) && !loadingEmail"
            :success-text="t('form.validatedEmail')"
          />
          <SolInputText
            id="birthdate"
            name="birthdate"
            :label="`${t('form.birthdate')}*`"
            :use-field="useField"
            mask="##/##/####"
            :disabled="true"
            placeholder="00/00/0000"
          />
        </div>
        <div class="title pt-4">
          <span class="font-highlight text-neutral-low-pure text-[16px] md:system:text-[20px]">
            {{ t('customer.address_data_title') }}
          </span>
        </div>
        <div class="grid grid-cols-1 md:system:grid-cols-12 gap-6 mt-6">
          <div class="md:system:col-span-3">
            <div class="ml-9 -mb-4">
              <SimulationTooltip position="right" :text="t('form.cepInfo')" class="tooltip z-100">
                <IconInfo class="cursor-pointer" />
              </SimulationTooltip>
            </div>
            <SolInputText
              id="cep-field"
              name="cep"
              :use-field="useField"
              mask="#####-###"
              :label="t('formAddress.cep')"
              placeholder="00000-000"
              :disabled="fieldIsDisabledBasedOnPersonIdOrStatus"
              :is-loading="loadingCep"
              :is-success="!!(!errors.cep) && fieldShouldBeValidated(values.cep?.length || 0) && !loadingCep"
              :success-text="t('form.validatedCep')"
              @blur="fetchAddress"
            />
            <SharedHelperCep />
          </div>
          <SolInputText
            id="street-field"
            class="md:system:col-span-5"
            name="street"
            :use-field="useField"
            :label="`${t('formAddress.street')}`"
            :disabled="!values.cep || fieldIsDisabledBasedOnPersonIdOrStatus"
          />
          <SolInputText
            id="number-field"
            class="md:system:col-span-2"
            name="number"
            :use-field="useField"
            :label="`${t('formAddress.number')}`"
            :disabled="!values.cep || fieldIsDisabledBasedOnPersonIdOrStatus"
          />
          <SolInputText
            id="complement-field"
            class="md:system:col-span-2"
            name="complement"
            :use-field="useField"
            :label="`${t('formAddress.complement')}`"
            :placeholder="t('formAddress.complementPlaceholder')"
            :disabled="!values.cep || fieldIsDisabledBasedOnPersonIdOrStatus"
          />
        </div>

        <div class="grid grid-cols-1 md:system:grid-cols-12 gap-6 mt-6 mb-6">
          <SolInputText
            id="neighborhood-field"
            class="md:system:col-span-3"
            name="neighborhood"
            :use-field="useField"
            :label="`${t('formAddress.neighborhood')}`"
            :disabled="!values.cep || fieldIsDisabledBasedOnPersonIdOrStatus"
          />
          <SolInputText
            id="city-field"
            class="md:system:col-span-7"
            name="city.name"
            :label="`${t('formAddress.city')}`"
            :use-field="useField"
            :disabled="true"
          />
          <SolInputText
            id="state-field"
            class="md:system:col-span-2"
            name="state.name"
            :label="`${t('formAddress.state')}`"
            :use-field="useField"
            :options="stateOptions"
            :disabled="true"
          />
        </div>

        <div v-if="isCombo">
          <span class="font-bold text-3xs text-brand-primary-pure">{{ t('formAddress.countryside') }}</span>

          <SolRadioGroup
            id="installationCountryside"
            class="mb-2"
            title=""
            name="installationCountryside"
            :use-field="useField"
            direction="row"
            :disabled="fieldIsDisabledBasedOnPersonIdOrStatus"
            :radios="radiosInstallationCountryside"
          />
        </div>

        <div class="mt-6">
          <span class="font-bold text-3xs text-brand-primary-pure">{{ t('formAddress.local') }}</span>

          <SolRadioGroup
            id="localAddress"
            title=""
            name="localAddress"
            :use-field="useField"
            direction="row"
            :disabled="fieldIsDisabledBasedOnPersonIdOrStatus"
            :radios="radiosAddress"
          />
        </div>

        <div :class="`${values.localAddress ? 'hidden' : 'block'}`">
          <span class="block font-highlight text-neutral-low-pure text-[16px] md:system:text-[20px] my-6">{{ t('formAddress.addressInstallation') }}</span>

          <SolAlert
            v-if="props.errorDocumentBill?.addressMatchesBill"
            id="warning-feedback"
            :title="t('customer.alertDocumentTitle')"
            feedback="warning"
          >
            {{ t('customer.alertDocumentMessage') }}
          </SolAlert>

          <div class="grid grid-cols-1 md:system:grid-cols-12 gap-6 mt-6">
            <div class="md:system:col-span-3">
              <div class="ml-9 -mb-4">
                <SimulationTooltip position="right" :text="t('form.cepInfo')" class="tooltip z-100">
                  <IconInfo class="cursor-pointer" />
                </SimulationTooltip>
              </div>
              <SolInputText
                id="cep-field-installation"
                name="installationCep"
                :use-field="useField"
                mask="#####-###"
                :label="t('formAddress.cep')"
                placeholder="00000-000"
                :disabled="fieldIsDisabledBasedOnPersonIdOrStatus"
                :is-loading="loadingInstallationCep"
                :is-success="!!(!errors.installationCep) && fieldShouldBeValidated(values.installationCep?.length || 0) && !loadingInstallationCep"
                :success-text="t('form.validatedCep')"
                @blur="fetchAddressInstallation"
              />
              <SharedHelperCep />
            </div>
            <SolInputText
              id="street-field-installation"
              class="md:system:col-span-5"
              name="installationStreet"
              :use-field="useField"
              :label="`${t('formAddress.installationStreet')}`"
              :disabled="!values.installationCep || fieldIsDisabledBasedOnPersonIdOrStatus"
            />
            <SolInputText
              id="number-field-installation"
              class="md:system:col-span-2"
              name="installationNumber"
              :use-field="useField"
              :label="`${t('formAddress.number')}`"
              :disabled="!values.installationCep || fieldIsDisabledBasedOnPersonIdOrStatus"
            />
            <SolInputText
              id="complement-field-installation"
              class="md:system:col-span-2"
              name="installationComplement"
              :use-field="useField"
              :label="`${t('formAddress.complement')}`"
              :placeholder="t('formAddress.complementPlaceholder')"
              :disabled="!values.installationCep || fieldIsDisabledBasedOnPersonIdOrStatus"
            />
          </div>
          <div class="grid grid-cols-1 md:system:grid-cols-12 gap-6 mt-6 mb-6">
            <SolInputText
              id="neighborhood-field-installation"
              class="md:system:col-span-3"
              name="installationNeighborhood"
              :use-field="useField"
              :label="`${t('formAddress.neighborhood')}`"
              :disabled="!values.installationCep || fieldIsDisabledBasedOnPersonIdOrStatus"
            />
            <SolInputText
              id="city-field-installation"
              class="md:system:col-span-7"
              name="installationCity.name"
              :label="`${t('formAddress.city')}`"
              :use-field="useField"
              :disabled="true"
            />
            <SolInputText
              id="state-field-installation"
              class="md:system:col-span-2"
              name="installationState.name"
              :label="`${t('formAddress.state')}`"
              :use-field="useField"
              :disabled="true"
            />
          </div>
        </div>

        <div class="mt-6 pt-6 border-t border-neutral-high-medium">
          <span class="block font-highlight text-neutral-low-pure text-[16px] md:system:text-[20px]">
            {{ t('electricityBill.title') }}
          </span>

          <span class="text-2xs text-neutral-low-light mt-2 mb-6 block">
            {{ t('electricityBill.description') }}
          </span>

          <span class="font-bold text-3xs text-brand-primary-pure">{{ t('electricityBill.question') }}</span>

          <SolRadioGroup
            id="electricityBill"
            title=""
            name="electricityBill"
            :use-field="useField"
            direction="row"
            :radios="radiosElectricityBill"
            :disabled="fieldIsDisabledBasedOnPersonIdOrStatus"
          />

          <div v-if="!values.electricityBill" class="mt-2 grid md:system:grid-cols-12">
            <SolInputText
              id="electricityBillDocument"
              class="md:system:col-span-4"
              name="electricityBillDocument"
              :use-field="useField"
              :mask="{ preset: 'CPF' }"
              :placeholder="t('electricityBill.placeholder')"
              :disabled="fieldIsDisabledBasedOnPersonIdOrStatus"
            />
          </div>
        </div>

        <div class="flex justify-end border-t border-neutral-high-medium pt-6 mt-8 gap-4 flex-col md:system:flex-row">
          <SharedCancelStep
            :status-step="statusProject.registration"
            step-to-cancel="registration"
            :disabled-button-by-status="disableStepsAccordingStatus"
          />

          <SolButton
            id="customer_confirm"
            class="w-full md:system:w-auto order-1 md:system:order-2"
            :loading="false"
            size="large"
            :disabled="Object.keys(errors).length ? true : false || fieldIsDisabledBasedOnPersonIdOrStatus"
            @click="createUser"
          >
            {{ t('app.send') }}
          </SolButton>
        </div>
      </form>
    </div>
  </CustomerElementAccordion>
</template>

<style lang="scss" scoped>
.error-message {
  @apply text-feedback-negative-pure py-nano;
  @apply fonts-subtitle-small;
}

.form-client-data {
  @apply mt-sm;
}

:deep(#input-text-input-phone) {
  @apply pl-xl;
}

[data-tooltip].tooltip:after {
  width: 250px;
  white-space: break-spaces;
}
</style>
