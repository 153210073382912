app:
  name: Financing
  next: Next
  advance: Advance
  advance_and_update: Update and advance
  share: Share
  whatsapp: WhatsApp
  send: Send
  save: Save
  cancel: Cancel
  cancel_analysis: Cancel analysis
  alert:
    partner:
      title: 'Viewing projects as: | Viewing project as: '
      statement_title: 'Viewing statement as: '
      textButton: Admin View
      description: >-
        Here you will find projects of just the partner above.
        To see all projects click on the button on the side. | To return to the admin view, click on the button on the side.
      statement_description: >-
        Here you will find the statement of just the partner above.
header:
  welcome: Welcome
  exit: Exit
  project:
    title: Financing
    steps:
      formalization: Proposal
      simulation: Simulation
      installation: Installation
    expired: Expired
  bnpl:
    steps:
      simulation: Simulation
      formalization: Proposal
      external: External
    status:
      IN_PROGRESS:
        type: warning
        text: In progress
      EXPIRED:
        type: neutral
        text: Expired
      DONE:
        type: positive
        text: Done
  impersonate:
    modal:
      title: View as a partner
      subtitle: Search by the Name or CNPJ of the partner you want to view
      input_label: Partner name or CNPJ
      button: View
      empty_state:
        title: Partner not found
        description: >-
          It seems that the partner you are looking for does not exist. Please
          check the data and try again.
    error:
      title: Error to view partner
      description: >-
        It was not possible to view the partner. Please check the data and try
        again.
home:
  title: Home
  subtitle: All your projects in one place
  newSimulation: New simulation
  banner:
    title: New formalization platform!
    subtitle: After completing the simulation, formalize faster in this new experience
simulation:
  next: View installments
  load_again: Load again
  founded: Simulations found
  golist: Go to list
  list: Simulations
  subtitles: Access your saved simulations
  new: New simulation
  project_type: Financing lines
  client_data: Client data
  cpf_use_terms: >-
    I declare that my client has authorized the consultation of their CPF or
    CNPJ in credit protection agencies.
  continue_with_value: Continue with approved amount
  financing_options: Financing options
  details:
    info_title: Simulation data for visualization purposes only.
    info_description: >-
      You will be able to review your simulation and change data during the
      contract signing stage.
  conclude: Conclude simulation
  info_risk:
    essential_profile_title: The client was approved in the simulation
    essential_profile_description: >-
      Attention! Project approved for credit, but in order to formalize this new
      project, it is necessary to prove the installation of other projects
      already formalized
  bnpl:
    solfacilInstallment: Solfacil Installment
    identity: id
    installment_title: |-
      Installment |
      Installments
    conditions_installment: >-
      Check out our options for the one that best fits your client's profile. It
      is possible to simulate the value of the project kit, and define it in the
      next stage, proposal.
    project_value: Project value
    due_date: Maturity
    invoice_due_date: Invoice due date
    kit_value_helper: Updated after kit selection
    kit_value: Kit value
    number_order: N° order
    service_value_helper: Project value minus kit and fee
    service_value: Service value
    installment_amount: Installment value
    initial_amount: Prohibited
    receive_amount: Will receive
    customer_pays_fee: Installment with fixed service
    store_pays_fee: Installment with fixed project
    customer_pays_fee_description: You pass on the fee to your client, increasing the value of the project.
    store_pays_fee_description: You pay the fee, maintaining the value of the project.
    id:
      summary:
        title:
          summary: Summary
          service: Fixed service
        installment:
          title: Installment
          prohibited: Prohibited
          cet: CET
          am: a.m.
        enterprise:
          title: For your company
          total: Total project value
          kit: Store Kit
          tax: Advance fee
          value: Amount to receive
        client:
          title: For your client
          total: Total project value
          prohibited: Prohibited
          installments: Installments
          of: of
  modal:
    title: New proposal platform!
    altImg: Funding Screen
    info:
      proposal:
        title: Send your proposal in up to 2 minutes!
        description: Fill out everything at once and wait for our analysis.
      projects:
        title: Make your projects whenever and wherever you want!
        description: 'Cell phone, computer or tablet: you choose how and where.'
      payments:
        title: Real-time payments
        description: After approval, payments 24 hours a day, 7 days a week.
    button:
      old: Continue with the old platform
      new: Continue with the new platform!
  notFound:
    title: Simulation not found
    subtitle: >-
      It seems that the simulation you are looking for does not exist. Please
      check the data and try again.
  errorProject:
    title: Error to load list
    subtitle: >-
      It seems that there was an error loading the list of simulations. Please
      try again later.
  status_filter:
    done: Approved
    pending: Pending
    reproved: Reproved
  result:
    bnpl:
      modals:
        important:
          description: >-
            You will be held responsible if the customer defaults. Solfácil may
            request that you pay the installment amounts in full, and may also
            enter your company's details in the Credit Protection Bodies.
      alerts:
        responsibility:
          description: >-
            Does the customer know that, even without credit, you pay in
            installments, assuming the risk? It's similar here, check
            conditions.
      will_be_pre_approved: Check the conditions that would be pre-approved
      approved_with_restrictions: Approved, with restrictions
      pre_approved_value: Pre-approved amount
      max_installments: Installments in up to
  return_formalization: Go to proposal
  ampera:
    lack_of_stock:
      text1: Due to high demand, Ampera is not available for this project.
      text2: >-
        But don't worry! Your client is still receiving the benefit of the
        discount when calculating the installment (even though it does not
        appear in the summary on the right). Installation will need to be proven
        with photos, without the need to install the ampera.
    textLink: More details
    description: >-
      Optimize your after-sales with monitoring and receive discounts on
      installments.
revision:
  title: Simulation review
  subtitle: >-
    Review and change all simulation data, including values, as long as they
    comply with our policies.
  subtitle_complement: Otherwise, it will be necessary to perform a new simulation.
buttons:
  back: Back
loading:
  messages: >-
    We're almost there, harnessing solar energy to power our calculations.
    Sunbeams are arriving, charging with the strength of the sun.
list:
  days: '- | {count} day | {count} days'
installments:
  with_combo: Installments with Easy Combo
  with_combo_description: >-
    The cheapest installment for your customer. The kit must be purchased at the
    Solfácil Store.
  with_combo_comission: Installments with Easy Combo and commission
  with_combo_comission_description: >-
    Best value for money for you and your client. The kit must be purchased at
    the Solfácil Store.
  without_combo: Installments without Easy Combo
  without_combo_description: >-
    Most expensive option for your client. The kit can be purchased outside the
    Solfácil Store.
form:
  alert_required_fields: Please fill in all required fields.
  file_required: File submission required.
  invalid_file_type: |
    Invalid file type. Accepted file types are: {types}
  required: Required field
  error: Registration not completed
  informativeCepText: Problems with the zip code?
  cepModal:
    title: Problems with the zip code?
    titleButton: Close
    informative:
      title: >-
        If you have problems with the CEP, watch the video or follow the steps
        below:
      item1: '1st Access the website Search postal code:'
      item2: >-
        2nd In the first field, write the name of the street, city and Federal
        Unit (abbreviated).

        In the second field it is not necessary to make changes.
      item3: 3rd Fill in the code shown in the image, and click the “Search” button.
      item4: >-
        4th On the next screen, the zip code that needs to be entered on the
        platform will appear.
      item5: 5th If your doubts persist, watch the video above for more information
  cepInfo: >-
    The zip code must be the same as that used for postal deliveries, even if a
    different one appears on the electricity bill.

    If the question persists, click on the button below "Problems with the zip
    code?".
  cancelAnalysis:
    confirm: Confirm cancellation
    back: Back
    title: Cancel the analysis?
    message: |-
      You are about to cancel the analysis of the information you entered.
      This will interrupt the process and a new submission will be required.
      Are you sure you want to continue?
    successToast: Analysis canceled successfully.
    errorToastDescription: Unable to cancel analysis.
customer:
  header:
    message:
      error: An error occurred while obtaining the proposal status.
    steps:
      simulation: Simulation
      installation: Installation
      formalization: Proposal
    tags:
      expired: Expired
      canceled: Canceled
      reproved: Failed
      approved: Approved
      pendency: Pending
      pending: In progress
      under_analysis: Under review
      send_contract: Send contract
      sent: Pending customer
      kit_pending: Pendant kit
      submitted: Sent
  canceled:
    title: Project canceled
    description: >-
      The client requested the cancellation of this project and the transfer of
      values will not be carried out. If it has already been done, it will be
      reversed. In the case of Combo Fácil, the kit order will also be
      cancelled.
  new: Proposal
  receipt_model_title: Receipt Model
  receipt_model_subtitle_eligible: >-
    Choose how you will receive the value of your project and attach the
    invoice(s).
  receipt_model_subtitle_not_eligible: Check your receipt model and attach the invoice(s).
  descriptionUnableContinuefinancing: >-
    Some customer data may need adjustment.

    Please check that the contact and address belong to the customer, not the
    seller or a relative.

    After this confirmation, create a new project and send it for analysis.

    Please note that, even so, the system may automatically disapprove the
    proposal in some cases, in accordance with our internal policy.
  document_data_subtitle: Please attach the following documents from your client below. Preferably in .pdf format.
company_form_document:
  label_document_majority_and_legal_representants: RG or CNH of the majority shareholder and legal representative(s) (if applicable)*
  label_energy_bill: Electricity bill for the installation site*
  label_document_company: Company officialization documents*
  label_additional_document: Additional documents
  label_subtitle_document_majority_and_legal_representants: >-
    We accept:
    RG or CNH or CNH Digital + QR Code or physical CTPS or active class card with photo.
    Documents must contain the CPF number.
  label_subtitle_energy_bill: >-
    Speed up your analysis!
    Give preference to .pdf files from the utility company's website.
    Send the complete bill, in the name of the individual or legal entity, issued within 90 days.
  label_subtitle_document_company: >-
    We accept one of these documents:
    declaration of individual microentrepreneur (MEI), articles of association, application for business or, in the absence of these, a certificate issued by the Commercial Board.
  label_subtitle_additional_document: >-
    If the place of establishment is not the address of the CNPJ or the residence of the legal representative or co-obligor, it will be necessary to send proof of residence in the name of one of these three entities of the place of establishment.
  additional_comments: Additional comments
  placeholder: Drag file here or click to upload
  placeholder_additional_comments: Optional
formDocument:
  labelDocumentSol: RG or CNH of client*
  labelElectricityBill: Electricity bill for the installation site*
  labelAdditionalDocument: Additional documents
  labelSubtitleDocument: >-
    We accept:
    RG or CNH or CNH Digital + QR Code or physical CTPS or active class card with photo.
    Documents must contain the CPF number.
  labelSubtitleElectricityBill: >-
    Speed up your analysis! Preferably .pdf files from the dealership website.
    Send the complete bill, in the name of the individual or legal entity, up to 90 days from the date of issue.
  labelSubtitleAdditionalDocument: >-
    Not mandatory.
    Example: proof of connection between the financing client and the electricity bill holder.
  additionalComments: Additional comments
  placeholder: Drag file here or click to upload
  placeholderAdditionalComments: Optional
  responseErrorDelete: Error deleting file, please try again.
  responseSuccessDelete: File deleted successfully.
solfacilPoints:
  label: Pontos Solfácil Mais
  phrases:
    one: 'How about adding to your financing:'
    two: Let's do the project better? How about adding
    three: Good project! How about adding {string}? Very good!
    four: Formalizing this project will bring you many benefits! Very good!
toastKitSelected:
  title: Selected Kit
  description: >-
    If available at the time of formalization, the kit will be invoiced
    automatically for your convenience.
receiptModel:
  tags:
    inAnalysis: In analysis
    approved: Approved
    denied: Rejected
    inProgress: In progress
    pendingChange: Pending invoice
    selected: Selected
    transferInProgress: Transfer in progress
  onflex:
    title: 100% receipt model after installation
    twoInvoices: in two notes
  alertInstallments:
    title: Receipt model in two payments ({firstValue}% and {secondValue}%)
  response:
    error: >-
      An error occurred while trying to update the receipt model. Please try
      again later.
    equipment_value_error: >-
      Equipment value must be greater than to financing amounts
    email_error: >-
      An error occurred while trying to update the email. Please try again later.
subscriptionEnergy:
  title: Your customer can still have solar energy!
  subtitle: >-
    Refer your customer to have solar energy by subscription with up to 20% off
    on the electricity bill! And earn a commission if they accept!
  recommend_customer: Refer customer
  alt_img: Image Subscription Solar Energy
  title_img: Subscription Solar Energy
project_data:
  status:
    pending: Pending
    selected: Selected
    in_progress: In progress
  errors:
    string_overload_max: >-
      Excessive power in the selected inverter(s). Reduce the quantity or choose
      lower power models.
    string_overload_min: >-
      Insufficient power in the selected inverter(s). Add another inverter or
      choose higher power models.
    microinverter_overload_max: >-
      Excessive power in the selected microinverters. Increase the quantity or
      choose lower power models.
    microinverter_overload_min: >-
      Insufficient power in the selected microinverters. Reduce the quantity of
      microinverters in the project or choose a higher power model.
    modules_overload: >-
      The selected modules do not seem to fit the project specifications. Please
      review the quantity or power of the modules.
financing:
  emptyList:
    title: No projects found
    subtitle: >-
      It seems that you have not yet created any projects.
    button_list: New project
  boxSearch:
    placeholder: Search by Name, CPF/CNPJ or ID
  filters:
    clear: |-
      Clear filter |
      Clear filters
    title: 'Filter projects by:'
    steps: Steps
    status: Status
  no_data: No data found
  notFound:
    subtitle: >-
      Search for another Name, CPF, CNPJ or project ID to find the project you
      are looking for.

      If it is correct, you can search for the project in the old list.
  list:
    step:
      canceled: Canceled
      concluded: Completed
      expired: Expired
      formalization: Proposal
      installation: Installation
      simulation: Simulation
    status:
      canceled: Canceled
      concluded: Completed
      expired: Expired
      kit_pending: Pendant kit
      pendency: Pending
      pending: In progress
      pending_customer: Pending customer
      pending_receipt: pending invoice
      reproved: Failed
      send_contract: Send contract
      under_analysis: Under review
  alert:
    title: Here you will only find loans created on the new platform
    subtitle: To access financing from the old platform, click the button on the right.
    action: View old list
installationPage:
  footer:
    formalization: Go to proposal
unauthorized:
  title: Unauthorized
  description: >-
    You do not have permission to access this page. If you believe this is an error,
    please contact the system administrator.
notFound:
  title: Page not found
  description: >-
    The page you are looking for does not exist. If you believe this is an error,
    please contact the system administrator.
unknonwError:
  title: Without information's
  description: >-
    An error occurred while processing your request. If you believe this is an error,
    please contact the system administrator.
noResults:
  title: No results found
  description: >-
    We couldn't find any results for your search. Try again with different terms.
