<script setup lang="ts">
import type { ReceiptModelStatusEnum, StageFormalization } from '~/utils/customer-register/CustomerRegister'
import type { FormalizationStatus } from '~/utils/financing-flow/Financing'
import type { ReceiptModel } from '~/utils/receipt-model/ReceiptModel'
import { useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import { isEmpty } from 'lodash'
import { FetchError } from 'ohmyfetch'
import { useField, useForm } from 'vee-validate'
import * as zod from 'zod'
import IMaterialSymbolsInfoOutline from '~icons/material-symbols/info-outline'
import IconLock from '~icons/material-symbols/lock'
import ToggleSwitch from '~/components/shared/ToggleSwitch.vue'
import ReceiptModelService from '~/services/receipt-model/ReceiptModel'
import { useFinancing } from '~/store/useFinancing'
import { Formalization, Journey } from '~/types/enum'

const props = defineProps<{
  position: number
  stage: StageFormalization
  stageReproved: string
  statusProject: FormalizationStatus
  isOnflexPartner: boolean
}>()

const { track } = useMixpanel()
const fieldIsDisabledBasedStatus = ref(false)
const { t } = useI18n()
const { createErrorToast, createSuccessToast } = useToast()
const receiptModelService = new ReceiptModelService(useApi('receiptModel'))
const router = useRouter()
const receiptModelData = ref({} as ReceiptModel)
const methodReceiptSeleted = ref('')
const methodInvoiceSeleted = ref('')
const submitForm = ref(false)
const files = ref<any[]>([])
const invoiceValueInput = ref(0)
const invoiceValueInputError = ref('')
const hasErrors = ref(false)
const hasCombo = ref(false)
const loadingGetProject = ref(false)
const loadingButtonSendFiles = ref(false)
const supplierOptions = ref<{ name: string, value: number }[]>([])
const notFoundShoppingCartId = ref(false)
const responseError = ref(false)
const invoicesToShow = ref()
const partnerProfile = ref('')
const { financing } = useFinancing(router.currentRoute.value.params.id as string)
const disableStepsAccordingStatus = ['canceled', 'expired'].includes(financing?.status ?? '')
const EnumTags: ReceiptModelStatusEnum = {
  in_analysis: {
    id: 'clientDataAnalysis',
    text: t('receiptModel.tags.inAnalysis'),
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  approved: {
    id: 'clientDataApproved',
    text: t('receiptModel.tags.approved'),
    variant: 'fill',
    size: 'medium',
    type: 'positive',
  },
  denied: {
    id: 'clientDataDenied',
    text: t('receiptModel.tags.denied'),
    variant: 'fill',
    size: 'medium',
    type: 'negative',
  },
  in_progress: {
    id: 'clientDataInProgress',
    text: t('receiptModel.tags.inProgress'),
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
  pending_change: {
    id: 'clientDataPendingChange',
    text: t('receiptModel.tags.pendingChange'),
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  selected: {
    id: 'clientDataSelected',
    text: t('receiptModel.tags.selected'),
    variant: 'fill',
    size: 'medium',
    type: 'positive',
  },
  transfer_in_progress: {
    id: 'clientDataTransferInProgress',
    text: t('receiptModel.tags.transferInProgress'),
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
}
const isEnabledToOnboardingFlex = ref(false)
const blocked = ref(true)
const isEligibleUser = ref(false)

onMounted(async () => {
  checkStageReproved()
  await getReceiptModel()
  setDisabledFields()
  getReceiptModelInitial()
  getInvoiceInitial()
  setHasCombo()
  transferPartner()
  setFeatureFlags()
  setValueIsEligibleUser()
})

const validationSchema = computed(() => toTypedSchema(
  zod.object({
    hardwareSupplier: zod.union([zod.object({ name: zod.string().optional(), value: zod.number().min(1, { message: t('form.required') }) }), zod.string().optional().refine(value => value !== undefined, { message: t('form.required') })]),
    emailInvoicing: zod.union([zod.string().min(1, { message: t('form.required') }), zod.string().optional().refine(value => value !== undefined && value !== '', { message: t('form.required') })]),
  }),
))

const { values, validate, setFieldValue, setFieldError } = useForm({
  validationSchema,
  initialValues: {
    hardwareSupplier: {
      name: 'Selecione',
      value: receiptModelData.value.supplier?.id || 0,
    },
    emailInvoicing: receiptModelData.value.supplier?.emails?.join(', ') || '',
  },
})

async function getReceiptModel() {
  try {
    loadingGetProject.value = true

    const response = await receiptModelService.get_receipt_model(String(router.currentRoute.value.params.id))

    if (!('equipment_not_found' in response) || !response.equipment_not_found)
      receiptModelData.value = response as ReceiptModel
    else
      notFoundShoppingCartId.value = true

    const invoices = receiptModelData.value.roles?.invoices
    partnerProfile.value = receiptModelData.value.partner_profile

    setInvoicesToShow(invoices)
    setEquipmentValue()
    await getSuppliers()
    setEmailFieldValue(receiptModelData.value.supplier?.emails)
  }
  catch (error) {
    console.error(error)
    responseError.value = true
  }
  finally {
    loadingGetProject.value = false
  }
}

function setInvoicesToShow(value) {
  invoicesToShow.value = !(receiptModelData.value.receipt_model === 'service_invoice' && !value.commission) && Object.values(value).includes(true)
}

function setEquipmentValue() {
  invoiceValueInput.value = receiptModelData.value.equipment_value || 0
}
function setEmailFieldValue(emails) {
  const emailValue = emails ? emails.join(', ') : ''
  setFieldValue('emailInvoicing', emailValue)
}

function setDisabledFields() {
  fieldIsDisabledBasedStatus.value = disableStepsAccordingStatus || getStatusProject(receiptModelData.value.status)
}
function getStatusProject(status) {
  const statusesShouldBeDisabled = ['approved', 'denied', 'in_analysis', 'transfer_in_progress']
  return statusesShouldBeDisabled.includes(status)
}
function setHasCombo() {
  hasCombo.value = receiptModelData.value.has_combo
}

function setValueIsEligibleUser() {
  if (receiptModelData?.value?.roles) {
    isEligibleUser.value = receiptModelData.value.roles.in_cash
  }
}

async function getSuppliers() {
  try {
    const response = await receiptModelService.get_receipt_model_suppliers()
    if (response?.length > 0) {
      const mapped = response.map(supplier => ({
        name: supplier.company_name,
        value: supplier.id,
      }))

      supplierOptions.value.push(...mapped)
      const filterSupplier = supplierOptions.value.find(supplier => supplier.value === receiptModelData.value.supplier?.id)

      setFieldValue('hardwareSupplier', filterSupplier)
    }
  }
  catch (error) {
    console.error(error)
  }
}

function getReceiptModelInitial() {
  methodReceiptSeleted.value = receiptModelData?.value?.issuance_model
}

function getInvoiceInitial() {
  if (hasCombo.value)
    methodInvoiceSeleted.value = ''
  else
    methodInvoiceSeleted.value = receiptModelData?.value?.receipt_model
}

function onSelected(type: string) {
  methodReceiptSeleted.value = type
  track('formalizing_receive-model_button-group_payment', { trigger: 'Clique no button group de à vista/parcelado', choice: type })
  submitForm.value = false
}

function onInvoiceSeleted(type: string) {
  methodInvoiceSeleted.value = type
  track('formalizing_receive-model_button-group_invoice', { trigger: 'Clique no button group de uma nota/duas notas', choice: type })
}

const inCash = computed(() => {
  return methodReceiptSeleted.value === 'in_cash'
})

function goStore() {
  track('formalizing_equipments_alert_store', { trigger: 'Clique no botão do alert para redirecionar à loja' })
  const url = 'https://loja.solfacil.com.br/'
  window.open(url, '_blank')
}

async function setFeatureFlags() {
  const user = useAppStorage().get('user')
  const flagNewJourney = await useFlag('new_journey', { partner_id: user?.parceiro?.id })
  isEnabledToOnboardingFlex.value = props.isOnflexPartner || (Number(flagNewJourney) === Journey.NEW && partnerProfile.value === 'essential')
}

// Modal
const modal = ref(false)
function openModal() {
  modal.value = true
  track('formalizing_receive-model_alert_more-info', { trigger: 'Clique no button no alert de Saiba Mais referente a pagamentos parcelados obrigatório' })
}

function closeModal() {
  modal.value = false
}
// Close Modal

const firstPercentToTransfer = ref(0)
const secondPercentToTransfer = ref(0)
const PERCENTAGE_DEFAULT = 50

function transferPartner() {
  if (receiptModelData.value.transfers) {
    const partnerTransfers = receiptModelData.value.transfers
      .filter(transfer => transfer.recipient === 'partner' && transfer.category === 'financing')
      .map(transfer => transfer.percent)

    if (partnerTransfers.length > 0)
      firstPercentToTransfer.value = partnerTransfers[0]

    if (partnerTransfers.length > 1)
      secondPercentToTransfer.value = partnerTransfers[1]
  }
}

function formattedTitle() {
  return isEnabledToOnboardingFlex.value
    ? t('receiptModel.onflex.title')
    : t('receiptModel.alertInstallments.title', {
      firstValue: firstPercentToTransfer.value || PERCENTAGE_DEFAULT,
      secondValue: secondPercentToTransfer.value || PERCENTAGE_DEFAULT,
    })
}

function formattedText() {
  return isEnabledToOnboardingFlex.value
    ? t('receiptModel.onflex.subtitle')
    : t('receiptModel.alertInstallments.text', {
      firstValue: firstPercentToTransfer.value || PERCENTAGE_DEFAULT,
      secondValue: secondPercentToTransfer.value || PERCENTAGE_DEFAULT,
    })
}

async function postReceiptModel(payload) {
  try {
    await receiptModelService.post_receipt_model(payload)
    track('formalizing_receive-model_button_next', { trigger: 'Clique no botão Próximo em Modelo de Recebimento', payload })
    createSuccessToast('Financiamento atualizado com sucesso.')
    await getReceiptModel()
  }
  catch (error) {
    if (error instanceof FetchError) {
      const response = error.data
      if (response.detail[0].type === 'value_error.email') {
        setFieldError('emailInvoicing', t('form.invalid_emails'))
        createErrorToast(t('receiptModel.response.email_error'))
        return
      }
    }
    createErrorToast(t('receiptModel.response.error'))
  }
  finally {
    submitForm.value = false
    loadingButtonSendFiles.value = false
  }
}

function createPayload(issuanceModel: string, receiptModel: string, supplierId: number | null = null, supplierEmail: string[] | null = null, equipmentValue: number | null = null) {
  return {
    project_id: String(router.currentRoute.value.params.id),
    issuance_model: issuanceModel,
    receipt_model: receiptModel,
    supplier_id: supplierId,
    supplier_email: supplierEmail,
    equipment_value: equipmentValue,
  }
}

function finalizeSendFilesProcess() {
  scrollIntoViewSupplier()
  submitForm.value = false
  loadingButtonSendFiles.value = false
}

function initSendFilesProcess() {
  submitForm.value = true
  loadingButtonSendFiles.value = true
}

async function sendFilesWhenOnflex(invoiceValueisValid, valid) {
  if (hasCombo.value) {
    const payload = createPayload(receiptModelData.value.issuance_model, 'service_invoice')
    await postReceiptModel(payload)
  }
  else {
    if (invoiceValueisValid && valid) {
      const _hardwareSupplier = typeof values.hardwareSupplier === 'string' ? null : values.hardwareSupplier
      const payload = createPayload(
        receiptModelData.value.issuance_model,
        receiptModelData.value.receipt_model,
        _hardwareSupplier?.value ?? null,
        (values.emailInvoicing ?? '').split(','),
        invoiceValueInput.value,
      )
      await postReceiptModel(payload)
    }
  }

  finalizeSendFilesProcess()
}

async function sendFiles() {
  initSendFilesProcess()
  const { valid } = await validate()
  invoiceValueInputError.value = projectError(invoiceValueInput.value)
  const invoiceValueisValid = !invoiceValueInputError.value

  if (isEnabledToOnboardingFlex.value) {
    await sendFilesWhenOnflex(invoiceValueisValid, valid)
    return
  }

  if (!receiptModelData.value?.roles?.in_cash) {
    if (hasCombo.value) {
      const payload = createPayload('installment', 'service_invoice')
      await postReceiptModel(payload)
      return
    }
    if (!hasCombo.value && invoiceValueisValid && valid) {
      const _hardwareSupplier = typeof values.hardwareSupplier === 'string' ? null : values.hardwareSupplier
      const payload = createPayload(
        'installment',
        'separate_invoices',
        _hardwareSupplier?.value ?? null,
        (values.emailInvoicing ?? '').split(','),
        invoiceValueInput.value,
      )
      await postReceiptModel(payload)
      return
    }
    submitForm.value = false
    loadingButtonSendFiles.value = false
    return
  }

  if (hasCombo.value) {
    const payload = createPayload(methodReceiptSeleted.value, 'service_invoice')
    await postReceiptModel(payload)
    return
  }

  if (methodInvoiceSeleted.value === 'separate_invoices' && invoiceValueisValid && valid) {
    const _hardwareSupplier = typeof values.hardwareSupplier === 'string' ? null : values.hardwareSupplier

    const payload = createPayload(
      methodReceiptSeleted.value,
      methodInvoiceSeleted.value,
      _hardwareSupplier?.value ?? null,
      (values.emailInvoicing ?? '').split(','),
      invoiceValueInput.value,
    )
    await postReceiptModel(payload)
    return
  }

  if (methodInvoiceSeleted.value === 'single_invoice') {
    const payload = createPayload(methodReceiptSeleted.value, methodInvoiceSeleted.value)
    await postReceiptModel(payload)
    return
  }

  finalizeSendFilesProcess()
}

function scrollIntoViewSupplier() {
  const infoSupplierElement = document.querySelector('#infoSupplier')
  if (infoSupplierElement)
    infoSupplierElement.scrollIntoView({ behavior: 'smooth' })
}
function handleUpdateErrors(newErrors) {
  isEmpty(newErrors) ? hasErrors.value = false : hasErrors.value = true
}

function handleReadyToSubmit(value: any) {
  files.value = value
}

const tag = computed(() => EnumTags[receiptModelData.value.status])

function notEmpty(value: number | string | null | undefined) {
  if (!value)
    return 'Campo obrigatório'

  return ''
}

function projectError(value: number) {
  const financingAmout = Number(receiptModelData.value.financing_value.toFixed(2))
  if (value > financingAmout)
    return t('receiptModel.response.equipment_value_error')

  if (!value)
    return notEmpty(value)

  if (value < 0)
    return 'O valor não pode ser negativo'

  return ''
}

function isMobileDevice() {
  return window.innerWidth < 768
}

watch(invoiceValueInput, (value) => {
  invoiceValueInputError.value = projectError(value)
})

function titleModal() {
  return isEnabledToOnboardingFlex.value
    ? t('receiptModel.onflex.title')
    : t(hasCombo.value ? 'receiptModel.modalInstallments.titleWithCombo' : 'receiptModel.modalInstallments.titleWithoutCombo')
}

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.RECEIPT_MODEL
}
</script>

<template>
  <div class="step-receipt">
    <template v-if="loadingGetProject">
      <CustomerElementAccordion
        :id="`accordion_${Formalization.RECEIPT_MODEL}`"
        :open-collapse="!blocked && stage === Formalization.RECEIPT_MODEL"
        :title="t('customer.receipt_model_title')"
        :position="String(position)"
        :tag="blocked ? undefined : tag"
        :blocked="blocked"
        class="receipt-model -mt-2 lg:system:-mt-8"
      >
        <template #icon>
          <IconLock v-if="blocked" />
        </template>

        <div class="container">
          <div class="my-4 card-container flex flex-col justify-between w-full">
            <span class="loader-project" />
          </div>
        </div>
      </CustomerElementAccordion>
    </template>
    <template v-else>
      <CustomerElementAccordion
        :id="`accordion_${Formalization.RECEIPT_MODEL}`"
        :open-collapse="!blocked && stage === Formalization.RECEIPT_MODEL"
        :title="t('customer.receipt_model_title')"
        :position="String(position)"
        :tag="blocked ? undefined : tag"
        :blocked="blocked"
        class="receipt-model -mt-2 lg:system:-mt-8"
      >
        <template #icon>
          <IconLock v-if="blocked" />
        </template>

        <template v-if="notFoundShoppingCartId">
          <SolAlert
            id="informative-feedback-receipt-model"
            class="mt-8"
            :title="t('receiptModel.alert.title')"
            feedback="informative"
            action="Ir para Loja"
            @click="goStore()"
          >
            {{ t('receiptModel.alert.text') }}
          </SolAlert>
        </template>
        <template v-else-if="responseError">
          <div class="w-full flex justify-center">
            <SolEmptyState
              id="error"
              :title="t('receiptModel.error.title')"
              :subtitle="t('receiptModel.error.subtitle')"
              variant="error404"
            />
          </div>
        </template>
        <template v-else>
          <div>
            <div>
              <p class="mt-2">
                {{ isEligibleUser ? t('customer.receipt_model_subtitle_eligible') : t('customer.receipt_model_subtitle_not_eligible') }}
              </p>
            </div>
            <div class="method_receipt">
              <template v-if="!isEligibleUser && !isEnabledToOnboardingFlex">
                <SolAlert
                  v-if="receiptModelData?.roles?.invoices?.separate_invoices"
                  id="informative-feedback-receipt-model"
                  class="mb-2xs"
                  :title="`${formattedTitle()} ${t('receiptModel.onflex.twoInvoices')}`"
                  feedback="informative"
                >
                  {{ t('customer.alertInstallment') }}
                </SolAlert>

                <SolAlert
                  v-else
                  id="informative-feedback-receipt-model"
                  class="mb-2xs"
                  :title="formattedTitle()"
                  feedback="informative"
                  action="Saiba mais"
                  @click="openModal"
                >
                  {{ formattedText() }}
                </SolAlert>
              </template>

              <template v-if="isEnabledToOnboardingFlex">
                <SolAlert
                  id="informative-feedback-onboarding-flex"
                  class="mb-2xs"
                  :title="formattedTitle()"
                  feedback="informative"
                  action="Saiba mais"
                  @click="openModal"
                >
                  {{ formattedText() }}
                </SolAlert>
                <template v-if="!hasCombo">
                  <div id="infoSupplier" class="pb-8">
                    <span class="font-highlight pb-2 text-neutral-low-pure text-[16px] md:system:text-[20px]">
                      {{ t('customer.supplier_information') }}
                    </span>

                    <form>
                      <div class="grid grid-cols-1 gap-4 md:site:grid-cols-2 md:site:gap-6 mt-4">
                        <SolSelect
                          id="hardwareSupplier"
                          name="hardwareSupplier"
                          :use-field="useField"
                          :disabled="fieldIsDisabledBasedStatus"
                          :label="t('customer.supplier_equipment')"
                          :options="supplierOptions"
                        />
                        <InputMoneyInput
                          id="invoiceValue"
                          v-model="invoiceValueInput"
                          name="invoiceValue"
                          size="medium"
                          required
                          :disabled="fieldIsDisabledBasedStatus"
                          :error="invoiceValueInputError"
                          :label="t('customer.invoice_value')"
                          :placeholder="t('customer.invoice_value_placeholder')"
                        />
                      </div>

                      <div class="mt-4 grid grid-cols-1">
                        <div class="mb-quark flex">
                          <strong class="label text-brand-primary-pure text-[14px]">
                            {{ t('customer.emails_to_send') }}
                          </strong>
                          <SimulationTooltip
                            :position="isMobileDevice() ? 'bottom' : 'right'"
                            :text="t('customer.emails_tooltip')"
                          >
                            <IMaterialSymbolsInfoOutline class="ml-1.5" />
                          </SimulationTooltip>
                        </div>
                        <SolInputText
                          id="emailInvoicing"
                          name="emailInvoicing"
                          :use-field="useField"
                          :disabled="fieldIsDisabledBasedStatus"
                          :placeholder="t('customer.emails_to_send_placeholder')"
                        />
                      </div>
                    </form>
                  </div>
                </template>
              </template>
              <template v-else>
                <template v-if="isEligibleUser">
                  <div class="title">
                    <span class="label text-[16px] md:system:text-[20px] pb-0 font-highlight text-neutral-low-dark">
                      {{ t('customer.method_receipt') }}
                    </span>
                  </div>
                  <!-- À vista ou parcelado -->
                  <div class="switch flex py-nano md:system:pt-4xs md:system:pb-2">
                    <ToggleSwitch
                      :disabled="fieldIsDisabledBasedStatus"
                      :active="methodReceiptSeleted"
                      :first-option="{ title: 'À vista', key: 'in_cash' }"
                      :second-option="{ title: 'Parcelado', key: 'installment' }"
                      @selected="onSelected"
                    />
                  </div>
                  <div class="subtitle-installment">
                    <template v-if="inCash">
                      <span class="fonts-body-small-regular text-brand-primary-dark">
                        {{ hasCombo ? t('customer.in_cash_subtitle_with_combo') : t('customer.in_cash_subtitle_without_combo') }}
                      </span>
                    </template>
                    <template v-else>
                      <span
                        v-if="receiptModelData.roles.invoices.commission"
                        class="fonts-body-small-regular text-brand-primary-dark"
                      >
                        {{ t('customer.in_installment_subtitle', { count: hasCombo ? 1 : 0 }) }}
                      </span>
                      <span v-else class="fonts-body-small-regular text-brand-primary-dark">
                        {{ t('customer.in_installment_without_service_invoice_subtitle') }}
                      </span>
                    </template>
                  </div>
                </template>

                <template v-if="!hasCombo">
                  <template v-if="isEligibleUser && methodInvoiceSeleted !== 'service_invoice'">
                    <div class="switch flex py-nano md:system:pt-4xs md:system:pb-2">
                      <ToggleSwitch
                        :disabled="fieldIsDisabledBasedStatus"
                        :active="methodInvoiceSeleted"
                        :first-option="{ title: 'Uma nota', key: 'single_invoice' }"
                        :second-option="{ title: 'Duas notas', key: 'separate_invoices' }"
                        @selected="onInvoiceSeleted"
                      />
                    </div>
                    <template v-if="methodInvoiceSeleted === 'single_invoice'">
                      <div class="subtitle-installment mb-6">
                        <span class="fonts-body-small-regular text-brand-primary-dark">
                          {{ t('customer.service_products') }}
                        </span>
                        <span
                          v-if="methodReceiptSeleted === 'in_cash'"
                          class="fonts-body-small-regular text-neutral-low-light block pt-2"
                        >
                          {{ t('customer.method_receipt_seleted_in_cash') }}
                        </span>
                        <span v-else class="fonts-body-small-regular text-neutral-low-light block pt-2">
                          {{ t('customer.method_receipt_seleted_installment') }}
                        </span>
                      </div>
                    </template>
                    <template v-if="methodInvoiceSeleted === 'separate_invoices'">
                      <div class="subtitle-installment mb-6">
                        <span class="fonts-body-small-regular text-brand-primary-dark">
                          {{ t('customer.separate_invoices_subtitle') }}
                        </span>
                        <span class="fonts-body-small-regular text-neutral-low-light block pt-2">
                          {{ t('customer.second_separate_invoices_subtitle') }}
                        </span>
                      </div>
                      <div id="infoSupplier" class="pb-8">
                        <span class="font-highlight pb-2 text-neutral-low-pure text-[16px] md:system:text-[20px]">
                          {{ t('customer.supplier_information') }}
                        </span>

                        <form>
                          <div class="grid grid-cols-1 gap-4 md:site:grid-cols-2 md:site:gap-6 mt-4">
                            <SolSelect
                              id="hardwareSupplier"
                              name="hardwareSupplier"
                              :use-field="useField"
                              :disabled="fieldIsDisabledBasedStatus"
                              :label="t('customer.supplier_equipment')"
                              :options="supplierOptions"
                            />
                            <InputMoneyInput
                              id="invoiceValue"
                              v-model="invoiceValueInput"
                              name="invoiceValue"
                              size="medium"
                              required
                              :disabled="fieldIsDisabledBasedStatus"
                              :error="invoiceValueInputError"
                              :label="t('customer.invoice_value')"
                              :placeholder="t('customer.invoice_value_placeholder')"
                            />
                          </div>

                          <div class="mt-4 grid grid-cols-1">
                            <div class="mb-quark">
                              <strong class="label text-brand-primary-pure text-[14px]">
                                {{ t('customer.emails_to_send') }}
                              </strong>
                              <SimulationTooltip
                                :position="isMobileDevice() ? 'bottom' : 'right'"
                                :text="t('customer.emails_tooltip')"
                              >
                                <IMaterialSymbolsInfoOutline class="ml-1.5" />
                              </SimulationTooltip>
                            </div>
                            <SolInputText
                              id="emailInvoicing"
                              name="emailInvoicing"
                              :use-field="useField"
                              :disabled="fieldIsDisabledBasedStatus"
                              :placeholder="t('customer.emails_to_send_placeholder')"
                            />
                          </div>
                        </form>
                      </div>
                    </template>
                  </template>

                  <template v-if="!isEligibleUser && receiptModelData?.receipt_model === 'separate_invoices'">
                    <div id="infoSupplier" class="pb-8">
                      <span class="font-highlight pb-2 text-neutral-low-pure text-[16px] md:system:text-[20px]">
                        {{ t('customer.supplier_information') }}
                      </span>

                      <form>
                        <div class="grid grid-cols-1 gap-4 md:site:grid-cols-2 md:site:gap-6 mt-4">
                          <SolSelect
                            id="hardwareSupplier"
                            name="hardwareSupplier"
                            :use-field="useField"
                            :disabled="fieldIsDisabledBasedStatus"
                            :label="t('customer.supplier_equipment')"
                            :options="supplierOptions"
                          />
                          <InputMoneyInput
                            id="invoiceValue"
                            v-model="invoiceValueInput"
                            name="invoiceValue"
                            size="medium"
                            required
                            :disabled="fieldIsDisabledBasedStatus"
                            :error="invoiceValueInputError"
                            :label="t('customer.invoice_value')"
                            :placeholder="t('customer.invoice_value_placeholder')"
                          />
                        </div>

                        <div class="mt-4 grid grid-cols-1">
                          <div class="mb-quark">
                            <strong class="label text-brand-primary-pure text-[14px]">
                              {{ t('customer.emails_to_send') }}
                            </strong>
                            <SimulationTooltip
                              :position="isMobileDevice() ? 'bottom' : 'right'"
                              :text="t('customer.emails_tooltip')"
                            >
                              <IMaterialSymbolsInfoOutline class="ml-1.5" />
                            </SimulationTooltip>
                          </div>
                          <SolInputText
                            id="emailInvoicing"
                            name="emailInvoicing"
                            :use-field="useField"
                            :disabled="fieldIsDisabledBasedStatus"
                            :placeholder="t('customer.emails_to_send_placeholder')"
                          />
                        </div>
                      </form>
                    </div>
                  </template>
                </template>
              </template>

              <FormalizationReiceiptModelFormsInCashForm
                v-if="inCash && invoicesToShow"
                :data="receiptModelData"
                :invoice-seleted="methodInvoiceSeleted"
                :submit="submitForm"
                :combo="hasCombo"
                :receipt-model-status="receiptModelData.status as unknown as ReceiptModelStatusEnum"
                @ready-to-submit="handleReadyToSubmit"
                @update-errors="handleUpdateErrors"
              />
              <FormalizationReiceiptModelFormsInstallmentForm
                v-if="!inCash && invoicesToShow"
                :data="receiptModelData"
                :invoice-seleted="methodInvoiceSeleted"
                :submit="submitForm"
                :combo="hasCombo"
                :receipt-model-status="receiptModelData.status as unknown as ReceiptModelStatusEnum"
                @ready-to-submit="handleReadyToSubmit"
                @update-errors="handleUpdateErrors"
              />

              <template v-if="receiptModelData.roles?.invoices?.commission">
                <CustomerElementCommissionInvoiceInfo
                  :solfacil-data="receiptModelData.solfacil_info"
                  :commission-value="receiptModelData.commissions.total.value"
                />
              </template>
            </div>

            <CustomerElementSummary
              :data="receiptModelData"
              :combo="hasCombo"
              :is-onboarding-flex="isEnabledToOnboardingFlex"
            />
          </div>

          <div>
            <SolDivider thickness="x-small" orientation="horizontal" class="mt-8 mb-6" />

            <div class="flex justify-end">
              <SolButton
                id="address_confirm" size="large" :loading="loadingButtonSendFiles" :disabled="fieldIsDisabledBasedStatus"
                @click="sendFiles()"
              >
                {{ (receiptModelData && receiptModelData.status === 'in_progress') ? t('app.save') : t('app.send') }}
              </SolButton>
            </div>
          </div>
        </template>
      </CustomerElementAccordion>
    </template>

    <SolModal
      id="receipt-model-modal"
      :title="titleModal()"
      :is-open="modal" action-primary-text="Fechar" @close="closeModal" @action:primary="closeModal"
    >
      <template v-if="isEnabledToOnboardingFlex">
        <div>
          {{ t('receiptModel.onflex.principalText') }}
          <div class="mt-4">
            {{ t('receiptModel.onflex.topic') }}
          </div>
          <ul class="pl-8">
            <li class="list-disc">
              {{ t('receiptModel.onflex.firstTopic') }}
            </li>
            <li class="list-disc">
              {{ t('receiptModel.onflex.secondTopic') }}
            </li>
          </ul>
        </div>
      </template>
      <template v-else>
        <div>
          <p>
            {{ t('receiptModel.modalInstallments.principalText', {
              firstValue: receiptModelData.transfers.length ? receiptModelData.transfers[0].percent : PERCENTAGE_DEFAULT,
              secondValue: receiptModelData.transfers.length ? receiptModelData.transfers[1].percent : PERCENTAGE_DEFAULT,
            }) }}
            <ul class="pl-8">
              <li class="list-disc">
                {{ t('receiptModel.modalInstallments.firstTopic') }}
              </li>
              <li class="list-disc">
                {{ t('receiptModel.modalInstallments.secondTopic') }}
              </li>
            </ul>
          </p>
        </div>
      </template>
    </SolModal>
  </div>
</template>

<style lang="scss">
.step-receipt {
  .receipt-model .method_receipt {
    @apply my-2xs p-4xs;
    @apply border-a-sm border-neutral-low-light rounded-md;

    md:system {
      @apply my-xs p-2xs;
    }
  }

  .receipt-model .title label {
    @apply font-highlight text-neutral-low-pure;
  }

  .method_receipt .subtitle {
    @apply leading-3 mt-quark mb-2xs;
  }

  .sol-button-core.-secondary {
    min-width: fit-content !important;
  }

  .sol-alert-core.-informative {
    @apply flex flex-col md:site:flex-row gap-4 md:site:gap-0;
  }

  .subtitle-installment {
    @apply leading-3 mt-quark;
  }
}

.sol-upload {
  @apply p-5;
}

.sol-select-core>.select-button-toggle {
  @apply h-auto;
}

.sol-input-currency-core>.container-input {
  @apply ml-1;
}
</style>
