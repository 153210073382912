import type { AxiosError } from 'axios'
import axios from 'axios'

import env from '~/env'
import {
  injectAccessTokenInterceptor,
  refreshTokenResponseInterceptor,
} from '~/services-v2/keycloack-api/keycloack-api.interceptors'
import { AuthorizationError } from './payment-bff.errors'

const http = axios.create({
  baseURL: env.VITE_PAYMENT_BFF_API_URL,
})

function isErrorResponse(error: AxiosError): error is AxiosError<{ code: number }> {
  const errorData = error.response?.data as { code: number }

  return errorData?.code !== undefined
}

function errorInterceptor(error: AxiosError) {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 404) {
      return Promise.resolve({ filters: { customer_document: null }, results: [] })
    }

    if (error.response?.status === 403 && isErrorResponse(error)) {
      return Promise.reject(new AuthorizationError('You are not allowed to access this resource', error.response.data.code, error))
    }
  }
  return Promise.reject(error)
}

// @ts-expect-error: error is not a valid axios response
http.interceptors.request.use(injectAccessTokenInterceptor)
http.interceptors.response.use(value => value, refreshTokenResponseInterceptor)
http.interceptors.response.use(value => value, errorInterceptor)

export default http
