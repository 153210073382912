<script setup lang="ts">
import type { ProjectStatusEnum, StageFormalization } from '~/utils/customer-register/CustomerRegister'
import type { FormalizationStatus } from '~/utils/financing-flow/Financing'
import type { HardwareCombosModel } from '~/utils/installation/Installation'
import IconLock from '~icons/material-symbols/lock'
import { useFinancing } from '~/store/useFinancing'
import { Formalization } from '~/types/enum'

const props = defineProps<{
  position: number
  stage: StageFormalization
  stageReproved: string
  statusProject: FormalizationStatus
  formData?: HardwareCombosModel
  refetch: () => Promise<void>
}>()

const { t } = useI18n()
const router = useRouter()
const id = router.currentRoute.value.params.id as string
const { financing } = useFinancing(id as string)

const hasSelectedHardware = ref(false)
const hasSelectedSystemInfo = ref(false)
const blocked = ref(true)

const EnumTags: ProjectStatusEnum = {
  pending: {
    id: 'pending',
    text: t('project_data.status.in_progress'),
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  submitted: {
    id: 'selected',
    text: t('project_data.status.selected'),
    variant: 'fill',
    size: 'medium',
    type: 'positive',
  },
  in_progress: {
    id: 'inProgress',
    text: t('project_data.status.in_progress'),
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
  kit_pending: {
    id: 'kitPending',
    text: t('project_data.status.kit_pending'),
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  approved: {
    id: 'approved',
    text: t('project_data.status.approved'),
    variant: 'fill',
    size: 'medium',
    type: 'positive',
  },
}

const tag = computed(() => props.statusProject.contract === 'approved' && props.statusProject?.hardware !== 'kit_pending' ? EnumTags.approved : EnumTags[props.statusProject.hardware])

onMounted(async () => {
  checkStageReproved()
  hasSelectedHardware.value = (props.formData?.modules?.length ?? 0) > 0 && ((props.formData?.inverters?.length ?? 0) > 0 || (props.formData?.microinverters?.length ?? 0) > 0)
  hasSelectedSystemInfo.value = Boolean(props.formData?.distributor && props.formData.phase)
})

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.HARDWARE
}

function openCollapse() {
  const hasSelected = !(hasSelectedHardware.value && hasSelectedSystemInfo.value)
  const formalization = props.stage === Formalization.HARDWARE
  const hardware = financing?.section_statuses?.formalization?.hardware === 'kit_pending'

  return (hasSelected && !blocked.value && formalization) || (hardware && !blocked.value && formalization)
}
</script>

<template>
  <CustomerElementAccordion
    :id="`accordion_${Formalization.HARDWARE}`"
    :title="t('customer.project_data_title')"
    :position="String(position)"
    :tag="blocked ? undefined : tag"
    :blocked="blocked"
    :open-collapse="openCollapse()"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>

    <div>
      <p class="mt-2">
        {{ t('customer.project_data_subtitle') }}
      </p>
    </div>

    <div v-if="props.formData">
      <CustomerFormsHardwareForm :hardware-information="props.formData" :refetch-form="props.refetch" />
    </div>
    <div v-else class="my-4 card-container flex flex-col justify-between w-full align-middle py-lg text-center items-center">
      <img src="/images/not-found.png" class="not-found">
      <h1 class="fonts-heading-h1">
        Ops, algo deu errado
      </h1>
      <p class="fonts-subtitle-medium">
        Ocorreu algum problema e não conseguimos trazer os dados dos seus equipamentos.
      </p>
    </div>
  </CustomerElementAccordion>
</template>

<style lang="scss" scoped>
.form-project-data {
  @apply mt-sm mb-0;
}
</style>
