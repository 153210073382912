import type { AxiosResponse } from 'axios'
import axios from 'axios'
import env from '~/env'
import {
  injectAccessTokenInterceptor,
  refreshTokenResponseInterceptor,
} from '~/services-v2/keycloack-api/keycloack-api.interceptors'

const http = axios.create({
  baseURL: env.VITE_FINANCIAL_BFF_API_URL,
})

function graphqlQlErrorHandler(response: AxiosResponse<{ data: any, errors: any[] }>) {
  if (response.data.errors)
    throw response.data.errors
  return response
}
// @ts-expect-error: error is not a valid axios response
http.interceptors.request.use(injectAccessTokenInterceptor)
http.interceptors.response.use(graphqlQlErrorHandler, refreshTokenResponseInterceptor)

export default http
