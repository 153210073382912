import type { ModelTypes } from './__generated__/zeus'
import { Thunder } from './__generated__/zeus'
import http from './financial-bff.http'

const thunder = Thunder(async (query, variables) => {
  const res = await http.post('/graphql', {
    query,
    variables,
  })

  return res.data.data
})

export type Partner = ModelTypes['CorePartner']

export default thunder
