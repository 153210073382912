<script setup lang="ts">
import Santander from '~/pages/company/santander/index.vue'
import Solfacil from '~/pages/company/solfacil/index.vue'
import CompanyRegisterService from '~/services/company-register/CompanyRegister'
import InstallationService from '~/services/installation/Installation'
import SimulatorService from '~/services/simulator/Simulator'
import useFinancingV2 from '~/store-v2/financings/useFinancing'
import { ProviderEnum } from '~/utils/company-register/CompanyRegister'
import { getErrorMessage } from '~/utils/errors'
import verify from '~/utils/verify'

const { track } = useMixpanel()

const providersOptions = {
  [ProviderEnum.SANTANDER]: Santander,
  [ProviderEnum.SOLFACIL]: Solfacil,
}

const router = useRouter()
const project_id = router.currentRoute.value.params.id as string
const { isLoading: isFinancingLoading } = useFinancing(project_id)
const {
  error,
  isError,
  isLoading,
} = useFinancingV2(ref(project_id))
const simulatorService = new SimulatorService(useApi('simulator'))
const projectPersonType = ref('')
const isOnflexPartner = ref(false)
const loadingGetProject = ref(false)
const companyRegisterService = new CompanyRegisterService(useApi('customerRegister'))
const companyRegisterProvider = ref<ProviderEnum | null>(null)
const projectName = ref('')
const companyForm = ref()
const hardwareForm = ref()
const loadingPageCustomer = ref(false)
const errorMessage = computed(() => getErrorMessage(error.value))

onMounted(async () => {
  trackEventMixpanel()

  await getProject()
  await Promise.all([
    getCustomerRegistrationForm(),
    fetchHardwareForm(),
  ])
})

async function getProject() {
  try {
    loadingGetProject.value = true
    const { data } = await simulatorService.get_project(project_id)

    if (data) {
      const verifyOldJourney = verify.oldJourneyFinancingListRedirect(data.new_journey || false)
      if (verifyOldJourney)
        return

      projectName.value = data?.project_name
      projectPersonType.value = data?.person_type
      isOnflexPartner.value = data?.is_onflex_partner || false
    }
  }
  catch (error) {
    console.error('File [id].vue > Não foi possível carregar as informações do projeto. Por favor, tente novamente.', error)
  }
  finally {
    loadingGetProject.value = false
  }
}

async function TriggerRefetch() {
  await getCustomerRegistrationForm()
}

async function getCustomerRegistrationForm() {
  loadingPageCustomer.value = true
  try {
    const response_customer_form = (await companyRegisterService.get_company_registration_form(project_id, projectPersonType.value)).data

    companyForm.value = response_customer_form

    if (response_customer_form?.provider?.name || response_customer_form?.id)
      companyRegisterProvider.value = response_customer_form?.provider?.name as ProviderEnum || 'solfacil'

    loadingPageCustomer.value = false
  }
  catch {
    loadingPageCustomer.value = false
  }
  finally {
    loadingPageCustomer.value = false
  }
}

async function fetchHardwareForm() {
  try {
    const installationService = new InstallationService(useApi('installation'))
    const hardwareFormResponse = (await installationService.get_hardware_model(project_id))

    hardwareForm.value = hardwareFormResponse
  }
  catch {}
}

function trackEventMixpanel() {
  track('customer_data_page_view', { trigger: 'Ver a página Dados do cliente' })
}

const showEmptyState = computed(() => {
  return ((projectPersonType.value && projectPersonType.value !== 'PJ')
    && router.currentRoute.value.fullPath.includes('company')) || !hardwareForm.value
    || !companyRegisterProvider.value
})

const loading = computed(() => loadingPageCustomer.value || loadingGetProject.value || isFinancingLoading || isLoading.value)

const mappingErrors = computed (() => ['NOT_FOUND', 'UNAUTHORIZED', 'UNKNOWN'].includes(errorMessage.value))
</script>

<template>
  <div v-if="loading" class="container">
    <div class="my-4 card-container flex flex-col justify-between w-full">
      <span class="loader-project" />
    </div>
  </div>
  <div v-else>
    <template v-if="isError && mappingErrors">
      <SharedErrorsCheckAccess :error-message="errorMessage" />
    </template>
    <template v-else>
      <div v-if="projectPersonType && projectPersonType === 'PJ' && companyRegisterProvider && hardwareForm">
        <h1>
          <component
            :is="providersOptions[companyRegisterProvider]"
            :company-form="companyForm"
            :trigger-refetch="TriggerRefetch"
            :is-onflex-partner="isOnflexPartner"
            :project-name="projectName"
            :provider="companyRegisterProvider"
            :status="companyForm.status"
            :hardware-form="{ response: hardwareForm, refetch: fetchHardwareForm }"
          />
        </h1>
      </div>
      <div
        v-if="showEmptyState"
        class="container"
      >
        <div class="bg-neutral-high-pure w-full rounded-lg p-10 flex items-center justify-center">
          <SolEmptyState
            id="emptyStateCustomer"
            :title="$t('emptyStateCustomer.title')"
            :subtitle="$t('emptyStateCustomer.subtitle')"
            variant="no-content"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.container {
  @apply flex flex-col items-center px-4xs pb-6 mt-6;
  @screen md:system {
    @apply p-12 mt-0;
  }
}

.loader-project {
  @apply h-96 rounded-lg;
  @apply top-0 right-0 bottom-0 left-0 z-50;
  background: rgba(#fff, 0.9)
    url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat
    center center;
}
</style>

<route lang="yaml">
meta:
  layout: client-data
</route>
