import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financingBff from '~/services-v2/financial-bff/financial-bff'

export type { Partner } from '~/services-v2/financial-bff/financial-bff'

export interface UsePartnersParams {
  page: number
  search_term?: string
}

export default function usePartners(params: Ref<UsePartnersParams>) {
  const queryKey = ref(queryKeys.PARTNERS.LIST(params.value))

  const queryFn = async () => {
    const res = await financingBff('query')({
      partners: [{
        input: {
          page: params.value.page,
          search_term: params.value.search_term,
        },
      }, {
        id: true,
        cnpj: true,
        responsible: {
          id: true,
          full_name: true,
        },
        company_name: true,
        fantasy_name: true,
        status: {
          active: true,
          reason: true,
          date: true,
        },
      }],
    })

    return res.partners
  }

  const query = useQuery({
    queryKey,
    queryFn,
    staleTime: 1000 * 30,
  })

  watch(params, () => {
    queryKey.value = queryKeys.PARTNERS.LIST(params.value)
  }, { deep: true })

  return query
}

export type UsePartnersData = ReturnType<typeof usePartners>['data']
