<script setup lang="ts">
import type { FunctionalComponent, SVGAttributes } from 'vue'
import type { UsePartnersParams } from '../store-v2/partners/usePartners'
import { SolHeader, useToast } from '@solfacil/girassol'
import IconMoney from '~icons/girassol/attach-money'
import useAuth from '~/store-v2/auth/useAuth'
import useLogout from '~/store-v2/auth/useLogout'
import { useCollapseStore } from '~/store/collapse'
import usePartners from '../store-v2/partners/usePartners'

const { createErrorToast } = useToast()
const impersonate = useImpersonate()
const { t } = useI18n()
const impersonateStore = useImpersonateStore()
const store = useCollapseStore()
const route = useRoute()
const { data: auth } = useAuth()
const { mutateAsync: logout } = useLogout()
const partnerName = ref('')
const partnerSelected = ref<{
  fantasyName: string
  partnerId: number
}>()

const user = {
  profile: auth.value?.me.perfil,
  completeName: auth.value!.me.nomeCompleto,
}

const params = ref<UsePartnersParams>({
  page: 0,
  search_term: '',
})

const {
  data: partners,
  isLoading: isLoadingPartners,
  isFetching: isFetchingPartners,
} = usePartners(params)

interface Product {
  id: 'portal' | 'shop' | 'financing' | 'ampera'
  // eslint-disable-next-line ts/no-empty-object-type
  icon?: FunctionalComponent<SVGAttributes, {}>
  title: string
  // eslint-disable-next-line ts/no-unsafe-function-type
  action?: Function
}

const product = {
  id: 'financing',
  icon: IconMoney,
  title: 'Financiamento',
  action: () => window.location.href = origin,
} as Product

const partner = computed(() => ({
  partnerId: auth.value!.me.parceiro?.id || '',
  responsible: {
    cellPhone: auth.value!.me.parceiro?.responsavel?.telefone || '',
    noAccountManager: auth.value!.me.parceiro?.responsavel?.semGc || false,
  },
  solfacilPlus: auth.value!.solfacilplus,
}))

async function validateUserAcceptedTerm() {
  const accepted = auth.value!.me.parceiro?.acceptedCurrentOperatingAgreement || true
  const profile = auth.value!.me.perfil

  if (!profile)
    return

  if (accepted && ['gerente', 'integrador_light'].includes(profile))
    return

  if (!accepted && ['gerente', 'integrador_light'].includes(profile)) {
    window.location.href
      = `https://integrador.solfacil.com.br/term?redirect=https://financiamento.solfacil.com.br${route.fullPath}`
  }
}

onMounted(async () => {
  await validateUserAcceptedTerm()
  if (auth.value?.me.perfil === 'admin') {
    impersonate.checkIsImpersonate()
  }
})

const isOpenSidebar = ref(false)

const handleChangeValue = computed(() =>
  (value: string) => {
    partnerName.value = value
  },
)

function selectPartner(partnerId: number, fantasyName: string) {
  partnerSelected.value = { fantasyName, partnerId }
  partnerName.value = fantasyName
}

function handleImpersonate() {
  if (partnerSelected.value) {
    impersonateStore.createImpersonate(partnerSelected.value)
    impersonateStore.toggleImpersonateModal()
  }
  else {
    impersonateStore.deleteImpersonate()
    impersonateStore.toggleImpersonateModal()
    createErrorToast({
      title: t('header.impersonate.error.title'),
      description: t('header.impersonate.error.description'),
    })
  }
}

const allPartners = computed(() => partners?.value)
</script>

<template>
  <main class="default-layout">
    <NavigationSidebar :is-open-sidebar="isOpenSidebar" />
    <div v-if="store.isCollapse" class="main-content">
      <SolHeader
        :user="user"
        :partner="partner"
        :product="product"
        :um-personification="impersonateStore.verifyIsImpersonated()"
        :in-personification="!impersonateStore.verifyIsImpersonated()"
        @click-logout="logout"
        @click-menu="isOpenSidebar = !isOpenSidebar"
        @click-product="menuHeader().openProduct"
        @click-solfacil-plus="menuHeader().goSolfacilPlus"
        @click-user="menuHeader().goMenuUser"
      />

      <RouterView />
    </div>
    <div v-else class="main-content hidden md:system:block">
      <SolHeader
        :user="user"
        :partner="partner"
        :product="product"
        @click-logout="logout"
        @click-menu="isOpenSidebar = !isOpenSidebar"
      />

      <RouterView />
    </div>

    <ImpersonateModal
      :loading="isLoadingPartners || isFetchingPartners"
      :partners="allPartners"
      :partner-id="partnerSelected?.partnerId"
      :partner-name="partnerName"
      @handle-change-value="handleChangeValue"
      @select-partner="selectPartner"
      @handle-impersonate="handleImpersonate"
    />
  </main>
</template>

<style lang="scss">
.main-content {
  background-color: #F0F0F0;
}
.default-engine {
  @apply m-lg;
}
.default-layout {
  @apply relative;
  @apply block max-h-screen;
  @screen md:system {
    @apply flex overflow-hidden;
  }
  > .main-content {
    @apply w-full;
    @apply overflow-auto;
    min-height: calc(100vh);
    > .page {
      @apply flex-1;
    }
  }
}
</style>
