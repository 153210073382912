<script setup lang="ts">
import type { RightClickContextMenuProps } from './RightClickContextMenu.types'
import { defineProps } from 'vue'

const props = defineProps<RightClickContextMenuProps>()
const width = 250

const left = computed(() => {
  const { x } = props
  if (!x)
    return 0

  return (x + width) > window.innerWidth ? (x - width) : x
})
</script>

<template>
  <div class="right-context-menu" :style="{ left: `${left}px`, top: `${props.y}px`, width: `${width}px` }">
    <ul>
      <li v-for="action in props.actions" :key="action.label" class="menu-item" @click="action.action">
        {{ action.label }}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.right-context-menu {
  position: absolute;
  top: var(--y)px;
  left: var(--x)px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: fit-content;
  z-index: 1000;
}
.menu-item {
  list-style: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}
</style>
