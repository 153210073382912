<script setup lang="ts">
import type { Project } from '~/utils/project/Project'
import type { AddonOpportunity, Addons } from '~/utils/simulation/Simulation'
import { useMoney } from '~/composables/money'
import useAuth from '~/store-v2/auth/useAuth'

const props = defineProps<{
  project: Project
  selectedGracePeriod: number
  selectedCommission: number
  disabledFields: boolean
  projectIsFinished: boolean
  loadingConclusion: boolean
  isDetails: boolean
}>()

const { data: auth } = useAuth()

const { t } = useI18n()

const { formatMoney } = useMoney()

const { getSumOfInsurancesApplied, getSelectedInstallment, getValueOfAmpera, getValueOfAmperaMontlhyInterestRate, hasInsuranceApplied, getInsurancePartnerGainSum, getSimulationOpportunities, hasCommissionApplied, getSumOfInsurancesAndCommissions } = useResumeStore()
const { getAddonsList } = useAddonStore()

const blockFields = ref(false)
const loadingLocal = ref(false)

const disabledButton = ref(true)

const userBlack = auth.value?.person.partner?.sfplus?.is_level_black
const difference = ref<number>(0)

const { getAddonsApplieds } = useAddonStore()

const responseSimulationOpportunities: any = ref({})

onMounted(async () => {
  await simulationOpportunities()
  await addSolfacilPoints()
  await itemsNoApplied()
  insertInformationTooltip(getAddonsApplieds())
})

onUpdated(() => {
  const selectedInstallment = getSelectedInstallment()
  if (selectedInstallment)
    difference.value = (selectedInstallment.value - selectedInstallment.down_payment) || 0

  disabledButton.value = !props.disabledFields || blockFields.value || !getSelectedInstallment() || loadingLocal.value
})

watch(() => getSimulationOpportunities(), async () => {
  await simulationOpportunities()
  addSolfacilPoints()
  itemsNoApplied()
})

watch(() => getAddonsApplieds(), async (addonsApplieds) => {
  await simulationOpportunities()
  addSolfacilPoints()
  itemsNoApplied()
  insertInformationTooltip(addonsApplieds)
})

function hasPrestamista() {
  return getAddonsList().some((addon: Addons) => addon.product_slug.toLowerCase() === 'prestamista')
}

const totalPoints = ref(0)
const strengthAddons = ref(0)

async function addSolfacilPoints() {
  totalPoints.value = 0
  strengthAddons.value = 0

  const { addons_opportunities, financing_gained_points } = responseSimulationOpportunities.value || {}

  getAddonsApplieds().forEach((getAddonApplied) => {
    const matchingAddon = addons_opportunities?.find((addon: { addon_type: string, product_slug: string }) => {
      if (addon.addon_type === 'insurance')
        return addon.product_slug === getAddonApplied.product_slug
      return addon.addon_type === getAddonApplied.type
    })
    if (matchingAddon) {
      totalPoints.value += matchingAddon.gained_points

      if (props.project.person_type.toLowerCase() === 'pj')
        strengthAddons.value += 2

      if (props.project.person_type.toLowerCase() === 'pf')
        !hasPrestamista() ? strengthAddons.value += (matchingAddon.addon_type === 'combo') ? 2 : 1 : strengthAddons.value += 1
    }
  })

  totalPoints.value += financing_gained_points || 0
}

const canAddItems = ref([])

function itemsNoApplied() {
  const addonsApplieds: Addons[] = getAddonsApplieds()

  if (responseSimulationOpportunities?.value?.addons_opportunities) {
    const allOpportunities = responseSimulationOpportunities.value.addons_opportunities.map((addon: AddonOpportunity) => {
      if (addon.addon_type === 'insurance')
        return addon.product_slug ? addon.product_slug.toLowerCase() : ''
      return addon.addon_type.toLowerCase()
    })
    const itemsApplieds = addonsApplieds.map((addon: Addons) => (addon.addon_type || addon.product_slug).toLowerCase())

    canAddItems.value = allOpportunities.filter((value: string) => !itemsApplieds.includes(value))
  }
}

function formattedTextTooltip(strengthAddons: number) {
  const itemsList = formatItemsList(canAddItems.value)
  const words = replaceWords(itemsList)
  const personType = props.project.person_type.toLowerCase()

  const phrases = {
    pf: {
      1: `${t('solfacilPoints.phrases.one')} ${words}?`,
      2: `${t('solfacilPoints.phrases.two')} ${words}?`,
      3: `${t('solfacilPoints.phrases.three', { string: words })}`,
      4: `${t('solfacilPoints.phrases.four')}`,
    },
    pj: {
      2: `${t('solfacilPoints.phrases.two')} ${words}?`,
      4: `${t('solfacilPoints.phrases.four')}`,
    },
  }

  if (personType === 'pf' && phrases.pf[strengthAddons as 1 | 2 | 3 | 4])
    return phrases.pf[strengthAddons as 1 | 2 | 3 | 4]

  if (personType === 'pj' && phrases.pj[strengthAddons as 2 | 4])
    return phrases.pj[strengthAddons as 2 | 4]

  return ''
}

const commissionValues = ref('')

function insertInformationTooltip(addonsApplieds: Addons[]) {
  const selectedInstallment = getSelectedInstallment()
  const defaultCommission = props.project.selected_installments?.commission_raw || 0
  const comissionValue = selectedInstallment?.commission_raw || defaultCommission

  commissionValues.value = `Financiamento: ${formatMoney(comissionValue)}\n`

  const insuranceCommissionValues = addonsApplieds.filter((item: Addons) => item.type === 'insurance')

  if (insuranceCommissionValues.length) {
    const formattedText = insuranceCommissionValues.map((el: Addons) => {
      const productSlugFormatted = el.product_slug
        .toLowerCase()
        .replace(/riscos diversos/g, 'Seguro do Equipamento Solar')
        .replace(/prestamista/g, 'Seguro Proteção Financeira')

      return `${productSlugFormatted}: ${formatMoney(el.partner_commission)}\n`
    })

    commissionValues.value = `${commissionValues.value} ${formattedText.join(' ')}`
  }

  commissionValues.value = commissionValues.value.replace(/\n\s+(S)/g, '\n$1')
}

function formatItemsList(items: string[]) {
  if (props.project.person_type.toLowerCase() === 'pj')
    items = items.filter(item => item !== 'prestamista' && item !== 'riscos diversos')

  if (props.project.person_type.toLowerCase() === 'pf' && !hasPrestamista())
    items = items.filter(item => item !== 'prestamista')

  const formattedString = items
    .slice(0, -1)
    .join(', ')
    + (items.length > 1 ? ' e ' : '')
    + items.slice(-1)
  return formattedString
}

function replaceWords(inputString: string) {
  const replacedString = inputString
    .replace(/riscos diversos/g, 'seguro do equipamento solar')
    .replace(/prestamista/g, 'seguro proteção financeira')

  return replacedString
}

async function simulationOpportunities() {
  responseSimulationOpportunities.value = await getSimulationOpportunities()
}

function getGracePeriodMonth(value: number) {
  if (value === 1)
    return 'Mês'

  return 'Meses'
}

function convertAnnualToMonthlyRate(monthlyRate?: number) {
  if (monthlyRate)
    return monthlyRate?.toFixed(2)?.replace('.', ',')

  return '--'
}

function getInterestRateValue(value?: number) {
  if (value)
    return value?.toFixed(2)?.replace('.', ',')

  return '--'
}

function formatComboFacilValue() {
  const value = (getSelectedInstallment()?.difference ?? 0)
  return formatMoney(-value)
}
</script>

<template>
  <div id="resume" class="resume-container !mt-0">
    <h2 class="fonts-body-x-large-bold mb-micro">
      Resumo
    </h2>
    <h3 class="card-subtitle my-micro">
      Parcela
    </h3>
    <div class="resume-card">
      <span v-if="getSelectedInstallment() && props.projectIsFinished" class="fonts-subtitle-small">{{ getSelectedInstallment()?.installments_quantity }}x</span>
      <span v-if="props.projectIsFinished && getSelectedInstallment()?.installments_value">
        {{ formatMoney(
          props.project.complete
            ? getSelectedInstallment()?.installments_value
            : ((getSelectedInstallment()?.installments_value ?? 0) + getSumOfInsurancesApplied()) + getValueOfAmpera(),
        ) ?? "R$ --"
        }}
      </span>

      <span v-if="getSelectedInstallment()?.combo && !props.projectIsFinished" class="discount">
        {{ formatMoney((getSelectedInstallment()?.installments_value ?? 0) + (getSelectedInstallment()?.difference ?? 0)) }}
      </span>
      <span v-if="getSelectedInstallment()?.combo && !props.projectIsFinished" class="resume-value">
        <span v-if="getSelectedInstallment()" class="fonts-subtitle-small">
          {{ getSelectedInstallment() && getSelectedInstallment()?.installments_quantity }}x
        </span>
        {{ formatMoney(((getSelectedInstallment()?.installments_value ?? 0) + getSumOfInsurancesApplied()) + getValueOfAmpera()) ?? "R$ --" }}
      </span>

      <span v-else-if="!props.projectIsFinished && getSelectedInstallment()?.installments_value" class="resume-value">
        <span v-if="getSelectedInstallment()" class="fonts-subtitle-small">
          {{ getSelectedInstallment()?.installments_quantity }}x
        </span>
        {{ formatMoney(((getSelectedInstallment()?.installments_value ?? 0) + getSumOfInsurancesApplied()) + getValueOfAmpera()) ?? "R$ --" }}
      </span>
    </div>
    <div class="container-details">
      <ul class="flex flex-col">
        <li v-if="getSelectedInstallment()?.combo && !props.projectIsFinished" class="details">
          <span class="text-brand-primary-medium">Combo Fácil</span>
          <span class="text-brand-secondary-pure">{{ formatComboFacilValue() }}</span>
        </li>
        <li v-if="getValueOfAmpera()" class="details">
          <span class="text-brand-primary-medium">Ampera</span>
          <span class="text-brand-secondary-pure">{{ formatMoney(getValueOfAmpera()) }}</span>
        </li>
        <li v-if="hasInsuranceApplied()" class="details">
          <span class="text-brand-primary-medium">Seguro</span>
          <span class="text-brand-primary-medium">+{{ formatMoney(getSumOfInsurancesApplied()) }}</span>
        </li>
      </ul>
    </div>

    <div class="resume-info">
      <h3 class="card-subtitle mt-4xs">
        Financiamento
      </h3>
      <div class="container-details">
        <ul class="flex flex-col">
          <li class="details">
            <span class="text-brand-primary-medium">Taxa de juros<span v-if="userBlack"> Black</span></span>
            <span class="text-brand-primary-pure">
              {{ convertAnnualToMonthlyRate(getValueOfAmperaMontlhyInterestRate() ? getValueOfAmperaMontlhyInterestRate() : getSelectedInstallment()?.monthly_interest_rate) }} % a.m
            </span>
          </li>
          <li class="details">
            <span class="text-brand-primary-medium">CET<span v-if="userBlack"> Black</span></span>
            <span class="text-brand-primary-pure">{{ getInterestRateValue(getSelectedInstallment()?.cet) }} % a.m</span>
          </li>
          <li class="details flex flex-col">
            <div class="flex justify-between py-quark ">
              <span class="text-brand-primary-medium">Valor total do projeto</span>
              <span class="text-brand-primary-pure">{{ formatMoney(getSelectedInstallment()?.value) }}</span>
            </div>
            <div class="flex justify-between py-quark pl-micro">
              <span class="text-brand-primary-medium">Entrada</span>
              <span class="text-brand-primary-pure">{{ formatMoney(getSelectedInstallment()?.down_payment) }}</span>
            </div>
            <div class="flex justify-between py-quark pl-micro">
              <span class="text-brand-primary-medium">Valor Financiado</span>
              <span class="text-brand-primary-pure">{{ formatMoney(difference) }}</span>
            </div>
          </li>
          <li class="details">
            <span class="text-brand-primary-medium">Carência</span>
            <span class="text-brand-primary-pure">{{ props.selectedGracePeriod }} {{ getGracePeriodMonth(props.selectedGracePeriod) }}</span>
          </li>
        </ul>
      </div>

      <div>
        <h3
          v-if="hasInsuranceApplied() || hasCommissionApplied() || responseSimulationOpportunities?.financing_gained_points"
          class="card-subtitle mt-4xs flex justify-between"
        >
          Seus ganhos
          <SimulationTooltip
            v-if="!(props.project.complete && isDetails) && (hasInsuranceApplied() || responseSimulationOpportunities?.financing_gained_points)"
            position="left"
            :text="props.project.complete && isDetails ? '' : formattedTextTooltip(strengthAddons)"
          >
            <SimulationSignalBar :signal="{ strength: strengthAddons }" />
          </SimulationTooltip>
        </h3>
        <div class="container-details">
          <ul class="flex flex-col">
            <li v-if="responseSimulationOpportunities?.financing_gained_points" class="details">
              <span class="text-brand-primary-medium">{{ $t('solfacilPoints.label') }}</span>
              <span class="text-brand-primary-pure">
                {{ totalPoints.toLocaleString('pt-BR') }}
              </span>
            </li>
            <li v-if="hasCommissionApplied()" class="details">
              <span class="text-brand-primary-medium">Comissões</span>

              <SimulationTooltip
                position="left"
                :text="commissionValues"
              >
                <span class="text-brand-primary-pure">
                  + {{
                    formatMoney(getSumOfInsurancesAndCommissions())
                  }}
                </span>
              </SimulationTooltip>
            </li>

            <li v-if="!hasCommissionApplied() && hasInsuranceApplied()" class="details">
              <span class="text-brand-primary-medium">Comissão do Seguro</span>
              <span class="text-brand-primary-pure">
                + {{
                  formatMoney(getInsurancePartnerGainSum())
                }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.container-details {
  @apply w-full flex flex-col pt-nano;
  .details {
    @apply flex justify-between py-quark text-3xs;

    &:not(:last-of-type) {
      @apply border-b-neutral-high-medium border-b-1
    }
  }
}

.discount {
  @apply text-brand-primary-medium fonts-subtitle-small;
  text-decoration: line-through;
}

.card-subtitle {
  @apply fonts-body-large-bold;
}

.resume-container {
  @apply py-2xs px-xs bg-neutral-high-pure rounded-lg mb-lg mt-2xs;
  @apply sticky top-4 h-max;

  .resume-card {
    @apply bg-neutral-high-light rounded-md p-4xs align-middle text-center;
    @apply flex flex-col;
    .resume-value {
      font-family: "Lato",sans-serif;
      line-height: 1.15;
      font-weight: 700;
      font-size: 1.5rem;
    }
    .card-title {
      @apply fonts-heading-h4 mb-2xs;
    }
    .card-content {
      @apply grid-cols-1 grid-rows-2 grid gap-1;
    }
  }
}
</style>
