import type { FinancingListItem } from '~/utils/financing-flow/Financing'
import { defineStore } from 'pinia'
import FinancingFlowService from '~/services/financingFlow/FinancingFlow'

const useFinancingState = defineStore('financing', () => {
  const isLoading = ref(false)
  const financing = ref<FinancingListItem | null>(null)

  const setLoading = (value: boolean) => {
    isLoading.value = value
  }

  const setFinancing = (value: FinancingListItem) => {
    financing.value = value
  }

  return {
    isLoading,
    financing,
    setLoading,
    setFinancing,
  }
})

export function useFinancing(projectId: string) {
  const { isLoading, financing, setLoading, setFinancing } = useFinancingState()

  const execute = async () => {
    if (!projectId)
      throw new Error('Financing project ID is required')

    if (isLoading || financing?.project_id === projectId) {
      return {
        isLoading,
        financing,
      }
    }

    try {
      setLoading(true)

      const financingService = new FinancingFlowService(useApi('financingFlow'))
      const response = await financingService.getFinancingByProjectId(projectId)

      setFinancing(response)
    }
    catch (error) {
      console.error(error)
    }
    finally {
      setLoading(false)
    }
  }

  execute()

  return {
    isLoading,
    financing,
  }
}
