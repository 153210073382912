/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Order: "enum" as const,
	PersonType: "enum" as const,
	FinancingStatus: "enum" as const,
	SectionStatus: "enum" as const,
	FinancingSteps: "enum" as const,
	FinancingsInput:{
		steps:"FinancingSteps",
		status:"FinancingStatus"
	},
	Query:{
		financing:{

		},
		financings:{
			input:"FinancingsInput"
		},
		partners:{
			input:"CoreGetPartnersInput"
		}
	},
	CoreGetPartnersInput:{

	}
}

export const ReturnTypes: Record<string,any> = {
	FormalizationStatus:{
		contract:"SectionStatus",
		hardware:"SectionStatus",
		registration:"SectionStatus",
		documentation:"SectionStatus",
		receipt_model:"SectionStatus"
	},
	SectionStatuses:{
		concluded:"SectionStatus",
		simulation:"SectionStatus",
		installation:"SectionStatus",
		formalization:"FormalizationStatus"
	},
	Payment:{
		id:"Int",
		financing_id:"Float",
		value:"Float",
		total_value:"Float",
		payment_percentage:"Float",
		created_at:"String"
	},
	Financing:{
		id:"Int",
		project_id:"String",
		financed_value:"Float",
		customer_name:"String",
		document:"String",
		expires_at:"String",
		created_at:"String",
		formalized_at:"String",
		installed_at:"String",
		status:"FinancingStatus",
		step:"String",
		is_combo:"Boolean",
		payments:"Payment",
		flow_provider:"String",
		person_type:"PersonType",
		all_payments_sent:"Boolean",
		section_statuses:"SectionStatuses",
		updated_at:"String",
		is_expired:"Boolean",
		system_power:"Float"
	},
	FinancingListTotalByStep:{
		simulation:"Int",
		formalization:"Int",
		installation:"Int",
		concluded:"Int",
		expired:"Int"
	},
	FinancingListTotalByStatus:{
		pending:"Int",
		expired:"Int",
		reproved:"Int",
		approved:"Int",
		under_analysis:"Int",
		pendency:"Int",
		send_contract:"Int",
		pending_customer:"Int",
		pending_receipt:"Int",
		concluded:"Int",
		canceled:"Int",
		kit_pending:"Int"
	},
	FinancingsList:{
		data:"Financing",
		total:"Int",
		total_by_step:"FinancingListTotalByStep",
		total_by_status:"FinancingListTotalByStatus"
	},
	Query:{
		financing:"Financing",
		financings:"FinancingsList",
		partners:"CorePartner",
		me:"User"
	},
	CorePartner:{
		id:"Int",
		cnpj:"String",
		responsible:"CoreParterResponsible",
		company_name:"String",
		fantasy_name:"String",
		status:"CoreParterStatus"
	},
	CoreParterResponsible:{
		id:"Int",
		full_name:"String"
	},
	CoreParterStatus:{
		active:"Boolean",
		reason:"String",
		date:"String"
	},
	LevelBlackStatus:{
		kwGoalMonth:"Int",
		kwReachedMonth:"Int"
	},
	Contact:{
		landline:"String",
		mobile:"String",
		email:"String"
	},
	Address:{
		street:"String",
		number:"String",
		complement:"String",
		reference:"String",
		district:"String",
		zipcode:"String",
		city:"String",
		state:"String"
	},
	Responsible:{
		id:"Int",
		username:"String",
		profile:"String",
		fullname:"String",
		document:"String",
		birthdate:"String",
		active:"Boolean",
		created_at:"String",
		contact:"Contact",
		partner:"Partner"
	},
	Permission:{
		store:"Boolean",
		financing:"Boolean"
	},
	Score:{
		current:"Int",
		goal:"Int"
	},
	Downgrade:{
		has_risk:"Boolean"
	},
	Sfplus:{
		level:"Int",
		score:"Score",
		black_status:"LevelBlackStatus",
		downgrade:"Downgrade",
		is_level_black:"Boolean",
		next_transition_date:"String"
	},
	Partner:{
		id:"Int",
		corporate_name:"String",
		operation_term_accepted:"Boolean",
		trade_name:"String",
		document:"String",
		category:"String",
		state_registration:"String",
		active:"Boolean",
		created_at:"String",
		address:"Address",
		responsible:"Responsible",
		permission:"Permission",
		users:"User",
		sfplus:"Sfplus"
	},
	Post:{
		id:"Int",
		title:"String",
		body:"String",
		userId:"String",
		comments:"Comment"
	},
	Comment:{
		id:"Int",
		name:"String",
		email:"String",
		body:"String"
	},
	User:{
		id:"Int",
		username:"String",
		profile:"String",
		fullname:"String",
		document:"String",
		birthdate:"String",
		active:"Boolean",
		created_at:"String",
		contact:"Contact",
		partner:"Partner",
		posts:"Post"
	}
}

export const Ops = {
query: "Query" as const
}