import type { BankStatementParams } from '~/services-v2/payment-bff/payment-bff.types'
import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { getBankStatement } from '~/services-v2/payment-bff/payment-bff'

export interface UseBankStatementParams extends BankStatementParams {}

export default function useBankStatement(params: Ref<UseBankStatementParams>) {
  const queryKey = ref(queryKeys.BANK_STATEMENT.LIST(params.value))

  const queryFn = async () => {
    const res = await getBankStatement(params.value)

    return res
  }

  watch(params, () => {
    queryKey.value = queryKeys.BANK_STATEMENT.LIST(params.value)
  })

  return useQuery({
    queryFn,
    queryKey,
    retry: 0,
  })
}
