<script setup lang="ts">
import { useToast } from '@solfacil/girassol'
import dayjs from 'dayjs'
import IconPlus from '~icons/girassol/add'
import IconSearch from '~icons/girassol/search'
import { AuthorizationError } from '~/services-v2/payment-bff/payment-bff.errors'
import useAuth from '~/store-v2/auth/useAuth'
import useBankStatement from '~/store-v2/bank-statement/useBankStatement'

const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const toast = useToast()
const router = useRouter()
const { track } = useMixpanel()

const { data: auth } = useAuth()
const sortByOptions = [
  { value: 'RECENT', name: 'Mais recentes' },
  { value: 'OLDEST', name: 'Mais antigas' },
]
const impersonate = useImpersonate()
const impersonateStore = useImpersonateStore()

const defaultRangeDate: [Date, Date] = [dayjs().subtract(90, 'day').toDate(), new Date()]

const search = ref<string>()
const sortBy = ref(sortByOptions[0])
const rangeDate = ref<[Date, Date] | []>(defaultRangeDate)
const datePickerError = ref<string>()
const tabs = ref([
  { key: 'in-progress', label: 'Em progresso', active: true },
  { key: 'completed', label: 'Concluído', active: false },
])

const fetchParams = ref(getFetchParams())

const { data: bankStatement, isLoading: loading, error } = useBankStatement(fetchParams)

const hasPermission = computed(() => {
  if (error.value instanceof AuthorizationError && error.value?.code === 100) {
    return false
  }

  return true
})

const handleNewSimulationClick = () => router.push('/')

function getFetchParams() {
  const currentTab = tabs.value.find(tab => tab.active)!.key
  const dateRange = rangeDate.value.map((date: Date) => date.toISOString().split('T')[0]).join(',')

  return {
    customer_document: search.value,
    sort: sortBy.value.value as 'RECENT' | 'OLDEST',
    status:
      currentTab === 'in-progress'
        ? 'IN_PROGRESS'
        : ('COMPLETED' as 'IN_PROGRESS' | 'COMPLETED'),
    date_range: currentTab === 'in-progress' ? undefined : dateRange,
    partner_id: auth.value?.me.perfil === 'admin' && impersonate.checkIsImpersonate() ? impersonateStore.partnerId : undefined,
  }
}

function dateRangeValidation() {
  const diffInDays = dayjs(rangeDate.value[1]).diff(dayjs(rangeDate.value[0]), 'day')

  if (diffInDays > 90) {
    datePickerError.value = 'O período deve ser de no máximo 90 dias'

    return false
  }

  datePickerError.value = ''

  return true
}

function handleFormSubmit() {
  track('solfacil-statement_button_buscar')

  if (!dateRangeValidation())
    return

  fetchParams.value = getFetchParams()
}

function handleOnTabClick() {
  const currentTab = tabs.value.find(tab => tab.active)

  fetchParams.value = getFetchParams()

  track('solfacil-statement_tab', { tab: currentTab?.key })
}

watch(tabs, () => {
  rangeDate.value = defaultRangeDate
  search.value = ''
})

watch(() => sortBy.value, () => {
  fetchParams.value = getFetchParams()
})

watch(() => error.value, (error) => {
  if (error !== null && !bankStatement) {
    toast.createErrorToast({
      title: 'Erro ao buscar extrato bancário',
      description: 'Não foi possível buscar o extrato. Tente novamente mais tarde.',
    })
  }
})

onMounted(async () => {
  track('solfacil-statement_page-view')
})
</script>

<template>
  <div class="container">
    <h2 class="fonts-heading-h2">
      Extrato da Conta Solfácil
    </h2>

    <div class="section-heading-wrapper">
      <p class="fonts-body-large-regular text-neutral-low-light">
        Confira as entradas e saídas da sua conta vinculada com a Solfácil em
        parceria com a QI Tech. As entradas são as transferências para a sua
        conta vinculada (valor financiado líquido e comissões), e as saídas são
        as transferências da sua conta vinculada para a sua conta pessoal e/ou
        para o fornecedor.
      </p>

      <SolButton id="new-simulation" size="medium" class="whitespace-nowrap" @click="handleNewSimulationClick">
        <template #icon-left>
          <IconPlus />
        </template>

        Nova simulação
      </SolButton>
    </div>

    <form v-if="hasPermission" class="filter-wrapper" @submit.prevent="handleFormSubmit">
      <SolInputText
        id="customer-search" v-model="search" name="customer-search" class="customer-search-input"
        placeholder="Busque por Nome, CPF ou CNPJ"
      >
        <template v-if="!isLargeScreen" #icon>
          <IconSearch />
        </template>
      </SolInputText>

      <SolDatePicker
        v-if="tabs.find(tab => tab.active)!.key === 'completed'" id="range-date-picker" v-model="rangeDate"
        :class="isLargeScreen ? 'w-68' : 'w-full'" name="range-date-picker" placeholder="Selecione o período" :range="true" label="" :error="datePickerError"
      />

      <SolButton id="customer-search-button" type="submit" variant="primary" size="medium">
        Buscar
      </SolButton>
    </form>

    <SharedViewPartnerAlert />

    <section v-if="hasPermission" class="w-full">
      <div class="bank-statement-heading">
        <h3 class="fonts-heading-h3 mb-2xs">
          Últimas transferências
        </h3>

        <div class="flex gap-2 items-baseline justify-end mb-2xs">
          <label for="sort-by" class="text-neutral-low-dark">
            Ordenar por:
          </label>
          <SolSelect id="sort-by" v-model:selected="sortBy" name="sort-by" :options="sortByOptions" size="small" />
        </div>
      </div>

      <SolTabs id="bank-statement-tabs" v-model:tab-list="tabs" @click.stop="handleOnTabClick">
        <template #tab:in-progress>
          <BankStatementTab v-if="bankStatement" :bank-statement="bankStatement" />
          <div class="flex justify-center">
            <Loading v-if="loading" size="small" />
            <SolEmptyState
              v-else-if="!loading && (!bankStatement || bankStatement?.results.length === 0)"
              id="not-found-in-progress" variant="empty-list" title="Nenhuma transferência realizada"
              subtitle="Ainda não há nenhuma transferência registrada neste extrato. Faça sua primeira formalização para ver o registro das transações."
            />
          </div>
        </template>

        <template #tab:completed>
          <BankStatementTab v-if="bankStatement" :bank-statement="bankStatement" />
          <div class="flex justify-center">
            <Loading v-if="loading" size="small" />
            <SolEmptyState
              v-else-if="!loading && (!bankStatement || bankStatement?.results.length === 0)"
              id="not-found-completed" variant="empty-list" title="Nenhuma transferência realizada"
              subtitle="Ainda não há nenhuma transferência registrada neste extrato. Faça sua primeira formalização para ver o registro das transações."
            />
          </div>
        </template>
      </SolTabs>
    </section>
    <div v-else-if="!hasPermission && loading" class="flex items-center justify-center w-full">
      <Loading size="small" />
    </div>
    <div v-else class="flex items-center justify-center w-full">
      <SolEmptyState
        id="no-permission" variant="no-content" title="Você não tem acesso ao extrato"
        subtitle="Apenas usuários como perfil gerencial tem acesso ao movimento bancário. Solicite ao administrador da sua conta o seu acesso."
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  --header-height: 65px;
  min-height: calc(100vh - var(--header-height));

  @apply py-2xs px-4xs mt-0;

  @apply bg-neutral-high-pure;

  @screen md:system {
    --header-height: 97px;
    min-height: calc(100vh - var(--header-height));

    @apply py-2xs px-12;

    .bank-statement-heading {
      @apply flex justify-between;
    }
  }

  .section-heading-wrapper {
    @apply mb-2xs;

    p {
      @apply mb-4xs;
    }

    @screen md:system {
      @apply flex gap-5;
    }
  }

  form.filter-wrapper {
    @apply mb-3xs w-full;
    @apply py-2xs px-4xs;

    @apply flex flex-col gap-2;

    @apply bg-neutral-high-light;
    @apply rounded-lg;

    @screen md:system {
      @apply flex-row;
    }

    .customer-search-input {
      @apply w-full;

      @screen md:system {
        @apply w-80;
      }
    }
  }
}
</style>

<route lang="yaml">
meta:
  layout: simulation
</route>
