export function useImpersonate() {
  const impersonateStore = useImpersonateStore()

  const removeImpersonate = () => {
    impersonateStore.deleteImpersonate()
  }

  const checkIsImpersonate = () => {
    const isImpersonated = impersonateStore.verifyIsImpersonated()

    if (isImpersonated)
      return isImpersonated

    const partnerToImpersonate = impersonateStore.isImpersonated() as { partnerId: number, fantasyName: string }

    if (partnerToImpersonate && partnerToImpersonate.partnerId && partnerToImpersonate.fantasyName) {
      impersonateStore.createImpersonate(partnerToImpersonate)

      return true
    }

    return false
  }

  return {
    checkIsImpersonate,
    removeImpersonate,
  }
}
