import type { AddressFormRequest, ClientDataOptions, CompanyFormModel, Document, DocumentModel, FinancingFormModel, ResponseUpdateCompany, UpdateFormCompany } from '~/utils/company-register/CompanyRegister'
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import BaseService from '../BaseService'

export default class CompanyRegisterService extends BaseService {
  async get_company_registration_form(id: string, projectCompanyType: string): Promise<ResponseModel<FinancingFormModel>> {
    try {
      const response = await super.api<ResponseModel<FinancingFormModel>>(`/registration/form/${id}/${projectCompanyType.toLowerCase()}`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async upload_documents(projectId: string, body: FormData): Promise<ResponseModel<Document>> {
    try {
      const response = await super.api<ResponseModel<Document>>(`/document_form/${projectId}/upload/pj`, {
        method: 'POST',
        body,
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async confirm_documents(projectId: string): Promise<ResponseModel<Document>> {
    try {
      const response = await super.api<ResponseModel<Document>>(`/document_form/${projectId}/confirm_documentation/pj`, {
        method: 'PUT',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async update_form_partial(body: UpdateFormCompany): Promise<ResponseUpdateCompany> {
    try {
      const response = await super.api<ResponseUpdateCompany>('/registration/form/company', {
        method: 'PUT',
        body: {
          ...body,
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async registration_confirm(body: UpdateFormCompany): Promise<any> {
    try {
      const response = await super.api<ResponseModel<null>>('/registration/form/confirm/company', {
        method: 'POST',
        body: {
          ...body,
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_company_register_client_data_options(): Promise<ResponseModel<ClientDataOptions>> {
    try {
      const response = await super.api<ResponseModel<ClientDataOptions>>('/registration/combos?nationality=true&gender=true&occupation=true', {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async confirm_person_form(project_id: string, person_form: CompanyFormModel): Promise<ResponseModel<null>> {
    try {
      const response = await super.api<ResponseModel<null>>('/registration/person/confirm', {
        method: 'POST',
        body: {
          project_id,
          person_form: { ...person_form },
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async confirm_address_form(project_id: string, plant_address_form: AddressFormRequest, residential_address_form: AddressFormRequest, residential_is_the_same_of_plant: boolean): Promise<ResponseModel<null>> {
    try {
      const residential_based_on_plant = residential_is_the_same_of_plant ? residential_address_form : plant_address_form
      const response = await super.api<ResponseModel<null>>('/registration/address/confirm', {
        method: 'POST',
        body: {
          project_id,
          residential_is_the_same_of_plant,
          plant_address_form: residential_based_on_plant,
          residential_address_form,
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_documents_company(projectId: string): Promise<DocumentModel> {
    try {
      const response = await super.api<DocumentModel>(`/document_form/${projectId}/pj`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async delete_document(documentId: string): Promise<any> {
    try {
      const response = await super.api<DocumentModel>(`/document_form/documents/${documentId}`, {
        method: 'DELETE',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }
}
