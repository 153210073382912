<script lang="ts" setup>
import { useToast } from '@solfacil/girassol'
import IconDownload from '~icons/girassol/download'
import * as paymentBFF from '~/services-v2/payment-bff/payment-bff'

const props = defineProps<{
  transactionId: string | null
}>()

const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const { track } = useMixpanel()
const toast = useToast()

const loading = ref(false)

async function handleOnDownloadStatementClick() {
  loading.value = true

  try {
    track('solfacil-statement_button_download')

    if (!props.transactionId) {
      throw new Error('Transaction ID is required')
    }

    const fileBlob = await paymentBFF.downloadBankStatement(props.transactionId)

    const url = window.URL.createObjectURL(fileBlob)
    const a = document.createElement('a')
    a.href = url
    a.download = `extrato-solfacil-${props.transactionId}.pdf`

    a.click()

    window.URL.revokeObjectURL(url)
  }
  catch (error) {
    console.error(error)
    toast.createErrorToast({
      title: 'Erro ao baixar comprovante',
      description: 'Não foi possível baixar o comprovante. Tente novamente mais tarde.',
    })
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <SolButton
    :id="`transaction-id-${props.transactionId}`"
    variant="secondary"
    size="small"
    :class="{
      'w-8': !isLargeScreen,
    }"
    :loading="loading"
    :disabled="props.transactionId === null"
    @click.stop="handleOnDownloadStatementClick"
  >
    <template #icon:left>
      <IconDownload />
    </template>

    {{ isLargeScreen ? 'Baixar comprovante' : '' }}
  </SolButton>
</template>
