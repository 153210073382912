import type { SimulatorService } from './services/simulator-v2/simulator.types'
import type { UseBankStatementParams } from './store-v2/bank-statement/useBankStatement'
import type { UseFinancingsParams } from './store-v2/financings/useFinancings'
import type { UsePartnersParams } from './store-v2/partners/usePartners'
import { QueryClient } from '@tanstack/vue-query'
import { filterAndFormatParams } from './utils/objects'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: 2,
    },
  },
})

export const queryKeys = {
  AUTH: {
    LOGIN: ['AUTH', 'LOGIN'],
    LOGOUT: ['AUTH', 'LOGOUT'],
    STATUS: ['AUTH', 'STATUS'],
  },
  SF_PLUS: {
    STATUS: ['SF_PLUS', 'STATUS'],
  },
  FINANCINGS: {
    DETAILS: (id: Ref<string>) => ['FINANCINGS', 'DETAILS', id.value],
    LIST: (params: UseFinancingsParams) => ['FINANCINGS', 'LIST', ...filterAndFormatParams(params)],
  },
  PARTNERS: {
    LIST: (params: UsePartnersParams) => ['PARTNERS', 'LIST', ...filterAndFormatParams(params)],
  },
  BANK_STATEMENT: {
    LIST: (params: UseBankStatementParams) => ['BANK_STATEMENT', 'LIST', ...filterAndFormatParams(params)],
  },

  // projects
  PROJECTS: (params: SimulatorService.ProjectsRequest) => ['PROJECTS', ...filterAndFormatParams(params)],
  PROJECT: (id: string) => ['PROJECT', id],

  BNPL_SUMMARY: (id: string) => ['BNPL_SUMMARY', id],
  BNPL_INSTALLMENTS_MUTATION: (projectID: string) => ['BNPL_INSTALLMENTS', projectID],
  BNPL_INSTALLMENT: (id: string) => ['BNPL_INSTALLMENT', id],
}

export default queryClient
