import type { Project } from '~/utils/project/Project'
import type { AddonOpportunity, Addons, ParsedInstallment, SimulationOpportunities } from '~/utils/simulation/Simulation'
import { defineStore } from 'pinia'
import { useAddonStore } from './addons'

interface SelectElement {
  name: string
  value: string | number
}

export const useResumeStore = defineStore('resume', () => {
  const sumOfInsurancesApplied = ref<number>(0)
  const insurancePartnerGainsSum = ref<number>(0)
  const amperaValue = ref<number>(0)
  const disabledButton = ref(true)
  const amperaMonthlyInterestRate = ref<number | undefined>(undefined)
  const installment = ref<ParsedInstallment | undefined>(undefined)
  const installmentSelectedByUserAction = ref<ParsedInstallment | undefined>(undefined)

  const pointsCombo = ref<AddonOpportunity>()
  const simulationOpportunities = ref<SimulationOpportunities>()

  const commission = ref<SelectElement | null>(null)
  const gracePeriod = ref<SelectElement | null>(null)
  const project = ref<Project | null>(null)

  const { getAddonsApplieds } = useAddonStore()

  function setInstallment(installmentSelected: ParsedInstallment) {
    installment.value = installmentSelected
  }

  onMounted(() => {
    if (getAddonsApplieds()?.length) {
      sumInsuranceValue(getAddonsApplieds())
      sumInsurancePartnerGainsValue(getAddonsApplieds())
      getValueAmpera()
    }
  })

  onUpdated(() => {
    disabledButton.value = false
  })

  function sumInsuranceValue(value: Addons[]) {
    if (value) {
      sumOfInsurancesApplied.value
      = getFieldValueOfAddon('Riscos Diversos', 'installment_price')
      + getFieldValueOfAddon('Prestamista', 'installment_price')
    }
  }

  function sumInsurancePartnerGainsValue(value: Addons[]) {
    if (value) {
      insurancePartnerGainsSum.value
      = getFieldValueOfAddon('Riscos Diversos', 'partner_commission')
      + getFieldValueOfAddon('Prestamista', 'partner_commission')
    }
  }

  watch(() => getAddonsApplieds(), (value: Addons[]) => {
    sumInsuranceValue(value)
  })

  watch(() => getAddonsApplieds(), (value: Addons[]) => {
    sumInsurancePartnerGainsValue(value)
  })

  watch(() => getAddonsApplieds(), () => {
    getValueAmpera()
  })

  function hasInsuranceApplied() {
    return !!getAddonsApplieds().find(value => value.type === 'insurance')
  }

  function hasCommissionApplied() {
    const selectedInstallmentComission = project.value?.selected_installments?.commission_raw
    const suggestedInstallmentComission = installment.value?.commission_raw

    if (suggestedInstallmentComission !== undefined)
      return suggestedInstallmentComission !== 0

    else
      return selectedInstallmentComission !== 0
  }

  function getFieldValueOfAddon(addon: string, field: keyof Addons) {
    const data = getAddonsApplieds().find(value => value.product_slug === addon)
    if (data)
      return Number(data[field])

    return 0
  }

  function setSelectedInstallment(value: ParsedInstallment, is_user_action?: boolean) {
    if (is_user_action)
      installmentSelectedByUserAction.value = value

    return installment.value = value
  }

  function setProject(value: Project) {
    return project.value = value
  }

  function setGracePeriod(value: SelectElement) {
    return gracePeriod.value = value
  }

  function setCommission(value: SelectElement) {
    return commission.value = value
  }

  function setSimulationOpportunities(value: SimulationOpportunities) {
    return simulationOpportunities.value = value
  }

  function setPoints(value: AddonOpportunity) {
    return pointsCombo.value = value
  }

  /* getters */

  function getSimulationOpportunities() {
    return simulationOpportunities.value
  }

  function getPoints() {
    return simulationOpportunities.value
  }

  function getInstallmentSelectedByUserAction() {
    return installmentSelectedByUserAction.value
  }

  function getCommissionApplied() {
    return commission.value?.value
  }

  function getGracePeriodApplied() {
    return gracePeriod.value?.value
  }

  function getCurrentProject(): Project | null {
    if (project.value)
      return project.value

    return null
  }

  function getValueOfAmpera() {
    return amperaValue.value
  }

  function getSelectedInstallment() {
    if (installment.value)
      return installment.value

    return null
  }

  function getSumOfInsurancesApplied() {
    return sumOfInsurancesApplied.value
  }

  function getValueOfAmperaMontlhyInterestRate() {
    return amperaMonthlyInterestRate.value
  }

  function getInsurancePartnerGainSum() {
    return insurancePartnerGainsSum.value
  }

  function getValueAmpera() {
    const amperaAddon = getAddonsApplieds().find(value => value.product_slug === 'Ampera')

    if (amperaAddon) {
      amperaValue.value = amperaAddon.installment_price

      if (amperaAddon.monthly_interest_rate)
        amperaMonthlyInterestRate.value = amperaAddon.monthly_interest_rate

      return
    }

    amperaMonthlyInterestRate.value = installment?.value?.monthly_interest_rate
    amperaValue.value = 0
  }

  function getSumOfInsurancesAndCommissions() {
    const selectedInstallmentComission = project.value?.selected_installments?.commission_raw
    const suggestedInstallmentComission = installment.value?.commission_raw
    if (suggestedInstallmentComission !== undefined)
      return suggestedInstallmentComission ? suggestedInstallmentComission + insurancePartnerGainsSum.value : 0
    else
      return selectedInstallmentComission ? selectedInstallmentComission + insurancePartnerGainsSum.value : 0
  }

  return {
    getGracePeriodApplied,
    getSumOfInsurancesApplied,
    getSelectedInstallment,
    getValueOfAmpera,
    getAddonsApplieds,
    getPoints,
    getInstallmentSelectedByUserAction,
    getSimulationOpportunities,
    getValueOfAmperaMontlhyInterestRate,
    getCurrentProject,
    getCommissionApplied,
    getInsurancePartnerGainSum,
    setSimulationOpportunities,
    setPoints,
    setSelectedInstallment,
    setProject,
    setInstallment,
    setGracePeriod,
    setCommission,
    hasInsuranceApplied,
    hasCommissionApplied,
    getSumOfInsurancesAndCommissions,
  }
})
