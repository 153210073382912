import type { BankStatementParams, BankStatementResponse } from './payment-bff.types'
import http from './payment-bff.http'

export async function getBankStatement(params: BankStatementParams): Promise<BankStatementResponse> {
  const sanitizedDocument = params.customer_document?.replace(/[^a-z0-9\s]/gi, '') || ''
  const isCpf = sanitizedDocument.length === 11
  const isCnpj = sanitizedDocument.length === 14
  const isDocument = isCpf || isCnpj

  const _params = {
    ...params,
    customer_document: isDocument ? sanitizedDocument : undefined,
  }

  const response = await http.get<BankStatementResponse>('/v1/transfer/bank-statement', {
    params: _params,
  })

  if (!isDocument && sanitizedDocument) {
    return {
      ...response.data,
      results: response.data.results.filter(result => result.customer_name?.toLocaleLowerCase().includes(sanitizedDocument.toLocaleLowerCase())),
    }
  }

  return response.data
}

export async function downloadBankStatement(id: string): Promise<Blob> {
  const response = await http.get(`/v1/transfer/${id}/transfer-receipt`, {
    responseType: 'blob',
  })

  return response.data
}
