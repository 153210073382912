<script lang="ts" setup>
import type { Data } from '@solfacil/girassol/dist/types/components/list/types'
import type { Type } from '@solfacil/girassol/dist/types/components/tag/types'
import type { RightClickContextMenuProps } from './RightClickMenu/RightClickContextMenu.types'

import type { FinancingsList, UseFinancingsParams } from '~/store-v2/financings/useFinancings'
import type { User } from '~/utils/auth/User'
import type { FinancingListItem, OptionsFilters, SelectedFilters } from '~/utils/financing-flow/Financing'
import { SolList, useToast } from '@solfacil/girassol'
import { match, P } from 'ts-pattern'

import IconPlus from '~icons/material-symbols/add'
import IconCheck from '~icons/material-symbols/check-circle-outline'
import IconOpenInNew from '~icons/material-symbols/open-in-new'

import IconSchedule from '~icons/material-symbols/schedule-outline'
import IconWarning from '~icons/material-symbols/warning-outline'
import Loading from '~/components/loading/index.vue'
import { FinancingSteps } from '~/store-v2/financings/useFinancings'
import currencyUtils from '~/utils/currency'
import { conventionalNameFormat } from '~/utils/formatter/name'
import RightClickContextMenu from './RightClickMenu/RightClickContextMenu.vue'

const props = defineProps<{
  loading: boolean
  list: FinancingsList
  params: UseFinancingsParams
}>()

const emit = defineEmits<{
  (e: 'params', v: UseFinancingsParams)
}>()

const config = import.meta.env
const router = useRouter()
const { t } = useI18n()
const { track } = useMixpanel()
const toast = useToast()
const user: User = useAppStorage().get('user')
const optionsFiltersList = ref({} as OptionsFilters)
const isLargeScreen = useMediaQuery('(min-width: 1024px)')

const FilterStatus = {
  approved: t('financing.list.status.approved'),
  canceled: t('financing.list.status.canceled'),
  concluded: t('financing.list.status.concluded'),
  expired: t('financing.list.status.expired'),
  kit_pending: t('financing.list.status.kit_pending'),
  pendency: t('financing.list.status.pendency'),
  pending: t('financing.list.status.pending'),
  pending_customer: t('financing.list.status.pending_customer'),
  pending_receipt: t('financing.list.status.pending_receipt'),
  reproved: t('financing.list.status.reproved'),
  send_contract: t('financing.list.status.send_contract'),
  under_analysis: t('financing.list.status.under_analysis'),
}

const FilterSteps = {
  simulation: t('financing.list.step.simulation'),
  formalization: t('financing.list.step.formalization'),
  installation: t('financing.list.step.installation'),
  concluded: t('financing.list.step.concluded'),
  expired: t('financing.list.step.expired'),
  canceled: t('financing.list.step.canceled'),
}

const sortByOptions = [
  { name: t('financing.sort_by.updated_at_desc'), value: 'updated_at:desc' },
  { name: t('financing.sort_by.created_at_desc'), value: 'created_at:desc' },
  { name: t('financing.sort_by.created_at_asc'), value: 'created_at:asc' },
  { name: t('financing.sort_by.expires_at_asc'), value: 'expires_at:asc' },
]

const flagNewJourney = ref()
const page = ref(1)
const query = ref<string>()
const financingList = ref()
const sortBy = ref(sortByOptions[0])
const isEnabledToBNPL = ref(false)
const refreshFilterList = ref(true)

const contextMenu = ref<null | RightClickContextMenuProps>(null)

const defaultSteps = [FinancingSteps.simulation, FinancingSteps.formalization, FinancingSteps.installation, FinancingSteps.concluded, FinancingSteps.expired]

function newSimulation() {
  track('financing-list_button_nova-simulacao', { trigger: 'Clique no botão de nova simulação na listagem de financiamentos' })
  router.push('/simulation/new')
}

function openOldList() {
  window.location.href = `${config.VITE_URL_LEGACY}/financiamentos`
}

async function fetchFinancingList(data: FinancingsList) {
  try {
    financingList.value = data

    if (financingList.value) {
      if (financingList.value?.total_by_status && financingList.value?.total_by_step) {
        const filterStatus = await Object.keys(financingList.value?.total_by_status).map(key => ({
          name: `${FilterStatus[key]} (${financingList.value?.total_by_status?.[key]})`,
          value: key,
        })).filter(key => financingList.value?.total_by_status?.[key.value] !== null)

        const filterSteps = await Object.keys(financingList.value?.total_by_step).map(key => ({
          name: `${FilterSteps[key]} (${financingList.value?.total_by_step?.[key]})`,
          value: key,
        })).filter(key => financingList.value?.total_by_step?.[key.value] !== null)

        optionsFiltersList.value = {
          title: t('financing.filters.title'),
          filters: [
            {
              id: 'steps-field',
              class: 'w-full md:system:w-[160px]',
              placeholder: t('financing.filters.steps'),
              size: 'small',
              name: 'stepsField',
              label: '',
              options: filterSteps,
            },
            {
              id: 'status-field',
              class: 'w-full md:system:w-[160px]',
              placeholder: t('financing.filters.status'),
              size: 'small',
              name: 'statusField',
              label: '',
              options: filterStatus,
            },
          ],
        }
      }
    }
  }
  catch (error) {
    console.error(error)
    toast.createErrorToast({
      title: t('financing.list.error.fetching.title'),
      description: t('financing.list.error.fetching.description'),
    })
  }
}

function getDifferenceInDays(start: Date, end: Date) {
  const diff = start.getTime() - end.getTime()

  return Math.ceil(diff / (1000 * 60 * 60 * 24))
}

function resolveDaysReference(item: FinancingListItem): string {
  const value = match(item)
    .with({ status: P.union('reproved', 'canceled') }, () => '-')
    .with({ step: 'formalization', expires_at: P.nonNullable }, ({ expires_at }) => {
      const expiresAt = new Date(expires_at)

      return getDifferenceInDays(expiresAt, new Date())
    })
    .with({ step: 'simulation', expires_at: P.nonNullable }, ({ expires_at }) => {
      const expiresAt = new Date(expires_at)

      return getDifferenceInDays(expiresAt, new Date())
    })
    .with({ step: 'installation', status: 'concluded', formalized_at: P.nonNullable, installed_at: P.nonNullable }, ({ formalized_at, installed_at }) => {
      const formalizedAt = new Date(formalized_at)
      const installedAt = new Date(installed_at)

      return getDifferenceInDays(installedAt, formalizedAt)
    })
    .with({ step: 'installation', status: P.union('pending', 'under_analysis', 'pendency'), formalized_at: P.nonNullable }, ({ formalized_at }) => {
      const formalizedAt = new Date(formalized_at)

      return getDifferenceInDays(new Date(), formalizedAt)
    })
    .otherwise(() => 0)

  if (typeof value === 'string')
    return value

  return value <= 0 ? '-' : String(value)
}

function resolveLabelReference(item: FinancingListItem) {
  return match(item)
    .with({ step: 'installation', status: P.union('pending', 'under_analysis', 'pendency') }, () => t('financing.list.headers.installing_days'))
    .with({ step: 'installation', status: P.union('concluded', 'canceled') }, () => t('financing.list.headers.installed_days'))
    .with({ step: P.union('formalization', 'simulation') }, () => t('financing.list.headers.expires_at'))
    .otherwise(() => '-')
}

function getListData() {
  return financingList.value?.data.map((item) => {
    const days = resolveDaysReference(item)

    return {
      ...item,
      days_info: days,
    }
  }) as Data[]
}

const financingListProps = {
  headers: {
    id: {
      text: t('financing.list.headers.id'),
      width: '72px',
    },
    customer_name: {
      text: t('financing.list.headers.customer_name'),
    },
    financed_value: {
      text: t('financing.list.headers.financed_value'),
      width: '118px',
    },
    days_info: {
      width: '80px',
    },
    step: {
      text: t('financing.list.headers.step'),
      width: '90px',
    },
    status: {
      width: '140px',
      hide: true,
    },
  },
  sortPositions: {
    mobile: [
      ['customer_name'],
      ['id', 'financed_value', 'days_info'],
      ['step', 'status'],
    ],
    desktop: [
      'customer_name',
      'id',
      'financed_value',
      'days_info',
      'step',
      'status',
    ],
  },
}

function renderStatus(status: string): Type {
  return match(status)
    .with('concluded', () => 'positive')
    .with(P.union('pending', 'pending_customer', 'send_contract', 'pending_receipt', 'pendency', 'kit_pending'), () => 'warning')
    .with('under_analysis', () => 'informative')
    .with(P.union('canceled', 'reproved'), () => 'negative')
    .with('expired', () => 'neutral')
    .otherwise(() => 'informative') as Type
}

function getAnchorByStatus(item: Data) {
  return match(item)
    .with({ status: 'pending_receipt' }, () => 'receipt-model')
    .with({ status: 'pending_customer' }, () => 'biometry')
    .with({ status: 'send_contract' }, () => 'biometry')
    .with({ status: 'kit_pending' }, () => 'accordion_hardware')
    .otherwise(() => '')
}

function goToStepScreen(item: Data, event: MouseEvent, contextMenu = false) {
  track('simulation-list_list_lista-financiamentos', { trigger: 'Clique em um item de lista, na lista de financiamentos' })

  const url = match(item)
    .with({ step: P.union('simulation'), status: 'expired' }, () => `/simulation/result/${item.project_id}`)
    .with({ step: P.union('formalization'), status: 'expired' }, () => `/${item.person_type === 'PJ' ? 'company' : 'customer'}/${item.project_id}#${getAnchorByStatus(item)}`)
    .with({ step: P.union('installation'), status: 'expired' }, () => `/installation/${item.project_id}`)
    .with({ step: P.union('simulation', 'expired') }, () => `/simulation/result/${item.project_id}`)
    .with({ step: 'formalization' }, () => `/${item.person_type === 'PJ' ? 'company' : 'customer'}/${item.project_id}#${getAnchorByStatus(item)}`)
    .with({ step: 'installation' }, () => `/installation/${item.project_id}`)
    .with({ step: 'concluded' }, () => `/installation/${item.project_id}`)
    .otherwise(() => `/simulation/result/${item.project_id}`)

  if (event?.ctrlKey || contextMenu)
    window.open(url, '_blank')
  else
    router.push(url)
}

async function setFeatureFlags() {
  flagNewJourney.value = await useFlag('new_journey', { partner_id: user?.parceiro?.id })
  isEnabledToBNPL.value = await useFlag<boolean>('bnpl', { partner_id: user?.parceiro?.id })
}

function applyFilters(selectedFilters: SelectedFilters) {
  const defaultSteps = ['simulation', 'formalization', 'installation', 'concluded', 'expired']

  const params = {
    page: 1,
    steps: typeof selectedFilters?.stepsField === 'object' && 'value' in selectedFilters.stepsField ? [selectedFilters.stepsField.value] : defaultSteps,
    status: typeof selectedFilters?.statusField === 'object' && 'value' in selectedFilters.statusField ? [selectedFilters.statusField.value] : [],
  } as unknown as UseFinancingsParams

  emit('params', params)
}

function setPage(value: any) {
  const params = { page: value } as unknown as UseFinancingsParams
  emit('params', params)
}

function onContextMenu(item: Data, event: MouseEvent) {
  event.preventDefault()

  contextMenu.value = {
    x: event.pageX,
    y: event.pageY,
    actions: [
      {
        label: 'Abrir em uma nova aba',
        action: () => {
          goToStepScreen(item, event, true)
        },
      },
    ],
  }

  window.addEventListener('click', () => contextMenu.value = null, { once: true })
}

watch(() => props.list, (value) => {
  if (value)
    fetchFinancingList(value)
})

watch(query, (value) => {
  const params = { query: value, steps: defaultSteps, status: [], page: 1 } as unknown as UseFinancingsParams
  refreshFilterList.value = false
  nextTick(() => refreshFilterList.value = true)

  emit('params', params)
})

watch(sortBy, (value) => {
  const params = { order_by: value.value, page: 1 } as unknown as UseFinancingsParams
  emit('params', params)
})

onMounted(() => {
  if (props.list)
    fetchFinancingList(props.list)

  track('financing-list_page-view', { trigger: 'Ver página da financiamentos de simulações' })
  setFeatureFlags()
})
</script>

<template>
  <template v-if="contextMenu">
    <RightClickContextMenu :x="contextMenu.x" :y="contextMenu.y" :actions="contextMenu.actions" />
  </template>
  <div class="container-list-financing">
    <section
      v-if="financingList?.data?.length || Boolean(query)"
      class="box-search p-6 rounded-lg bg-neutral-high-light flex flex-col md:system:flex-row gap-3"
    >
      <FinancingSearch @search-financing-value="(searchFinancingValue) => { query = searchFinancingValue; }" />

      <SharedFilters v-if="refreshFilterList" :options-filters-list="optionsFiltersList" @send-filters="applyFilters" />
    </section>

    <section v-if="financingList?.total === 0" class="empty-state">
      <SolEmptyState
        v-if="(financingList?.total ?? 0) === 0 && !Boolean(query)"
        id="empty-list"
        :title=" t('financing.emptyList.title')"
        :subtitle="t('financing.emptyList.subtitle')"
        variant="empty-list"
      >
        <template #action>
          <div class="flex gap-2 inline-flex show-md">
            <SolButton id="btn-new-simulation" :size="isLargeScreen ? 'medium' : 'small'" @click="newSimulation">
              <template #icon-left>
                <IconPlus />
              </template>
              {{ t('simulation.new') }}
            </SolButton>
          </div>
        </template>
      </SolEmptyState>

      <SolEmptyState
        v-else
        id="project-not-found"
        :title=" t('financing.notFound.title')"
        :subtitle="t('financing.notFound.subtitle')"
        variant="no-content"
      >
        <template #action>
          <div class="flex gap-2 inline-flex show-md">
            <SolButton
              id="btn-new-simulation"
              :size="isLargeScreen ? 'medium' : 'small' "
              @click="openOldList"
            >
              <template #icon-right>
                <IconOpenInNew />
              </template>
              {{ t('financing.alert.action') }}
            </SolButton>
          </div>
        </template>
      </SolEmptyState>
    </section>

    <div v-if="loading" class="loading">
      <Loading />
    </div>

    <div v-if="!loading && (financingList?.total ?? 0) > 0" class="financing-list">
      <div class="financing-list-header">
        <p class="mb-2 fonts-body-medium-bold text-neutral-low-medium">
          {{ t('financing.list.results_found', { count: financingList?.total ?? 0 }) }}
        </p>
        <div class="flex">
          <h2 class="text-brand-primary-pure font-bold self-center mr-micro">
            {{ t('financing.sort_by.label') }}
          </h2>
          <div class="w-56">
            <SolSelect
              id="financing-order-by"
              v-model:selected="sortBy"
              name="financing-order-by"
              size="small"
              :options="sortByOptions"
            >
              <template #no-data>
                <h2 class="text-feedback-negative-pure font-bold">
                  {{ t('financing.no_data') }}
                </h2>
              </template>
            </SolSelect>
          </div>
        </div>
      </div>

      <SharedMessageInstallmentsProjects v-if="isEnabledToBNPL" event-mixpanel="financing-list_button_ver-parcelas" />

      <SolList
        v-if="financingList?.data"
        id="financing-list"
        v-bind="financingListProps"
        :hide-menu="true"
        :data="getListData()"
        @listitem:click="goToStepScreen"
        @listitem:contextmenu="onContextMenu"
      >
        <template #column:customer_name="{ data }">
          <div id="financing-list-field-name" class="items-center">
            <p class="fonts-body-small-regular text-neutral-low-light">
              {{ t('financing.list.headers.customer_name') }}
            </p>
            <p class="fonts-body-medium-regular">
              {{ conventionalNameFormat(String(data.value)) }}
            </p>
          </div>
        </template>
        <template #column:days_info="{ data, index }">
          <div class="items-center">
            <p class="fonts-body-small-regular text-neutral-low-light">
              {{ financingList.data[index].status !== 'concluded' ? resolveLabelReference(financingList.data[index]) : '' }}
            </p>
            <p class="fonts-body-medium-regular flex gap-1">
              <IconSchedule v-if="data.value !== '-' && financingList.data[index].status !== 'concluded' && parseInt(data.value as string) > 10" />
              <IconCheck v-else-if="financingList.data[index].status === 'concluded'" class="text-brand-secondary-pure" />
              <IconWarning v-else-if="data.value !== '-' && parseInt(data.value as string) < 10" class="text-feedback-negative-pure" />
              {{ (financingList.data[index].status !== 'concluded') ? t('financing.list.days_info', { count: data.value !== '-' ? parseInt(data.value as string) : 0 }) : '' }}
            </p>
          </div>
        </template>

        <template #column:financed_value="{ data, index }">
          <div class="items-center">
            <p class="fonts-body-small-regular text-neutral-low-light">
              {{ t('financing.list.headers.financed_value') }}
            </p>
            <p class="fonts-body-medium-regular">
              {{ typeof data.value === 'number' ? currencyUtils.formatBRL(data.value) : '-' }}
              <SolTag
                v-if="financingList.data[index].percentage_paid"
                :id="`financing-paid-${financingList.data[index].id}`"
                :type="financingList.data[index].percentage_paid === 100 ? 'positive' : 'informative'" size="small"
              >
                {{ t('financing.list.tags.paid_percentage', { percentage: financingList.data[index].percentage_paid }) }}
              </SolTag>
            </p>
          </div>
        </template>

        <template #value:status="{ data }">
          <SolTag
            :id="`financing-status-${data.value}`"
            class="lg:site:w-full"
            :type="renderStatus(String(data.value))"
          >
            {{ t(`financing.list.status.${data.value}`) }}
          </SolTag>
        </template>

        <template #value:step="{ data }">
          {{ t(`financing.list.step.${data.value}`) }}
        </template>
      </SolList>

      <SolPagination
        id="test"
        v-model:current="page"
        :total-pages="financingList?.total ? Math.ceil(financingList.total / 10) || 0 : 0"
        size="small"
        @update:current="setPage"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container-list-financing {
  .loading {
    @apply flex justify-center items-center h-96;
  }

  .financing-list-header {
    @apply mb-4;

    @screen md:system {
      @apply flex items-baseline justify-between;
    }
  }

  .sol-alert-core.-informative {
    @apply block mb-2xs;

    @screen md:system {
      @apply flex;
    }

    .sol-button-core.-secondary {
      @apply ml-sm mt-micro min-w-max;

      @screen md:system {
        @apply ml-0 mt-0;
      }
    }
  }

  .sol-list-core {
    .list-item > .list-item-row {
      @apply items-start;

      @screen md:system {
        @apply items-center;
      }
    }
  }

  .box-search {
    @apply mb-2xs;
  }

  .empty-state {
    @apply w-full pt-2xs;

    .sol-empty-state-core {
      @apply p-0 max-w-full;
    }
  }

  .sol-select-core.-bottom>.select-options-container {
    @apply w-full;
  }
}

[data-tooltip].tooltip:after {
  width: 450px;
  white-space: break-spaces;
}

@media screen and (max-width: 767px) {
  [data-tooltip].tooltip:after {
    width: 300px;
  }
}
</style>
