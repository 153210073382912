<script setup lang="ts">
import type { Addons, SimulationConclusion } from '~/utils/simulation/Simulation'
import { useToast } from '@solfacil/girassol'
import SimulatorService from '~/services/simulator/Simulator'

const props = defineProps<{
  disabledFields: boolean
  loadingConclusion: boolean
  cartIdSelected: string
  shoppingCartId: string
}>()

const { createErrorToast, createSuccessToast } = useToast()
const { getSelectedInstallment, getValueOfAmpera, getAddonsApplieds, getCurrentProject, getCommissionApplied, getSumOfInsurancesApplied } = useResumeStore()

const { t } = useI18n()
const router = useRouter()

const simulation = new SimulatorService(useApi('simulator'))

const valueHasChanges = ref(false)
const changeComparissonValue = ref(0)
const loadingLocal = ref(false)
const blockFields = ref(false)

const config = import.meta.env

const disabledButton = ref(true)

const currentProject = getCurrentProject()

onUpdated(() => {
  disabledButton.value = !props.disabledFields || blockFields.value || !getSelectedInstallment() || loadingLocal.value
})

onMounted(() => {
  const installmentValue = getSelectedInstallment()?.installments_value
  if (installmentValue)
    changeComparissonValue.value = installmentValue + getSumOfInsurancesApplied() + getValueOfAmpera()
})

watch([
  () => getSelectedInstallment()?.installments_value,
  () => getSumOfInsurancesApplied(),
  () => getValueOfAmpera(),
], (newValues, _) => {
  if (newValues) {
    valueHasChanges.value = changeComparissonValue.value !== newValues.reduce((accumulator, currentValue) => {
      if (accumulator !== undefined && currentValue !== undefined)
        return accumulator + currentValue
      return 0
    }, 0)
  }
})

async function submitFinancial() {
  try {
    const selectedInstallment = getSelectedInstallment()
    const commission = getCommissionApplied()

    loadingLocal.value = true

    if (selectedInstallment === null || currentProject === null || commission === undefined)
      return

    const addonsAppliedId = getAddonsApplieds().map((addon: Addons) => {
      if (addon.applied)
        return addon.id

      return null
    }).filter((n: string | null) => n)

    const payload: SimulationConclusion = {
      id: selectedInstallment.engine_simulation_id,
      new_journey: Boolean(currentProject.new_journey),
      is_combo: selectedInstallment.combo,
      is_ampera: Boolean(getValueOfAmpera()),
      commission: selectedInstallment?.is_from_suggested ? Number(selectedInstallment.commission) : Number(commission),
      simulation_id: selectedInstallment.simulation_id,
      project_id: currentProject.id,
      applied_addons: addonsAppliedId as any[],
      is_from_suggested: selectedInstallment?.is_from_suggested,
    }

    const response = await simulation.create_financial(payload)
    createSuccessToast('Financiamento atualizado com sucesso.')
    disabledButton.value = true
    window.location.href = `${config.VITE_URL_LEGACY}/financiamento/etapas/${response.data.financing_id}`
  }
  catch (e: any) {
    if (e.response.status === 422)
      createErrorToast('Selecione ao menos uma parcela.')
    else
      createErrorToast(e?.response?._data?.detail || 'Ocorreu um erro ao tentar atualizar o projeto, verifique se existe algum contrato gerado.')
  }
  finally {
    loadingLocal.value = false
  }
}

function redirectToStepsLegacy() {
  window.location.href = `${config.VITE_URL_LEGACY}/etapas/${router.currentRoute.value.params.id}`
}
</script>

<template>
  <div class="w-full justify-end">
    <div class="w-full flex gap-3 justify-end">
      <SolButton
        v-if="!valueHasChanges && (props.shoppingCartId === props.cartIdSelected)"
        id="back"
        class="w-full"
        :loading="loadingLocal"
        size="large"
        @click="redirectToStepsLegacy()"
      >
        {{ t('buttons.back') }}
      </SolButton>

      <SolButton
        v-if="(valueHasChanges && currentProject?.complete) || (props.shoppingCartId !== props.cartIdSelected)"
        id="go"
        class="w-full"
        :disabled="disabledButton || loadingConclusion"
        :loading="loadingLocal"
        size="large"
        @click="submitFinancial()"
      >
        {{ t('app.advance_and_update') }}
      </SolButton>
    </div>
  </div>
</template>
