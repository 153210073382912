import { isAxiosError } from 'axios'

export function getErrorMessage(error: any | any[]): string {
  if (Array.isArray(error))
    return getErrorMessage(error[0])

  if (typeof error === 'string')
    return error

  if (error?.message && typeof error.message === 'string') {
    return error.message
  }

  if (isAxiosError(error)) {
    const axiosErrorMessage = error.response?.data?.message
      || error.response?.data?.error
      || error.response?.data
      || error.response?.statusText

    if (axiosErrorMessage) {
      return axiosErrorMessage
    }
    return error.message
  }

  if (error instanceof Error) {
    return error.message || error.toString()
  }

  if (error?.request)
    return 'Request error'

  return JSON.stringify(error)
}
