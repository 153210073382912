<script setup lang="ts">
import type { Partner } from '~/store-v2/partners/usePartners'

const props = withDefaults(
  defineProps<{
    loading: boolean
    partners: Partner[] | undefined
    partnerId: number | undefined
    partnerName: string
  }>(),
  {
    partnerId: 0,
  },
)

const emits = defineEmits<{
  (e: 'handleChangeValue', value: any): void
  (e: 'selectPartner', id: number, fantasyName: string): void
  (e: 'handleImpersonate'): void
}>()
const input = ref('')
const { t } = useI18n()
const applySelected = ref(false)
const impersonateStore = useImpersonateStore()

const partners = computed(() => props.partners)

function handleValue(event: any) {
  applySelected.value = false
  input.value = event.target.value
  emits('handleChangeValue', event.target.value)
}

function selectPartnerToImpersonate(item: Partner) {
  emits('selectPartner', item.id ?? 0, item.fantasy_name || '')
  applySelected.value = true
}

function closeModal() {
  input.value = ''
  applySelected.value = false
  impersonateStore.toggleImpersonateModal()
}

onMounted(() => {
  input.value = props.partnerName
})

const partnerNotFound = computed(() => {
  return !props.loading && !partners?.value?.length && input.value !== ''
})
</script>

<template>
  <SolModal
    id="financing-modal-impersonate"
    class="modal-impersonate"
    :is-open="impersonateStore.openImpersonateModal"
    :size="{ desktop: 'medium', mobile: 'bottom-sheet' }"
    :title="t('header.impersonate.modal.title')"
    :action-secondary-text="t('app.cancel')"
    @close="closeModal()"
    @action:secondary="closeModal()"
  >
    <div>
      <h3 class="mb-4xs text-2xs">
        {{ t('header.impersonate.modal.subtitle') }}
      </h3>

      <SolInputText
        id="modal-impersonate-input"
        :value="input"
        name="partnerName"
        :placeholder="t('header.impersonate.modal.input_label')"
        :is-loading="loading"
        aria-autocomplete="none"
        autocomplete="off"
        @input="handleValue"
      />
      <ul v-if="partners?.length">
        <li
          v-for="partner in partners"
          :key="partner.id"
          :class="{ 'bg-neutral-high-dark bg-opacity-intense': partner.id === partnerId }"
          @click="selectPartnerToImpersonate(partner)"
        >
          <span>{{ partner.fantasy_name }}</span>
        </li>
      </ul>
      <div v-else class="flex items-center justify-center">
        <SolEmptyState
          v-if="partnerNotFound"
          id="modal-impersonate-empty-state"
          :title="t('header.impersonate.modal.empty_state.title')"
          :subtitle="t('header.impersonate.modal.empty_state.description')"
          variant="search"
          class="pt-4xs"
        />
      </div>
    </div>
    <template #action-primary>
      <SolButton
        id="visualize-modal-button"
        size="medium"
        :disabled="!applySelected"
        @click="$emit('handleImpersonate')"
      >
        {{ t('header.impersonate.modal.button') }}
      </SolButton>
    </template>
  </SolModal>
</template>

<style scoped lang="scss">
.modal-impersonate {
  ul {
    @apply py-micro;

    li {
      @apply p-micro;

      &[active='true'] {
        @apply bg-neutral-high-dark bg-opacity-intense;
      }

      &:hover {
        @apply bg-neutral-high-medium bg-opacity-medium cursor-pointer;
      }
    }
  }
}
</style>
