import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useFinancingV2(id: Ref<string>) {
  const queryKey = queryKeys.FINANCINGS.DETAILS(id)

  const queryFn = async () => {
    const res = await financialBff('query')({
      financing: [{ id: id.value }, {
        id: true,
        project_id: true,
        customer_name: true,
      }],
    })
    return res.financing
  }

  return useQuery({
    queryFn,
    queryKey,
    retry: 0,
  })
}
